import { combineReducers } from "redux";
import auth from "./auth";
import clients from "./clients";
import chantiers from "./chantiers";
import salesItems from "./salesItems";
import contacts from "./contacts";
import salesDetails from "./salesDetails";
import schedules from "./schedules";
import users from "./users";
import suppliers from "./suppliers";
import achatsItems from "./achatsItems";

export default combineReducers({ auth, clients, salesItems , chantiers , contacts , salesDetails ,schedules , users, suppliers, achatsItems  });

import Header from "@components/Header";
import Router from "@components/Router";
import DataBox from "@components/DataBox";
import { useHistory } from "react-router-dom";
import { fetchClients } from "@queries/Clients";
import { useQuery } from "react-query";
import { useState } from "react";
import { fetchChantiers } from "@queries/Chantiers";
import { fetchSalesItems } from "@queries/SalesItems";
import { fetchUsers } from "@queries/Users";
import MySearchBar from "@components/MySearchBar";

function Main() {
  const history = useHistory();
  const [allSearchItems, setAllSearchItems] = useState([]);
  const [ids, setIds] = useState(10);

  useQuery("clients", () => fetchClients(), {
    onSuccess: (data) => {
      let tmp = ids;
      setAllSearchItems([
        ...allSearchItems,
        ...data.data.map((data) => {
          data.attributes.realId = data.id;
          tmp++;
          data.attributes.type = "clients";
          data.attributes.id = tmp;
          return data.attributes;
        }),
      ]);
      setIds(tmp);
    },
  });
  useQuery("salesItems", () => fetchSalesItems(), {
    onSuccess: (data) => {
      let tmp = ids;
      setAllSearchItems([
        ...allSearchItems,
        ...data.data.map((data) => {
          data.attributes.realId = data.id;
          tmp++;
          data.attributes.realType = data.attributes.type;
          data.attributes.type = "salesItems";
          data.attributes.id = tmp;
          return data.attributes;
        }),
      ]);
      setIds(tmp);
    },
  });
  useQuery("chantier", () => fetchChantiers(), {
    onSuccess: (data) => {
      let tmp = ids;
      setAllSearchItems([
        ...allSearchItems,
        ...data.data.map((data) => {
          tmp++;
          data.attributes.realId = data.id;
          data.attributes.type = "chantier";
          data.attributes.id = tmp;
          return data.attributes;
        }),
      ]);
      setIds(tmp);
    },
  });
  useQuery("employees", () => fetchUsers(), {
    onSuccess: (data) => {
      let tmp = ids;
      setAllSearchItems([
        ...allSearchItems,
        ...data.map((data) => {
          tmp++;
          data.type = "employees";
          data.realId = data.id;
          data.id = tmp;
          return data;
        }),
      ]);
      setIds(tmp);
    },
  });
  const navigate = (page) => {
    history.push(page);
  };

  return (
    <Router active="Accueil">
      <Header />
      <div>
        <div className="pl-5 pr-5 mt-4">
          <MySearchBar allSearchItems={allSearchItems} />
        </div>
        <div className="w-75 mx-auto d-flex flex-column">
          <div className="mt-3 d-flex justify-content-center">
            <DataBox
              text="Clients"
              image="fas fa-users fa-3x"
              color="var(--gray)"
              bottomColor="var(--gray)"
              navigateTo={() => navigate({ pathname: "/clients" })}
            />
             <DataBox
              text="Chantiers"
              image="fas fa-warehouse fa-3x"
              color="var(--gray)"
              bottomColor="var(--gray)"
              navigateTo={() => navigate("/chantiers")}
            />
            <DataBox
              text="Documents"
              image="fas fa-file-contract fa-3x"
              color="var(--gray)"
              bottomColor="var(--gray)"
              navigateTo={() =>
                navigate({ pathname: "/sales-items" })
              }
            />
           
          </div>

        </div>
      </div>
    </Router>
  );
}

export default Main;

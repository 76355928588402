import * as queries from "@queries/utils";

export const fetchChantiers = () =>
  queries.getQuery(
    "/api/chantiers",
    "populate=idClient,idContact,sales_items.sales_details,schedules.idUser"
  );

export const createChantiers = (chantiers) =>
  queries.postQuery("/api/chantiers", chantiers);

export const updateChantiers = (chantiers) =>
  queries.putQuery(`/api/chantiers`, chantiers);

export const deleteChantiers = (chantiersToDelete) =>
  queries.deleteQuery(`/api/chantiers`, chantiersToDelete);

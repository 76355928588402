import React from 'react'
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '@root/config';
import { fetchMe } from '@queries/Users';

const Sidebar = ({ children }) => {
    const content = React.Children.map(children, child => child.type.displayName === 'Content' ? child : null);
    const main = React.Children.map(children, child => child.type.displayName === 'Main' ? child : null);

    const history = useHistory();

    const me = useQuery('me', fetchMe);    

    return (
        <div style={styles.container}>
            { me.isSuccess && me.data?.type == 'admin' ? <div style={styles.content}>
                {content && content[0].props.header && (
                    <div style={styles.contentHeader}>
                        <i className="fas fa-th" style={styles.contentHeaderLogo}></i>
                    </div>)
                }
                {content}
            </div> 
            :
             history.push('/time-tracker')
            }
            <div style={styles.main}>
                {main}
            </div>
        </div>
    )
}

const Content = ({ children }) => {
    const items = React.Children.map(children, child => child.type.displayName === 'Item' ? child : null);

    return (
        <div style={styles.contentContainer}>
            {items && items.map((val, index) => (
            <Link to={val.props.url} 
                key={index}
                className={'sidebarLink ' + ((val.props.active) ? 'sidebarLinkActive' : '')}
                href={"#" + val.props.url}
                // onClick={() => val.props.trigger.call()}
            >
                <i className={val.props.image} ></i>
                <span className="mt-1" style={styles.contentText}>
                    {val}
                </span>
            </Link>)
            )}
        </div>
    )
}

Content.displayName = "Content";
Sidebar.Content = Content;

const Main = ({ children }) => children;
Main.displayName = "Main";
Sidebar.Main = Main;

const Item = ({ children }) => children;
Item.displayName = "Item";
Sidebar.Item = Item;

const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '110px',
    backgroundColor: 'var(--dark)'
  },
  main: {
    width: '100%',
  },
  contentContainer: {
      display: 'flex',
      flexDirection: 'column'
  },
  contentText: {
      fontSize: 12
  },
  contentHeader: {
      width: '100%',
      height: '58px',
      backgroundColor: 'rgb(0,183,145)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white'
  },
  contentHeaderLogo: {
    fontSize: '25px'
  }
}

export default Sidebar
import axios from 'axios';
import baseUrl from '@root/config';
import _ from 'lodash';

export async function fetchAchatsItems() {
    try {
        const response = await axios.get(baseUrl + '/achatsitems');
        return response.data;
    } catch (error) {
        console.log('Error while fetching achatsitems', error);
        return {
            status: 'error',
            message: 'AchatsItems fetch: ' + error
        }
    }
}


export async function updateAchatsItems(salesItems) {
    try {
        const payload = salesItems.map((o) => (o.id < 0) ? _.omit(o, ['id', 'createdAt', 'updatedAt', 'deletedAt']) : _.omit(o, ['createdAt', 'updatedAt', 'deletedAt']));
        const response = await axios.post(baseUrl + '/achatsitems', payload);
        return response.data;

    } catch (error) {
        console.log('Error while updating achatsitems', error);
        return {
            status: 'error',
            message: 'AchatsItems update : ' + error
        }
    }
}

export async function deleteAchatsItems(salesItemsId) {
    try {
        const response = await axios.delete(baseUrl + '/achatsitems', {
          data: salesItemsId
        });

        return response.data;
    
    } catch (error) {
        console.log('Error while deleting achatsitems', error);
        return {
            status: 'error',
            message: 'AchatsItems delete: ' + error
        }
    }
  }

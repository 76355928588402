import { useState } from 'react';
import { findIndex } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import _ from 'lodash';

import { useMax, useForm } from '@hooks';
import { typeLabel, statutText } from '@root/config';

import {fetchSuppliers , updateSuppliers , deleteSuppliers} from '@queries/Suppliers';
import {fetchChantiers } from '@queries/Chantiers';
import {fetchContacts , updateContacts , deleteContacts} from '@queries/Contacts';
import {fetchSalesItems  , deleteSalesItems} from '@queries/SalesItems';
import {fetchSalesDetails , deleteSalesDetails} from '@queries/SalesDetails';

import Header from '@components/Header';
import Router from "@components/Router";
import Table from '@components/Table';
import Field from '@components/Field/Field';
import FilterBox from '@components/FilterBox';

function SuppliersDetails() {
  const location = useLocation();
  const history = useHistory();
  const querySupplier = useQueryClient();

  const [form, setForm] = useForm({ contacts: [], chantiers: [], salesItems: [] });
  const currentSupplierId = location.state;
  
  // Fetch data
  const salesDetailsAll = useQuery('salesDetails', fetchSalesDetails);

      // Automatic populate / reupdate supplier structure based on the currentSupplierId
  const salesItemsAll = useQuery('salesItems', fetchSalesItems, { onSuccess: () => setForm('salesItems', salesItemsAll?.data?.data?.filter(o => o.idSupplier == currentSupplierId)) });
  const chantiersAll = useQuery('chantiers', fetchChantiers, { onSuccess: () => setForm('chantiers', chantiersAll?.data?.data?.filter(o => o.idSupplier == currentSupplierId)) });
  const contactsAll = useQuery('contacts', fetchContacts, { onSuccess: () => setForm('contacts', contactsAll?.data?.data?.filter(o => o.idSupplier == currentSupplierId)) });
  const suppliersAll = useQuery('suppliers', fetchSuppliers, { onSuccess: () => setForm('all', suppliersAll?.data?.data?.filter(o => o.id == currentSupplierId)[0]) });
  
  const maxSalesItemId = useMax((salesItemsAll.isSuccess) ? salesItemsAll.data?.data : []);
  let maxContactId = useMax((contactsAll.isSuccess) ? contactsAll.data?.data : [])

  const suppliers = (suppliersAll.isSuccess) ? suppliersAll.data?.data?.filter((o) => !o.deletedAt) : [];
  const chantiers = (chantiersAll.isSuccess) ? chantiersAll.data?.data?.filter((o) => !o.deletedAt) : [];
  const contacts = (contactsAll.isSuccess) ? contactsAll.data?.data?.filter((o) => !o.deletedAt) : [];
  const salesItems = (salesItemsAll.isSuccess) ? salesItemsAll.data?.data?.filter((o) => !o.deletedAt) : [];
  const salesDetails = (salesDetailsAll.isSuccess) ? salesDetailsAll.data?.data?.filter((o) => !o.deletedAt) : [];

  // Modify Data

  const upsertSupplierMutation = useMutation((suppliers) => updateSuppliers(suppliers));
  const updateContactsMutation = useMutation((contacts) => updateContacts(contacts));

  const deleteSupplierMutation = useMutation((ids) => deleteSuppliers(ids));
  const deleteContactsMutation = useMutation((ids) => deleteContacts(ids));
  const deleteSalesDetailsMutation = useMutation((ids) => deleteSalesDetails(ids));
  const deleteSalesItemsMutation = useMutation((ids) => deleteSalesItems(ids));

  // State
  
  const [typeFilter, setTypeFilter] = useState('');
  const [currentTab, setCurrentTab] = useState('informations');
  
  const [isUpdatingSuppliers, setIsUpdatingSuppliers] = useState(false);
  const [isDeletingSuppliers, setIsDeletingSuppliers] = useState(false);

  const [contactsToDelete, setContactsToDelete] = useState([]);

  // Helpers

  const deleteSupplier = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let salesItemsToDelete = salesItems.filter((o) => o.idSupplier == currentSupplierId).map((o) => o.id);
    let salesDetailsToDelete = salesDetails.filter((o) => salesItemsToDelete.includes(o.idSalesItems)).map((o) => o.id);
    let contactsToBeDeleted = contacts.filter((o) => o.idSupplier == currentSupplierId).map((o) => o.id);
        
    await deleteSalesDetailsMutation.mutateAsync(salesDetailsToDelete);
    querySupplier.invalidateQueries('salesDetails');
    await deleteSalesItemsMutation.mutateAsync(salesItemsToDelete);
    querySupplier.invalidateQueries('salesItems');
    await deleteContactsMutation.mutateAsync(contactsToBeDeleted);
    querySupplier.invalidateQueries('contacts');
    await deleteSupplierMutation.mutateAsync([currentSupplierId]);
    querySupplier.invalidateQueries('suppliers');

    history.goBack();
  } 

  const upsertSupplierContact = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { contacts,  salesItems, ...supplier} = form;

    await deleteContactsMutation.mutateAsync(contactsToDelete);
    querySupplier.invalidateQueries('contacts');
    await updateContactsMutation.mutateAsync(contacts || []);
    querySupplier.invalidateQueries('contacts');
    await upsertSupplierMutation.mutateAsync([supplier]);
    querySupplier.invalidateQueries('suppliers');

    history.goBack();
  }

  // Internal methods 

  const newContact = () => {
    setForm('contacts', [...form.contacts, {
      id: maxContactId + 1,
      idSupplier: currentSupplierId
    }]);

    maxContactId += 1;
  }

  const deleteContact = (id) => {
    setContactsToDelete((prev) => [...prev, id]);
    const index = findIndex(form.contacts, (o) => o.id == id);

    setForm('contacts', [
        ...form.contacts.slice(0, index), ...form.contacts.slice(index+1)
    ]);
  }

  const editContact = (row, key, value) => {
    const tempContacts = JSON.parse(JSON.stringify(form.contacts));
    tempContacts.filter((o) => o.idSupplier == currentSupplierId )[row][key] = value;
    setForm('contacts', tempContacts); 
  }

  const getDisplayContactName = (idContact) => {
    let contact = contacts.filter((o) => o.id == idContact)[0];
    return `${contact && contact.firstName + ' ' +contact.lastName || ''}`;
  }


  // duplicate bills button
  const duplicateBill = async (element) => {
  //   const { status: statusSalesItems, data: dataSalesItems, message: messageSalesItems } = await updateSalesItems([
  //       {
  //         id : maxSalesItemId + 1 ,
  //         idSupplier : element.idSupplier ,
  //         idChantier : element.idChantier ,
  //         type : element.type,
  //         documentNumber : element.documentNumber ,
  //         vat : element.vat ,
  //         dateFacturation :  moment(element.dateFacturation , 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
  //         dateEcheance : moment(element.dateEcheance , 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
  //         companyName : element.companyName,
  //         address : element.address,
  //         postCode : element.postCode,
  //         town : element.town ,
  //         payment : element.payment,
  //         vatNumber : element.vatNumber,
  //         contactName : element.contactName,
  //         email : element.email,
  //         code : element.code,
  //         remiseHT : element.remiseHT,
  //         status : element.status,
  //       }
  //   ]);

  //   let tempSalesDetails = salesDetails.filter((o) => o.idSalesItems == element.id ) ;
  //   let tempSalesDetailsOmitted = tempSalesDetails.map((element) =>  _.omit(element, ['id', 'createdAt', 'updatedAt', 'deletedAt']) )
  //   let tempSalesDetailsCorrectId = tempSalesDetailsOmitted.map((element) => ({...element, idSalesItems : maxSalesItemId + 1}) )

  //  const { status: statusSalesDetails, data: dataSalesDetails, message: messageSalesDetails } = await updateSalesDetails(tempSalesDetailsCorrectId);
  
  //   if(statusSalesItems == 'success' && statusSalesDetails == 'success'){
  //     setFetchSalesItems(true);
  //   }
  //   else {
  //     console.log(messageSalesItems || messageSalesDetails )
  //   }
  }
  
  const navigateToChantierDetails = (id) => {
    history.push({
      pathname: '/chantier-details',
      state: id
    });
  }

  const openSalesItem = (id) => {
    history.push({
      pathname: '/ventes',
      state: id
    });
  }

    return(
        <Router
          active='Suppliers'
        >
          <Header />
          
              <div style={styles.detailContainer}>
                <Field
                  label="Code entreprise :"
                  value={currentSupplierId}
                  inline={12}
                  disabled={true}
                />
                <Field
                  label="Nom de l'entreprise * :"
                  value={form?.companyName || ''}
                  setValue={(e) => setForm('companyName', e.target.value)}
                  inline={12}
                />
              
                <div className="slider mr-3 ml-3 mt-4 mb-4">
                  <div className={(currentTab == 'informations') ? 'active' : ''} onClick={() => setCurrentTab('informations')}>Informations générales</div>
                  <div className={(currentTab == 'contacts') ? 'active' : ''} onClick={() => setCurrentTab('contacts')}>Contacts</div>
                  <div className={(currentTab == 'financier') ? 'active' : ''} onClick={() => setCurrentTab('financier')}>Informations financières</div>
                  <div className={(currentTab == 'documents') ? 'active' : ''} onClick={() => setCurrentTab('documents')}>Documents Internes</div>
                  <div className={(currentTab == 'chantiers') ? 'active' : ''} onClick={() => setCurrentTab('chantiers')}>Chantiers</div>
                </div>


                {currentTab && currentTab == 'documents' && (
                <div className="mt-3" style={styles.tab}>
                  
                  <div className='px-3'>
                    <FilterBox
                      typeFilter={typeFilter}
                      setTypeFilter={setTypeFilter}
                    />
                    <Table
                      headers = {['N° de Document','Type de Document','Date','Tiers', 'Chantier', 'Montant HT','Montant TTC','Statut','Action']}
                      data = {salesItems}
                      filterBoolean = { (o) => (typeFilter ? typeFilter.includes(o.type) : o) && o.idSupplier == currentSupplierId }
                      actions= {[
                        { name: 'navigate', action: (element) => openSalesItem(element.id) }
                      ]}
                      tds = {[
                        { action: (element) => element.documentNumber},
                        { action: (element) => typeLabel[element.type]},
                        { action: (element) => moment(element?.dateFacturation).format('DD/MM/YYYY')},
                        { action: (element) => suppliers?.filter(o => o.id == element.idSupplier)[0]?.companyName },
                        { action: (element) => chantiers?.filter(o => o.id == element.idChantier)[0]?.site },
                        { action: (element) => element?.montantHT?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") },
                        { action: (element) => element?.montantTTC?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") },
                        { action: (element) => statutText?.[element?.type]?.[element?.status] },
                        ]}
                    />
                    </div>
                  </div>
                )}

                {currentTab && currentTab == 'chantiers' && (
                <div className="mt-3" style={styles.tab}>
                  <div className="form-row mr-4 ml-4 ">
                      <Table 
                        headers = {['Client', 'Libellé','Code','Désignation travaux','Contact','Action']}
                        data = {(currentSupplierId && chantiers)}
                        filterBoolean = { (o) => {
                          const selection = salesItems.filter(k => k.idSupplier == currentSupplierId).map(k => k.idChantier);
                          return selection.includes(o.id);
                        }}
                        actions= {[
                          { name: 'navigate', action: (element) => navigateToChantierDetails(element.id) },
                        ]}
                        tds = {[
                          { action: (element) => element.site },
                          { action: (element) => element.code },
                          { action: (element) => element.designationTravaux },
                          { action: (element) => getDisplayContactName(element.idContact) }
                        ]}
                      />

                  </div>
                </div>
                )}

                { currentTab && currentTab == 'informations' && (
                <div className='mt-3' style={styles.tab}>
                  {/* <div className="text-center small text-muted mb-4 mt-4">Adresse de facturation</div> */}
                  <Field 
                    label="Statut * :"
                    value={form?.status || ''}
                    setValue={(e) => setForm('status', e.target.value)}
                    element='select'
                    inline={12}
                    options={[
                      { value: 'supplier', label: 'Fournisseur'},
                      { value: 'prestataire', label: 'Sous-traitant'}
                    ]}
                  />
                  <Field
                    label="Adresse principale * :"
                    value={form?.address || ''}
                    setValue={(e) => setForm('address', e.target.value)}
                    inline={12}
                  />
                  <Field
                    label="Adresse secondaire * :"
                    value={form?.addressFacturation || ''}
                    setValue={(e) => setForm('addressFacturation', e.target.value)}
                    inline={12}
                  />
                  <div className="d-flex">
                    <Field
                      label='Code Postal * :'
                      type='number'
                      value={form?.postCode || ''}
                      setValue={(e) => setForm('postCode', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='Ville * :'
                      value={form?.town || ''}
                      setValue={(e) => setForm('town', e.target.value)}
                      inline={6}
                    />
                  </div>
                  <Field
                    label='Pays * :'
                    value={form?.pays || ''}
                    setValue={(e) => setForm('pays', e.target.value)}
                    inline={12}
                  />
                  <div className="d-flex">
                    <Field
                      label='Telephone * :'
                      type='tel'
                      value={form?.phone || ''}
                      setValue={(e) => setForm('phone', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='Fax * :'
                      type='tel'
                      value={form?.faxNumber || ''}
                      setValue={(e) => setForm('faxNumber', e.target.value)}
                      inline={6}
                    />
                  </div>

                  <Field
                    label='Email * :'
                    type='email'
                    value={form?.email || ''}
                    setValue={(e) => setForm('email', e.target.value)}
                    inline={12}
                  />

                  <div className="d-flex">
                    <Field
                      label='RCI / SIRET * :'
                      value={form?.rciNumber || ''}
                      setValue={(e) => setForm('rciNumber', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='N° TVA * :'
                      value={form?.vatNumber || ''}
                      setValue={(e) => setForm('vatNumber', e.target.value)}
                      inline={6}
                    />
                  </div>        
                  
                  
                </div>
                )}

                { currentTab && currentTab == 'financier' && (
                <div className="mt-3">
                  <Field
                    className='mt-3'
                    label="Mode de règlement * :"
                    value={form?.modeReglement || ''}
                    element='select'
                    setValue={(e) => setForm('modeReglement', e.target.value)}
                    options={[
                      { value : 'cheques', label : 'Cheques' },
                      { value : 'virement', label : 'Virement' },
                      { value : 'traites', label : 'Traites' }
                    ]}
                    inline={12}
                  />
                  <Field
                    label="Condition de règlement * :"
                    value={form?.payment || ''}
                    setValue={(e) => setForm('payment', e.target.value)}
                    inline={12}
                  />

                  <div className="d-flex">

                    <Field
                      label="IBAN * :"
                      value={form?.iban || ''}
                      setValue={(e) => setForm('iban',e.target.value)}
                      inline={6}
                    />
                    <Field
                      label="Code BIC / SWIFT : "
                      value={form?.bic || ''}
                      setValue={(e) => setForm('bic', e.target.value)}
                      inline={6}
                    />
                  </div>
                  <Field
                    label="Nom, prénom ou raison sociale du bénéficiaire :"
                    value={form?.beneficiary || ''}
                    setValue={(e) => setForm('beneficiary', e.target.value)}
                    inline={12}
                  />

                  <Field
                    label="Nom banque : "
                    value={form?.bank || ''}
                    setValue={(e) => setForm('bank', e.target.value)}
                    inline={12}
                  />

                  <div className="d-flex">
                    <Field
                      label='Ville :'
                      value={form?.townFacturation || ''}
                      setValue={(e) => setForm('townFacturation', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='Pays :'
                      value={form?.paysFacturation || ''}
                      setValue={(e) => setForm('paysFacturation', e.target.value)}
                      inline={6}
                    />
                  </div>


                </div>
                  )}


                    { currentTab && currentTab == 'contacts' && (
                      <div>
                        {/* <div className="text-center small text-muted mb-4 mt-4">Contacts</div> */}
                        <div className="form-row mr-3 ml-3 ">
                          <button className="btn btn-dark mb-3 mt-3" onClick={() => newContact()} >Nouveau contact</button>
                            <Table 
                              headers = {['Nom *','Prénom *', 'Fonction','Portable','Téléphone Fixe','Email','Commentaire','Action']}
                              data = {(form?.contacts || [])}
                              filterBoolean = { (o) => o.idSupplier == currentSupplierId }
                              actions= {[
                                { name: 'edit' },
                                { name: 'delete', action: (element) => deleteContact(element.id) },
                              ]}
                              tds = {[
                                { action: (element) => (element.lastName || '') , editAction: (index, text, e) => editContact(index , text , e) , text : 'lastName' },
                                { action: (element) => (element.firstName || '') , editAction: (index, text, e) => editContact(index , text , e) , text : 'firstName'},
                                { action: (element) => (element.fonction || '')  , editAction: (index, text, e) => editContact(index , text , e) , text : 'fonction' },
                                { action: (element) => (element.phone || '')  , editAction: (index, text, e) => editContact(index , text , e) , text : 'phone' },
                                { action: (element) => (element.phoneFix || '' ) , editAction: (index, text, e) => editContact(index , text , e) , text : 'phoneFix' },
                                { action: (element) =>  (element.email || '' ) , editAction: (index, text, e) => editContact(index , text , e) , text : 'email' },
                                { action: (element) => (element.comment || '')  , editAction: (index, text, e) => editContact(index , text , e) , text : 'comment' },
                              ]}
        
                            />
                        </div>
                      </div>
                    )}

                  <div className="form-row mr-3 ml-3 justify-content-between">
                    <button className="btn btn-outline-danger mb-2" onClick={(e) => { setIsDeletingSuppliers(true); deleteSupplier(e) }}>
                      { isDeletingSuppliers &&
                          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden='true'>
                          </div>
                      } 
                      { (isDeletingSuppliers) ? 'Supprimer...' : 'Supprimer' }</button>
                    <button className="btn btn-outline-success mb-2" onClick={(e) => { setIsUpdatingSuppliers(true); upsertSupplierContact(e) }}>
                      { isUpdatingSuppliers &&
                          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden='true'>
                          </div>
                      } 
                      { (isUpdatingSuppliers) ? 'Enregistrement...' : 'Enregistrer' }</button>
                  </div>
              </div>
      </Router>
    )
}


const styles = {
    supplierList: {
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 150px)',
      padding: 0 
    },
    tab: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxHeight: 'calc(100% - 50px)'
    },
    inputSearch: {
      width: '70%'
    },
    detailContainer: {
      height: 'calc(100vh - 58px - 2*20px)',
      marginTop: 20,
      marginBottom: 20,
      boxShadow: "-0.35rem 0 0.25rem -0.25rem rgba(67, 67, 67, 0.15)" 
    }
}


export default SuppliersDetails;
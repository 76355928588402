import { SET_SALESITEMS, SET_CURRENT_SALESITEM } from "../actions/salesItems";

const initialState = {
  currentSalesItem: null,
  salesItems: []
}

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_SALESITEMS: {
      return {
        ...state,
        salesItems: action.payload.salesItems
      }
    }
    case SET_CURRENT_SALESITEM: {
      return {
        ...state,
        currentSalesItem: action.payload.salesItemId
      }
    }
    default:
      return state;
  }
}

export default resolver;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Main, UserManagement, Clients, ClientsDetails, Ventes , AllSalesItems , TimeTracker, Employees , EmployeesDetails ,ChantiersDetails, Achats ,Suppliers, SuppliersDetails , NewAchat , ExportSchedules , AllChantiers } from '@pages';
import Auth from "@components/Auth";
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from '@redux/store';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Auth>
        <Router>
          <Switch>
            <Route exact path='/'>
              <Main />
            </Route>
            <Route exact path='/users-management'>
              <UserManagement />
            </Route>
            <Route exact path='/clients'>
              <Clients />
            </Route>
            <Route exact path='/clients-details'>
              <ClientsDetails />
            </Route>
            <Route exact path='/ventes'>
              <Ventes />
            </Route>
            <Route exact path='/sales-items'>
              <AllSalesItems />
            </Route>
            <Route exact path='/achats'>
              <Achats/>
            </Route>
            <Route exact path='/nouvel-achat'>
              <NewAchat/>
            </Route>
            <Route exact path='/time-tracker'>
              <TimeTracker/>
            </Route>
            <Route exact path='/employees'>
              <Employees/>
            </Route> 
            <Route exact path='/employees-details'>
              <EmployeesDetails/>
            </Route>
            <Route exact path='/chantier-details'>
              <ChantiersDetails/>
            </Route>
            <Route exact path='/suppliers'>
              <Suppliers/>
            </Route>
            <Route exact path='/suppliers-details'>
              <SuppliersDetails/>
            </Route>
            <Route exact path='/export-schedules'>
              <ExportSchedules/>
            </Route>
            <Route exact path='/chantiers'>
              <AllChantiers/>
            </Route>
          </Switch>
        </Router>
      </Auth>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



import * as queries from '@queries/utils';

export const fetchMe = () => queries.getQuery('/api/users/me');

export const fetchUsers = () => queries.getQuery('/api/users', 'populate=files');

export const createUsers = (users) => queries.postQuery('/api/users', users, false);

export const updateUsers = (users) => queries.putQuery(`/api/users`, users, false);

export const deleteUsers = (usersToDelete) => queries.deleteQuery(`/api/users`, usersToDelete);
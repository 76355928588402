import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryClient, useQuery, useMutation } from "react-query";

import { optionsByType, dateLabel } from "@root/config";
import { useForm } from "@hooks";

import {
  fetchSalesItems,
  createSalesItems,
  updateSalesItems,
  deleteSalesItems,
} from "@queries/SalesItems";
import {
  createSalesDetails,
  updateSalesDetails,
  deleteSalesDetails,
} from "@queries/SalesDetails";
import { createUpload, deleteUpload } from "@queries/Upload";
import { fetchChantiers } from "@queries/Chantiers";
import { fetchClients } from "@queries/Clients";
import baseUrl, {typeLabel} from "@root/config";
import { fetchId, updateIds } from "@queries/IdSales";

import Header from "@components/Header";
import Table from "@components/Table";
import Field from "@components/Field";
import Router from "@components/Router";
import Upload from "@components/Upload";

const checkIfTitle = (data) => data?.filter((s) => s?.detail === 0)?.every(s => Boolean(s?.index))

function Ventes() {
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  let { id: currentSalesItemId, copy } = location.state;

  const [salesDetails, setSalesDetails] = useState([]);
  const [currentTab, setCurrentTab] = useState("lignes");
  const [filesToDelete, setFilesToDelete] = useState([]);

  const [ids, setIds] = useState({});

  const [form, setForm] = useForm({
    remiseHt: 0,
    vat: 20,
    type: "Devis",
    documentNumber: ids?.hasOwnProperty("Devis")
      ? (ids["Devis"] + 1).toString()
      : "",
  });

  useQuery("ids", fetchId, {
    onSuccess: (data) => {
      setIds(data?.data?.attributes?.map);
    },
  });

  // Fetch data

  // Automatic populate / reupdate salesItem structure based on the currentSalesItem

  const resetIds = useMutation((data) => updateIds(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("ids");
    },
  });

  const chantiersAll = useQuery("chantiers", fetchChantiers);
  const clientsAll = useQuery("clients", fetchClients);
  const salesItemsAll = useQuery("salesItems", fetchSalesItems, {
    onSuccess: (data) => {
      const salesItem = data?.data
        ?.filter((o) => o?.id === currentSalesItemId)
        ?.map((o) => ({
          id: o?.id,
          ...o?.attributes,
          sales_item: null,
          files: [],
          ...(o?.attributes?.idChantier?.data?.id && {
            idChantier: o?.attributes?.idChantier?.data?.id,
          }),
          ...(o?.attributes?.idClient?.data?.id && {
            idClient: o?.attributes?.idClient?.data?.id,
          }),
          ...(o?.attributes?.sales_item?.data?.id && {
            sales_item: o?.attributes?.sales_item?.data?.id,
          }),
          ...(!o?.attributes?.remiseHt && { remiseHt: 0 }),
          ...(!o?.attributes?.vat && { vat: 20 }),
          ...(o?.attributes?.files?.data && {
            files: o?.attributes?.files?.data?.map((k) => ({
              id: k?.id,
              ...k?.attributes,
            })),
          }),
        }))
        ?.at(0) || {
        remiseHt: 0,
        vat: 20,
        type: "Devis",
        documentNumber: ids?.hasOwnProperty("Devis")
          ? (ids["Devis"] + 1)?.toString()
          : "",
      };

      if (salesItem?.idClient?.data === null) delete salesItem.idClient;

      if (salesItem?.idChantier?.data === null) delete salesItem.idChantier;

      if (copy) {
        delete salesItem?.id;
        setSalesDetails(
          salesItem?.sales_details?.data?.map((o) => ({ ...o?.attributes })) ||
            []
        );
      } else
        setSalesDetails(
          salesItem?.sales_details?.data?.map((o) => ({
            id: o?.id,
            ...o?.attributes,
          })) || []
        );

      setForm("all", salesItem);
    },
  });

  const chantiers = chantiersAll.isSuccess
    ? chantiersAll.data?.data?.map((o) => ({ id: o?.id, ...o?.attributes }))
    : [];
  const salesItems = salesItemsAll.isSuccess
    ? salesItemsAll.data?.data?.map((o) => ({ id: o?.id, ...o?.attributes }))
    : [];
  const clients = clientsAll.isSuccess
    ? clientsAll.data?.data?.map((o) => ({ id: o?.id, ...o?.attributes }))
    : [];

  // Modify data

  const createSalesItemsMutation = useMutation((salesItems) =>
    createSalesItems(salesItems)
  );
  const updateSalesItemsMutation = useMutation((salesItems) =>
    updateSalesItems(salesItems)
  );

  const createSalesDetailsMutation = useMutation((salesDetails) =>
    createSalesDetails(salesDetails)
  );
  const updateSalesDetailsMutation = useMutation((salesDetails) =>
    updateSalesDetails(salesDetails)
  );

  const deleteSalesDetailsMutation = useMutation((ids) =>
    deleteSalesDetails(ids)
  );
  const deleteSalesItemsMutation = useMutation((ids) => deleteSalesItems(ids));

  const createUploadMutation = useMutation((uploads) => createUpload(uploads));
  const deleteUploadMutation = useMutation((ids) => deleteUpload(ids));

  const [isUpdatingSalesItems, setIsUpdatingSalesItems] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [salesDetailsToDelete, setSalesDetailsToDelete] = useState([]);

  const createSalesItem = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const montantHt = form?.montantHt
      ? form?.montantHt
      : _.sumBy(
          salesDetails,
          (o) => (o.amountHt * o.quantity * (o?.completion || 100)) / 100 || 0
        ) - (form?.remiseHt || 0);

    const montantTtc = form?.montantTtc
      ? form?.montantTtc
      : montantHt * (1 + form?.vat / 100);
    const { sales_details, sales_items, files, ...salesItem } = form;

    const result = await createSalesItemsMutation.mutateAsync([
      {
        ...salesItem,
        remiseHt: form?.remiseHt || 0,
        dateFacturation: moment(form?.dateFacturation).format("YYYY-MM-DD"),
        dateEcheance: moment(form?.dateEcheance).format("YYYY-MM-DD"),
        montantHt,
        montantTtc,
      },
    ]);

    await createSalesDetailsMutation.mutateAsync(
      salesDetails.map((o) => ({
        ...o,
        idSalesitem: result?.at(0)?.data?.data?.id,
      }))
    );

    if (files?.filter((o) => !o?.id)?.length > 0) {
      const filesToUpload = new FormData();

      for (let file of files?.filter((o) => !o?.id)) {
        filesToUpload.append("files", file.files, file.name);
      }

      filesToUpload.append("ref", "api::sales-item.sales-item");
      filesToUpload.append("field", "files");
      filesToUpload.append("refId", result?.at(0)?.data?.data?.id);

      await createUploadMutation.mutateAsync([filesToUpload]);
    }

    queryClient.invalidateQueries("salesItems");

    history.goBack();
  };

  const removeSalesItems = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const toDelete = [
      ...salesDetails.map((o) => o?.id),
      ...salesDetailsToDelete,
    ];

    await deleteSalesDetailsMutation.mutateAsync(toDelete);
    await deleteSalesItemsMutation.mutateAsync([currentSalesItemId]);
    queryClient.invalidateQueries("salesItems");

    history.goBack();
  };

  const updateSalesItem = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { sales_details, sales_items, files, ...salesItem } = form;

    const montantHt = ['DevisVente', 'FactureVente'].includes(form?.type)
      ? _.sumBy(
        salesDetails,
        (o) => (o.amountHt * o.quantity * (o?.completion || 100)) / 100 || 0
      ) - (form?.remiseHt || 0)
      :form?.montantHt

    const montantTtc = form?.montantTtc
      ? form?.montantTtc
      : montantHt * (1 + form?.vat / 100);
      
    if (files?.filter((o) => !o?.id)?.length > 0) {
      const filesToUpload = new FormData();

      for (let file of files?.filter((o) => !o?.id)) {
        filesToUpload.append("files", file.files, file.name);
      }

      filesToUpload.append("ref", "api::sales-item.sales-item");
      filesToUpload.append("field", "files");
      filesToUpload.append("refId", form?.id);

      await createUploadMutation.mutateAsync([filesToUpload]);
    }

    await deleteUploadMutation.mutateAsync(filesToDelete);
    await deleteSalesDetailsMutation.mutateAsync(salesDetailsToDelete);
    await createSalesDetailsMutation.mutateAsync(
      salesDetails?.filter((o) => !o?.id)
    );
    await updateSalesDetailsMutation.mutateAsync(
      salesDetails?.filter((o) => o?.id)
    );
    await updateSalesItemsMutation.mutateAsync([
      {
        ...salesItem,
        remiseHt: form?.salesItem || 0,
        dateFacturation: moment(form?.dateFacturation).format("YYYY-MM-DD"),
        dateEcheance: moment(form?.dateEcheance).format("YYYY-MM-DD"),
        montantHt,
        montantTtc,
      },
    ]);
    queryClient.invalidateQueries("salesItems");

    history.goBack();
  };

  const newSalesDetailsItemInter = (devis = false) => {
    if (salesDetails.length === 0) return;
    deleteSalesDetailItem(salesDetails.length - 1);

    setSalesDetails((prev) => [
      ...prev,
      {
        detail: 1,
        idSalesitem: currentSalesItemId,
        completion: (devis) ? 100 : 0
      },
      {
        idSalesitem: currentSalesItemId,
        detail: 2,
        isSubTotal: true,
      },
    ]);
  };

  const newSalesDetailsItemHeader = () =>
    setSalesDetails((prev) => [
      ...prev,
      {
        detail: 0,
        idSalesitem: currentSalesItemId,
      },
      {
        idSalesitem: currentSalesItemId,
        detail: 2,
        isSubTotal: true,
      },
    ]);


  const editItem = (row, index, value) => {
    const temp = JSON.parse(JSON.stringify(salesDetails));
    temp[row][index] = value;
    setSalesDetails(temp);
  };

  const deleteSalesDetailItem = (row) => {
    if (salesDetails?.at(row)?.id)
      setSalesDetailsToDelete((prev) => [...prev, salesDetails?.at(row)?.id]);

    setSalesDetails((prev) => [...prev.slice(0, row), ...prev.slice(row + 1)]);
  };

  const computeSubTotal = (row) => {
    const indexLast = _.findLastIndex(
      salesDetails,
      (o) => o.isSubTotal,
      row - 1
    );
    let result = 0;

    for (let i = indexLast > -1 ? indexLast : 0; i < row; i++)
      result +=
        (parseFloat(salesDetails?.at(i)?.amountHt) *
          parseFloat(salesDetails?.at(i)?.quantity) *
          parseFloat(salesDetails?.at(i)?.completion)) /
          100 || 0;

    return result;
  };

  const deleteFile = (index) => {
    if (form?.files[index].hasOwnProperty("id")) {
      let tempFilesToDelete = JSON.parse(JSON.stringify(filesToDelete));
      console.log(tempFilesToDelete);
      tempFilesToDelete.push(form?.files[index].id);
      setFilesToDelete(tempFilesToDelete);
    }

    let tempFiles = JSON.parse(JSON.stringify(form?.files));
    setForm("files", [
      ...tempFiles.slice(0, index),
      ...tempFiles.slice(index + 1),
    ]);
  };

  return (
    <Router active="Documents">
      <Header />

      <div className="mt-4">
        <div className="mr-3 d-flex">
          <Field
            disabled={(currentSalesItemId && !copy)}
            label="Type de document *"
            element="select"
            setValue={(e) => {
              setForm("type", e.target.value);
              if (ids?.hasOwnProperty(e.target.value)) {
                let tmp = JSON.parse(JSON.stringify(ids));
                tmp[e.target.value] += 1;
                setForm("documentNumber", `${tmp[e.target.value].toString()}/${moment().format('YY')}`);
              }
            }}
            value={form?.type || ""}
            options={[
                { value: 'DevisVente', label: 'Vente - Devis' },
                { value: 'FactureVente', label: 'Vente - Facture' },
                { value: 'Règlements', label: 'Financier - Règlement' }
            ]}
            inline={6}
          />
          <Field
            label="Clients"
            element="select"
            setValue={(e) => setForm("idClient", e.target.value)}
            value={form?.idClient || ""}
            options={clients.map((val, index) => ({
              value: val.id,
              label: val.companyName,
            }))}
            inline={6}
          />
        </div>
        <div className="mr-3 d-flex">
          <Field
            label="Libellé"
            setValue={(e) => setForm("libelle", e.target.value)}
            value={form?.libelle || ""}
            inline={6}
          />
          <Field
            label="Chantiers"
            element="select"
            setValue={(e) => setForm("idChantier", e.target.value)}
            value={form?.idChantier || ""}
            options={chantiers
              ?.filter((o) =>
                form?.idClient ? o?.idClient?.data?.id == form?.idClient : true
              )
              ?.map((val, index) => ({ value: val.id, label: val.site }))}
            inline={6}
          />
        </div>
        <div className="mr-3 d-flex">
          <Field
            label="Date du document"
            type="date"
            setValue={(e) => setForm("dateFacturation", e.target.value)}
            value={moment(form?.dateFacturation).format("YYYY-MM-DD")}
            inline={6}
          />
           <Field
                label="Statut"
                element='select'
                setValue={(e) => setForm('status', e.target.value)}
                value={form?.status || ''}
                options={optionsByType[form?.type]}
                inline={6}
            />
        </div>
        <div className="mr-3 d-flex">
          <Field
            label="N° de Document"
            setValue={(e) => setForm("documentNumber", e.target.value)}
            value={form?.documentNumber || ""}
            inline={6}
          />
           <Field
            label="Document lié"
            element="select"
            setValue={(e) => setForm("sales_item", e.target.value)}
            value={form?.sales_item || ""}
            options={salesItems
              ?.filter((val) => ({
                'DevisVente': '',
                'FactureVente': 'DevisVente',
                'Règlements': 'FactureVente'
              })?.[form?.type]?.includes(val?.type))
              ?.map((val, index) => ({ value: val.id, label: `${typeLabel?.[val?.type]} : ${val.documentNumber}` }))}
            inline={6}
          />
        </div>
        {currentTab &&
          currentTab === "lignes" &&
          ["DevisVente", "FactureVente"].includes(form?.type) && (
            <div>
              <div>
                <div className="row justify-content-end mr-3">
                  <div className="mr-3">
                    <button
                      className="btn btn-dark justify-self-end mb-2"
                      onClick={() => newSalesDetailsItemHeader()}
                    >
                      Nouveau titre
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-dark justify-self-end mb-2"
                      onClick={() => newSalesDetailsItemInter(!["FactureVente"].includes(form?.type))}
                    >
                      Nouvel élément
                    </button>
                  </div>
                </div>

                <div className="mx-3">
                  <div className="datatable table-responsive">
                    <table
                      className="table table-hover mb-0"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>Art.</th>
                          <th style={{ width: "14%" }}>Titre</th>
                          <th style={{ width: "25%" }}>Désignation</th>
                          <th style={{ width: "10%" }}>U</th>
                          <th style={{ width: "10%" }}>Quantité</th>
                          <th style={{ width: "10%" }}>%</th>
                          <th style={{ width: "10%" }}>P.U (HT €)</th>
                          <th style={{ width: "10%" }}>P.T (HT €)</th>
                          <th style={styles.alignEnd}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesDetails?.map((element, index) => (
                          <tr key={index} style={styles.cursor}>
                            <td>
                              {element?.detail === 0 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="text"
                                  value={element?.index}
                                  onChange={(e) =>
                                    editItem(index, "index", e.target.value)
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail !== 2 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="text"
                                  value={element?.title}
                                  onChange={(e) =>
                                    editItem(index, "title", e.target.value)
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail !== 2 && (
                                <textarea
                                  style={{ height: 100, minHeight: 100 }}
                                  className="form-control form-control-solid"
                                  value={element?.description}
                                  onChange={(e) =>
                                    editItem(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  value={element?.unite}
                                  onChange={(e) => {
                                    editItem(index, "unite", e.target.value);
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="number"
                                  value={element?.quantity}
                                  required
                                  onChange={(e) => {
                                    editItem(
                                      index,
                                      "quantity",
                                      e.target.value || 0
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="number"
                                  value={element?.completion}
                                  onChange={(e) =>
                                    editItem(
                                      index,
                                      "completion",
                                      e.target.value || 0
                                    )
                                  }
                                  disabled={!["FactureVente"].includes(form?.type)}
                                  readOnly={!["FactureVente"].includes(form?.type)}
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="number"
                                  value={element?.amountHt}
                                  onChange={(e) =>
                                    editItem(
                                      index,
                                      "amountHt",
                                      e.target.value || 0
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail !== 0 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="text"
                                  value={
                                    element?.isSubTotal
                                      ? computeSubTotal(index)
                                      : (element?.amountHt *
                                          element?.quantity *
                                          element?.completion) /
                                          100 || 0
                                  }
                                  disabled
                                  readOnly
                                />
                              )}
                            </td>
                            <td style={styles.alignEnd}>
                              <button
                                className="btn btn-datatable btn-icon btn-transparent-dark"
                                onClick={(e) => deleteSalesDetailItem(index)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="mb-2 mr-3 d-flex flex-column align-items-end">
                <h5
                  className="text-gray-600 d-flex justify-content-between"
                  style={styles.total}
                >
                  <span>Total HT:</span>
                  <span>
                    {_.sumBy(
                      salesDetails,
                      (o) =>
                        (o.amountHt * o.quantity * o?.completion) / 100 || 0
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between align-items-center mt-1"
                  style={styles.total}
                >
                  <span>Remise HT:</span>
                  <span className="d-flex align-items-center">
                    -{" "}
                    <input
                      id="remise"
                      className="form-control form-control-solid mr-1"
                      type="number"
                      style={styles.inputTotal}
                      value={form?.remiseHt || 0}
                      onChange={(e) => setForm("remiseHt", e.target.value)}
                    />{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1"
                  style={styles.total}
                >
                  <span>Net Total HT:</span>
                  <span>
                    {(
                      _.sumBy(
                        salesDetails,
                        (o) =>
                          (o.amountHt * o.quantity * o?.completion) / 100 || 0
                      ) - (form?.remiseHt || 0)
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1 align-items-center "
                  style={styles.total}
                >
                  <span>Taux TVA:</span>
                  <select
                    id="tva-selectoption"
                    className="form-control form-control-solid"
                    style={{ width: 120 }}
                    value={parseFloat(form?.vat) || 20}
                    onChange={(e) => {
                      setForm("vat", e.target.value);
                    }}
                  >
                    <option value={""}></option>
                    <option value={0}>0%</option>
                    <option value={5.5}>5.5%</option>
                    <option value={10}>10%</option>
                    <option value={20}>20%</option>
                  </select>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1"
                  style={styles.total}
                >
                  <span>Total TVA:</span>
                  <span>
                    {(
                      (_.sumBy(
                        salesDetails,
                        (o) =>
                          (o.amountHt * o.quantity * o?.completion) / 100 || 0
                      ) -
                        (form?.remiseHt || 0)) *
                      (form?.vat / 100 || 0)
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1"
                  style={styles.total}
                >
                  <span>Total TTC: </span>
                  <span>
                    {(
                      (_.sumBy(
                        salesDetails,
                        (o) =>
                          (o.amountHt * o.quantity * o?.completion) / 100 || 0
                      ) -
                        (form?.remiseHt || 0)) *
                      (1 + form?.vat / 100 || 0)
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
              </div>
            </div>
          )}

        {currentTab &&
          currentTab === "lignes" &&
          ["DevisIntervention"].includes(form?.type) && (
            <div>
              <div>
                <div className="row justify-content-end mr-3">
                  {/* <div className="mr-3">
                    <button
                      id="nouveau-sous-total"
                      className="btn btn-dark justify-self-end mb-2"
                      onClick={() => newSalesDetailsSubTotal()}
                    >
                      Nouveau Sous-Total
                    </button>
                  </div> */}
                  <div className="mr-3">
                    <button
                      className="btn btn-dark justify-self-end mb-2"
                      onClick={() => newSalesDetailsItemHeader()}
                    >
                      Nouveau Titre
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-dark justify-self-end mb-2"
                      onClick={() => newSalesDetailsItemInter()}
                    >
                      Nouvel élément
                    </button>
                  </div>
                </div>

                <div className="mx-3">
                  <div className="datatable table-responsive">
                    <table
                      className="table table-hover mb-0"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          {/* <th style={{width: '5%'}}>Titre</th> */}
                          <th style={{ width: "5%" }}>Art.</th>
                          <th style={{ width: "9%" }}>date</th>
                          {/* <th style={{ width: "9%" }}>id</th> */}
                          <th style={{ width: "14%" }}>Titre</th>
                          <th style={{ width: "20%" }}>Désignation</th>
                          <th style={{ width: "8%" }}>U</th>
                          <th style={{ width: "7%" }}>Quantité</th>
                          <th style={{ width: "8%" }}>%</th>
                          <th style={{ width: "10%" }}>P.U (HT €)</th>
                          <th style={{ width: "10%" }}>P.T (HT €)</th>
                          <th style={styles.alignEnd}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesDetails?.map((element, index) => (
                          <tr key={index} style={styles.cursor}>
                            {/* <td className='text-center pt-4'>
                                                            { element?.isSubTotal ? null : <input type="checkbox" checked={element?.isTitle} onChange={(e) => editItem(index, 'isTitle', e.target.checked)}  /> }
                                                        </td> */}
                            <td>
                              {element?.detail === 0 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="text"
                                  value={element?.index}
                                  onChange={(e) =>
                                    editItem(index, "index", e.target.value)
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail === 0 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="text"
                                  value={element?.date}
                                  onChange={(e) =>
                                    editItem(index, "date", e.target.value)
                                  }
                                />
                              )}
                            </td>
                            {/* <td>
                              {element?.detail === 0 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="text"
                                  value={element?.id_devis}
                                  onChange={(e) =>
                                    editItem(index, "id_devis", e.target.value)
                                  }
                                />
                              )}
                            </td> */}
                            <td>
                              {element?.detail === 0 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="text"
                                  value={element?.title}
                                  onChange={(e) =>
                                    editItem(index, "title", e.target.value)
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail !== 2 && (
                                <textarea
                                  style={{ height: 100, minHeight: 100 }}
                                  className="form-control form-control-solid"
                                  value={element?.description}
                                  onChange={(e) =>
                                    editItem(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  value={element?.unite}
                                  onChange={(e) => {
                                    editItem(index, "unite", e.target.value);
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="number"
                                  value={element?.quantity}
                                  required
                                  onChange={(e) => {
                                    editItem(
                                      index,
                                      "quantity",
                                      e.target.value || 0
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="number"
                                  value={element?.completion}
                                  required
                                  onChange={(e) => {
                                    editItem(
                                      index,
                                      "completion",
                                      e.target.value || 0
                                    );
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {element?.detail === 1 && (
                                <input
                                  className="form-control form-control-solid"
                                  type="number"
                                  value={element?.amountHt}
                                  onChange={(e) =>
                                    editItem(
                                      index,
                                      "amountHt",
                                      e.target.value || 0
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              <input
                                className="form-control form-control-solid"
                                type="text"
                                value={
                                  element?.isSubTotal
                                    ? computeSubTotal(index)
                                    : (element?.amountHt *
                                        element?.quantity *
                                        element?.completion) /
                                        100 || 0
                                }
                                disabled
                                readOnly
                              />
                            </td>
                            <td style={styles.alignEnd}>
                              <button
                                className="btn btn-datatable btn-icon btn-transparent-dark"
                                onClick={(e) => deleteSalesDetailItem(index)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="mb-2 mr-3 d-flex flex-column align-items-end">
                <h5
                  className="text-gray-600 d-flex justify-content-between"
                  style={styles.total}
                >
                  <span>Total HT:</span>
                  <span>
                    {_.sumBy(
                      salesDetails,
                      (o) =>
                        (o.amountHt * o.quantity * o?.completion) / 100 || 0
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between align-items-center mt-1"
                  style={styles.total}
                >
                  <span>Remise HT:</span>
                  <span className="d-flex align-items-center">
                    -{" "}
                    <input
                      id="remise"
                      className="form-control form-control-solid mr-1"
                      type="number"
                      style={styles.inputTotal}
                      value={form?.remiseHt || 0}
                      onChange={(e) => setForm("remiseHt", e.target.value)}
                    />{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1"
                  style={styles.total}
                >
                  <span>Net Total HT:</span>
                  <span>
                    {(
                      _.sumBy(
                        salesDetails,
                        (o) =>
                          (o.amountHt * o.quantity * o?.completion) / 100 || 0
                      ) - (form?.remiseHt || 0)
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1 align-items-center "
                  style={styles.total}
                >
                  <span>Taux TVA:</span>
                  <select
                    id="tva-selectoption"
                    className="form-control form-control-solid"
                    style={{ width: 120 }}
                    value={parseFloat(form?.vat) || 20}
                    onChange={(e) => {
                      setForm("vat", e.target.value);
                    }}
                  >
                    <option value={""}></option>
                    <option value={0}>0%</option>
                    <option value={5.5}>5.5%</option>
                    <option value={10}>10%</option>
                    <option value={20}>20%</option>
                  </select>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1"
                  style={styles.total}
                >
                  <span>Total TVA:</span>
                  <span>
                    {(
                      (_.sumBy(
                        salesDetails,
                        (o) =>
                          (o.amountHt * o.quantity * o?.completion) / 100 || 0
                      ) -
                        (form?.remiseHt || 0)) *
                      (form?.vat / 100 || 0)
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
                <h5
                  className="text-gray-600 d-flex justify-content-between mt-1"
                  style={styles.total}
                >
                  <span>Total TTC: </span>
                  <span>
                    {(
                      (_.sumBy(
                        salesDetails,
                        (o) =>
                          (o.amountHt * o.quantity * o?.completion) / 100 || 0
                      ) -
                        (form?.remiseHt || 0)) *
                      (1 + form?.vat / 100 || 0)
                    )?.toFixed(2)}{" "}
                    €
                  </span>
                </h5>
              </div>
            </div>
          )}

        {((currentTab && currentTab === "documents") ||
          ["Règlements"].includes(form?.type)) && (
          <>
          <div className="mr-3 d-flex">
            <Field
              label="Montant HT"
              setValue={(e) => setForm("montantHt", e.target.value)}
              value={form?.montantHt || ""}
              inline={6}
            />
            <Field
              label="Montant TTC"
              setValue={(e) => setForm("montantTtc", e.target.value)}
              value={Math.round(form?.montantTtc * 100)/100 || ""}
              inline={6}
            />
          </div>
          <div className="mt-4" style={styles.tab}>
            <div className="px-3">
              <Table
                headers={["N° de Document", "Type de Document", "Action"]}
                data={form?.files}
                actions={[
                  {
                    name: "download",
                    action: (element) =>
                      window.open(`${baseUrl}${element?.url}`, "_blank"),
                  },
                  {
                    name: "delete",
                    action: (element, index) => deleteFile(index),
                  },
                ]}
                tds={[
                  { action: (element) => element.name },
                  { action: (element) => element.mime },
                ]}
              />

              <Upload
                files={form?.files || []}
                setFiles={(data) => setForm("files", data)}
              />
            </div>
          </div>
          </>
        )}

        <div className="form-row mr-3 ml-3 justify-content-between">
          <button
            className="btn btn-outline-danger mb-2"
            onClick={(e) => {
              setIsDeleting(true);
              removeSalesItems(e);
            }}
          >
            {isDeleting && (
              <div
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></div>
            )}
            {isDeleting ? "Supprimer..." : "Supprimer"}
          </button>
          <button
            className="btn btn-outline-success mb-2"
            onClick={(e) => {
              setIsUpdatingSalesItems(true);

              const isTitle = checkIfTitle(salesDetails);

              if (!isTitle) {
                alert ("Renseignez le champs Art. pour l'ensemble des titres avant d'enregistrer");
                setIsUpdatingSalesItems(false);
              } else if (form?.id) updateSalesItem(e);
              else {
                createSalesItem(e);
                if (ids?.hasOwnProperty(form.type)) {
                  let tmp = JSON.parse(JSON.stringify(ids));
                  tmp[form.type] += 1;
                  resetIds.mutateAsync([tmp]);
                }
              }
            }}
          >
            {isUpdatingSalesItems && (
              <div
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></div>
            )}
            {isUpdatingSalesItems ? "Enregistrement..." : "Enregistrer"}
          </button>
        </div>
      </div>
    </Router>
  );
}

const styles = {
  cursor: {
    cursor: "pointer",
  },
  total: {
    width: 400,
  },
  inputTotal: {
    width: 100,
  },
};

export default Ventes;

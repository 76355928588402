import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link } from "react-router-dom";
import Avatar from '@components/Avatar';
import axios from 'axios';
import baseUrl from '@root/config';
import { fetchMe } from '@queries/Users';

const Header = () => {

  // Fetch data
  const me = useQuery('me', fetchMe);  
  const logout = () => {
    localStorage.removeItem('token');
    window.location.replace('/');
  };
  
  // Use State
  const [show, setShow] = useState(false);

  return (
    <nav className={"topnav navbar navbar-expand shadow"}>
        <Link className="navbar-brand" to="/">
            <span className="mb-0 logoFont">Monaco Domotique</span>
        </Link>
        <ul className="navbar-nav align-items-center ml-auto">
            <li className="nav-item dropdown no-caret mr-3 dropdown-user">
              <a className="nav-link dropdown-toggle btn-light" style={styles.circle} onClick={() => setShow(!show)}>
              <Avatar
                firstName={(me.isSuccess) ? me.data?.firstName : 'John'}
                lastName={(me.isSuccess) ? me.data?.lastName : 'Doe'}
               />
              </a>

              {
                show && ( 
                <ul className="dropdown-menu dropdown-menu-right show" >
                  { 
                    me.isSuccess && me.data?.type == 'admin' && <li><Link className="dropdown-item" to="/users-management">Gérer les utilisateurs</Link></li>
                  }
                  <li><a className="dropdown-item" href="#" onClick={() => logout()}>Déconnexion</a></li>
                </ul>
                )
              }
              
            </li>
        </ul>
    </nav>
  );
}

const styles = {
    logo: {
      width: 132,
      height: 75
    },
    by: {
      color: 'rgba(0,0,0,0.5)',
      fontWeight: 500
    },
    brand: {
      fontSize: 15,
      fontWeight: 400
    },
    circle: {
      borderRadius: '50%',
      cursor: 'pointer'
    }
}

export default Header;
import { useState } from 'react';

const useForm = (data = {}) => {
    const [form, setForm] = useState(data);

    const setKeyForm = (key, value) => {
        if (key == 'all')
            setForm(value);
        else
            setForm((prev) => ({
                ...prev,
                [key]: value
            }));
    }
    
    return [form, setKeyForm]
}

export default useForm;
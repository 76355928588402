export default {
  DEV: "http://localhost:4100",
  STAGING: "https://md-staging-back.herokuapp.com",
  PROD: "https://md-prod-back.herokuapp.com",
}[process.env.REACT_APP_ENV || "DEV"];

export const statutText = {
  DevisVente: {
    saisie: "Saisie",
    validationGM: "Validation GM",
    transmisClient: "Transmis client",
    accepte: "Accepté",
    refuse: "Refusé",
  },
  FactureVente: {
    saisie: "Saisie",
    validationGM: "Validation GM",
    transmisClient: "Transmis client",
    regle: "Réglée",
    relance: "Relancé",
  },
  SituationChantier: {
    saisie: "Saisie",
    validationGM: "Validation GM",
    transmisClient: "Transmis client",
    regle: "Réglée",
    relance: "Relancé",
  },
  CommandesVente: {
    envoye: "Envoyé",
    arc: "ARC Ok",
    attente: "En attente de livraison",
    partiel: "Livraison partielle",
    total: "Livraison totale",
  },
  BonsdelivraisonVente: {
    attente: "En attente de livraison",
    partiel: "Livraison partielle",
    total: "Livraison totale",
  },
  CommandesFournisseur: {
    saisie: "Saisie",
    attente: "En attente de livraison",
    partiel: "Livraison partielle",
    total: "Livraison totale",
  },
  FactureFournisseur: {
    saisie: "Saisie",
    regle: "Réglée",
  },
  CommandesSousTraitant: {
    saisie: "Saisie",
    attente: "En attente de livraison",
    partiel: "Livraison partielle",
    total: "Livraison totale",
  },
  FactureSousTraitant: {
    saisie: "Saisie",
    regle: "Réglée",
  },
  Règlements: {
    regle: "Réglé",
  },
};

export const optionsByType = {
  DevisVente: [
    { value: "saisie", label: "Saisie" },
    { value: "validationGM", label: "Validation GM" },
    { value: "transmisClient", label: "Transmis client" },
    { value: "accepte", label: "Accepté" },
    { value: "refuse", label: "Refusé" },
  ],
  FactureVente: [
    { value: "saisie", label: "Saisie" },
    { value: "validationGM", label: "Validation GM" },
    { value: "transmisClient", label: "Transmis client" },
    { value: "regle", label: "Réglée" },
    { value: "relance", label: "Relancé" },
  ],
  CommandesVente: [
    { value: "envoye", label: "Envoyé" },
    { value: "arc", label: "ARC Ok" },
    { value: "attente", label: "En attente de livraison" },
    { value: "partiel", label: "Livraison partielle" },
    { value: "total", label: "Livraison totale" },
  ],
  BonsdelivraisonVente: [
    { value: "attente", label: "En attente de livraison" },
    { value: "partiel", label: "Livraison partielle" },
    { value: "total", label: "Livraison totale" },
  ],
  SituationChantier: [
    { value: "saisie", label: "Saisie" },
    { value: "validationGM", label: "Validation GM" },
    { value: "transmisClient", label: "Transmis client" },
    { value: "regle", label: "Réglée" },
    { value: "relance", label: "Relancé" },
  ],
  CommandesFournisseur: [
    { value: "saisie", label: "Saisie" },
    { value: "attente", label: "En attente de livraison" },
    { value: "partiel", label: "Livraison partielle" },
    { value: "total", label: "Livraison totale" },
  ],
  FactureFournisseur: [
    { value: "saisie", label: "Saisie" },
    { value: "regle", label: "Réglée" },
  ],
  CommandesSousTraitant: [
    { value: "saisie", label: "Saisie" },
    { value: "attente", label: "En attente de livraison" },
    { value: "partiel", label: "Livraison partielle" },
    { value: "total", label: "Livraison totale" },
  ],
  FactureSousTraitant: [
    { value: "saisie", label: "Saisie" },
    { value: "regle", label: "Réglée" },
  ],
  Règlements: [{ value: "regle", label: "Réglé" }],
};

export const dateLabel = {
  DevisVente: "émission",
  CommandesVente: "commande",
  BonsdelivraisonVente: "livraison",
  FactureVente: "facturation",
  CommandesFournisseur: "commande",
  BonsréceptionFournisseur: "réception",
  BonsderetourFournisseur: "retour",
  FactureFournisseur: "facturation",
  CommandesSousTraitant: "commande",
  FactureSousTraitant: "facturation",
  Règlements: "règlement",
  SituationChantier: "situation",
  "": "facturation",
};

export const typeLabel = {
  DevisVente: "Vente - Devis",
  Facture: "Facture - Classique",
  DevisIntervention: "Devis - Intervention",
  Devis: "Devis - classique",
  CommandesVente: "Vente - Commande",
  BonsdelivraisonVente: "Vente - Bons de livraison",
  FactureVente: "Vente - Facture",
  SituationChantier: "Vente - Situation de chantier",
  CommandesFournisseur: "Fournisseur - Commande",
  BonsréceptionFournisseur: "Fournisseur - Bon de réception",
  BonsderetourFournisseur: "Fournisseur - Bons de retour",
  FactureFournisseur: "Fournisseur - Facture",
  CommandesSousTraitant: "Sous Traitant - Commande",
  FactureSousTraitant: "Sous Traitant - Facture",
  Règlements: "Financier - Règlement",
};

export const taskText = {
  devis: "Devis/Etude/Recherche",
  etude: "Etude plan synoptique",
  integration: "Intégration/Paramétrage",
  programmation: "Programmation",
  depannage: "Dépannage SAV",
  divers: "Divers (manutention/déplacement)",
  maintenance: "Maintenance",
  recuperation: "Récupération",
  conges: "Congés CCPB",
  maladie: "Maladie",
  ferie: "Férié",
};

export const defaultTemplate = {
  Devis: {
    header: 'Valeur "mois" 2021',
    footer: `"autoliquidation-Tva due par le preneur-article 62-4 sexies du Code des taxes sur le chiffre d’affaires"

Conditions générales de vente :
    
- le client reconnaît avoir vérifié l’adéquation de l’offre , comme étant conforme à ses besoins, et avoir reçu de Monaco Domotique toutes les informations et conseils qui lui étaient nécessaires pour souscrire au présent engagement en connaissance de cause.
- Le matériel/logiciel fournit reste la propriété de Monaco Domotique jusqu’au règlement complet de celui-ci par le client.
- toutes modifications ultérieures à la signature de ce présent devis fera l’objet d’un devis complémentaire.
- les temps estimatifs sont conclus en fonction des éléments fournis et connus le jour de l’établissement du présent devis . En cas de temps supplémentaire, la personne en charge de l’installation enverra un mémoire de travaux TS.
- la matériel du présent devis est garanti par leur constructeur respectif. En cas d’intervention de Monaco Domotique dans le cadre de cette garantie , le temps passé sera facturé au taux horaire en vigueur à la date de l’intervention.
- en cas de litige seuls les tribunaux de Monaco sont compétents.

Avancement des travaux au 25 du mois.`,
  },
  Facture: {
    header: "Selon le devis n°XXXX du XXXX dûment accepté par vos soins",
    footer: "En votre aimable règlement, à réception de facture",
  },
};

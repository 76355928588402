export const SET_CHANTIERS = "SET_CHANTIERS";
export const ADD_CHANTIER = "ADD_CHANTIER";
export const UPDATE_CHANTIER = "UPDATE_CHANTIER";
export const  SET_CURRENT_CHANTIER = " SET_CURRENT_CHANTIER";

export const setChantiers = chantiers => ({
  type: SET_CHANTIERS,
  payload: {
    chantiers
  }
});

export const setCurrentChantier = chantierId => ({
  type: SET_CURRENT_CHANTIER,
  payload: {
      chantierId
  }
})
import { SET_ACHATSITEMS, SET_CURRENT_ACHATSITEM } from "../actions/achatsItems";

const initialState = {
  currentAchatsItem: null,
  achatsItems: []
}

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_ACHATSITEMS: {
      return {
        ...state,
        achatsItems: action.payload.achatsItems
      }
    }
    case SET_CURRENT_ACHATSITEM: {
      return {
        ...state,
        currentAchatsItem: action.payload.achatsItemId
      }
    }
    default:
      return state;
  }
}

export default resolver;
import axios from 'axios';
import baseUrl from '@root/config';

const config = { 
    headers: 
    { 
        Authorization: `Bearer ${localStorage.getItem('token')}` 
    } 
};

export const getQuery = (url, args='populate=*') => axios.get(baseUrl + url + '?' + args, config ).then(res => res.data);

export const postQuery = async (url, arr, wrapped=true) => {
    const result = [];

    for(let element of arr)
        result.push(await axios.post(baseUrl + url, (wrapped) ? { data: element } : element , config));

    return result;
};

export const putQuery = (url, arr, wrapped=true) => Promise.all(arr.map(element => axios.put(baseUrl + url + `/${element.id}`, (wrapped) ? { data: element } : element, config)));

export const deleteQuery = (url, arr) => Promise.all(arr.map(id => axios.delete(baseUrl + url + `/${id}`, config)));


import React from 'react';
import Header from '@components/Header';
import Table from '@components/Table';
import { fetchUsers } from '@queries/Users';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import Router from '@components/Router';

function UserManagement() {
    const history = useHistory();
    const queryClient = useQueryClient();

    // Fetch data
    const usersAll = useQuery('users', fetchUsers); 

    // Working data 
    const users = (usersAll.isSuccess) ? usersAll.data?.filter((o) => !o.deletedAt) : [];

    const navigateToEmployeeDetails = (id) => history.push({
      pathname: '/employees-details',
      state: id
    });


    return (
        <Router
          active="Employees"
        >
            <Header />
            <div className='mr-3 ml-3'>

              <div className="form-row justify-content-end mr-1">
                <Table 
                  headers = {['Prénom','Nom','Email','N° Téléphone', 'Actions']}
                  data = {( users )}
                  actions= {[
                    { name: 'navigate', action: (element) => navigateToEmployeeDetails(element.id) },
                  ]}
                  tds = {[
                    { action: (element) =>  element.firstName },
                    { action: (element) =>  element.lastName },
                    { action: (element) =>  element.email },
                    { action: (element) =>  element.phone }
                  ]}
                />

              </div>
          </div>
        </Router>
    )
}

const styles = {
    nouveauDocument : {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    alignEnd: {
      textAlign: 'end'
    },
    cursor: {
      cursor: 'pointer'
    }
  }
  

export default UserManagement;

export const SET_SALESDETAILS = "SET_SALESDETAILS";
export const ADD_SALESDETAIL = "ADD_SALESDETAIL";
export const UPDATE_SALESDETAIL = "UPDATE_SALESDETAIL";

export const setSalesDetails = salesDetails => ({
  type: SET_SALESDETAILS,
  payload: {
    salesDetails
  }
});

export const addSalesDetail = salesDetail => ({
  type: ADD_SALESDETAIL,
  payload: {
    salesDetail
  }
});


export const updateSalesDetail = (id, salesDetail) => ({
  type: UPDATE_SALESDETAIL,
  payload: {
    id,
    salesDetail
  }
});
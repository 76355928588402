import { SET_USERS , SET_CURRENT_USER} from "../actions/users";

const initialState = {
  currentUserId: null,
  users: []
}

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        users: action.payload.users
      }
    }
    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUserId: action.payload.userId
      }
    }
    default:
      return state;
  }
}

export default resolver;
import { findIndex } from "lodash";
import { SET_CHANTIERS , ADD_CHANTIER , UPDATE_CHANTIER , SET_CURRENT_CHANTIER } from "../actions/chantiers";

const initialState = {
  currentChantierId: null,
  chantiers: []
}

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_CHANTIERS: {
      return {
        ...state,
        chantiers: action.payload.chantiers
      }
    } 

    case ADD_CHANTIER: {
      return [
          ...state , 
          action.payload.chantier
      ]
    } 

    case UPDATE_CHANTIER: {
      const tempChantier = JSON.parse(JSON.stringify(state));
      const index = findIndex(tempChantier,(o) => o.id == action.payload.id);
      tempChantier[index] = action.payload.chantier;
      return tempChantier ;
    } 

    case SET_CURRENT_CHANTIER: {
      return {
        ...state,
        currentChantierId: action.payload.chantierId
      }
    }

    default:
      return state;
  }
}

export default resolver;
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { saveAs } from "file-saver";
import { typeLabel, statutText } from "@root/config";
import { fetchSalesItems } from "@queries/SalesItems";
import { downloadWord } from "@queries/World";
import Table from "@components/Table";
import Header from "@components/Header";
import Router from "@components/Router";
import FilterBox from "@components/FilterBox";
import { mapFillDocx } from "./wordsFill";
import _ from 'lodash';

function AllSalesItems() {
  const location = useLocation();
  const history = useHistory();

  const salesItemsAll = useQuery("salesItems", fetchSalesItems);
  const [content, setContent] = useState();
  useQuery("worlds", downloadWord, {
    onSuccess: (data) => {
      setContent(data);
    },
  });
  const salesItems = salesItemsAll?.isSuccess
    ? salesItemsAll?.data?.data?.map((o) => ({ id: o?.id, ...o?.attributes }))
    : [];

  const [search, setSearch] = useState("");
  const [typeFilter, setTypeFilter] = useState(location?.state?.type || "");

  const openSalesItem = (id) => {
    history?.push({
      pathname: "/ventes",
      state: {
        id,
        copy: false,
      },
    });
  };

  const copySalesItem = (id) => {
    history?.push({
      pathname: "/ventes",
      state: {
        id,
        copy: true,
      },
    });
  };

  const generateDocument = (e) => {
    var zip = new PizZip(content[e?.type].data);
    var doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    mapFillDocx[e?.type](doc, e);
    try {
      doc?.render();
    } catch (error) {
      function replaceErrors(key, value) {
        if (value instanceof Error) {
          return Object?.getOwnPropertyNames(value).reduce(function (
            error,
            key
          ) {
            error[key] = value[key];
            return error;
          },
          {});
        }
        return value;
      }
      if (error?.properties && error?.properties?.errors instanceof Array) {
        const errorMessages = error?.properties?.errors
          .map(function (error) {
            return error?.properties?.explanation;
          })
          .join("\n");
        console?.log("errorMessages", errorMessages);
        // errorMessages is a humanly readable message looking like this :
        // 'The tag beginning with "foobar" is unopened'
      }
      throw error;
    }
    var out = doc?.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd?.openxmlformats-officedocument?.wordprocessingml?.document",
    }); //Output the document using Data-URI
    saveAs(out, e?.type + ".docx");
  };

  return (
    <Router active="Documents">
      <Header />
      <div className="mr-3 ml-3 ">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button
            className="btn btn-dark align-self-start"
            onClick={() => openSalesItem(null)}
          >
            Nouveau document
          </button>

          <div className="input-group mb-3" style={styles?.inputSearch}>
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fas fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher un document ..."
              aria-label="Client_Search"
              aria-describedby="basic-addon1"
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>
        </div>

        <div className="mt-2">
          <FilterBox typeFilter={typeFilter} setTypeFilter={setTypeFilter} />
        </div>

        <div className="form-row mr-1 ml-1 justify-content-end">
          <Table
            headers={[
              "N° de Document",
              "Type de Document",
              "Date",
              "Tiers",
              "Chantier",
              "Montant HT",
              "Montant TTC",
              "Montant Réglé",
              "Statut",
              "Action",
            ]}
            data={salesItems}
            filterBoolean={(o) =>
              (typeFilter ? typeFilter?.includes(o?.type) : o) &&
              (o?.documentNumber
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) ||
                (o?.type &&
                  typeLabel[o?.type]
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase())) ||
                (o?.montantHt &&
                  o?.montantHt
                    ?.toFixed(2)
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase())) ||
                (o?.montantTtc &&
                  o?.montantTtc
                    ?.toFixed(2)
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase())) ||
                (o?.companyName &&
                  o?.companyName?.toLowerCase().includes(search?.toLowerCase())) ||
                moment(o?.dateFacturation)
                  .format("DD/MM/YYYY")
                  .toLowerCase()
                  .includes(search?.toLowerCase()) ||
                o?.idClient?.data?.attributes?.companyName
                  .toLowerCase()
                  .includes(search?.toLowerCase()) ||
                o?.idChantier?.data?.attributes?.site
                  .toLowerCase()
                  .includes(search?.toLowerCase()))
            }
            actions={[
              {
                name: "navigate",
                action: (element) => openSalesItem(element?.id),
              },
              { name: "copy", action: (element) => copySalesItem(element?.id) },
              {
                name: "download",
                action: (element) => generateDocument(element),
                display: (element) => element?.type !== 'Règlements'
              },
            ]}
            tds={[
              {
                highlight: search,
                action: (element) =>
                  search
                    ? element?.documentNumber?.replace(
                        new RegExp("(" + search + ")", "ig"),
                        "<span class='highlight'>$1</span>"
                      )
                    : element?.documentNumber,
              },
              {
                highlight: search,
                action: (element) =>
                  search
                    ? typeLabel[element?.type].replace(
                        new RegExp("(" + search + ")", "ig"),
                        "<span class='highlight'>$1</span>"
                      )
                    : typeLabel[element?.type],
              },
              {
                highlight: search,
                action: (element) =>
                  search
                    ? moment(element?.dateFacturation)
                        .format("DD/MM/YYYY")
                        .replace(
                          new RegExp("(" + search + ")", "ig"),
                          "<span class='highlight'>$1</span>"
                        )
                    : moment(element?.dateFacturation).format("DD/MM/YYYY"),
              },
              {
                highlight: search,
                action: (element) =>
                  search
                    ? element?.idClient?.data?.attributes?.companyName?.replace(
                        new RegExp("(" + search + ")", "ig"),
                        "<span class='highlight'>$1</span>"
                      )
                    : element?.idClient?.data?.attributes?.companyName,
              },
              {
                highlight: search,
                action: (element) =>
                  search
                    ? element?.idChantier?.data?.attributes?.site?.replace(
                        new RegExp("(" + search + ")", "ig"),
                        "<span class='highlight'>$1</span>"
                      )
                    : element?.idChantier?.data?.attributes?.site,
              },
              {
                highlight: search,
                action: (element) =>
                  search
                    ? element?.montantHt
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        .replace(
                          new RegExp("(" + search + ")", "ig"),
                          "<span class='highlight'>$1</span>"
                        )
                    : element?.montantHt
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              },
              {
                highlight: search,
                action: (element) =>
                  search
                    ? element?.montantTtc
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        .replace(
                          new RegExp("(" + search + ")", "ig"),
                          "<span class='highlight'>$1</span>"
                        )
                    : element?.montantTtc
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              },
              {
                highlight: search,
                action: (element) => (element?.type === 'FactureVente') ? _?.sumBy(salesItems?.filter(o => o?.type === 'Règlements' && o?.sales_item?.data?.id === element?.id), 'montantTtc')?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '',
              },
              { highlight: search, action: (element) => statutText?.[element?.type]?.[element?.status] },
            ]}
          />
        </div>
      </div>
    </Router>
  );
}

const styles = {
  clientList: {
    overflowY: "scroll",
    maxHeight: "calc(100vh - 150px)",
    padding: 0,
  },
  inputSearch: {
    width: "80%",
  },
  detailContainer: {
    height: "calc(100vh - 58px - 2*20px)",
    marginTop: 20,
    marginBottom: 20,
    boxShadow: "-0.35rem 0 0.25rem -0.25rem rgba(67, 67, 67, 0?.15)",
  },
};

export default AllSalesItems;

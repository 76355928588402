import Devis from "./fncDevis";

function DevisIntervention(doc, e) {
  let ensemble = [];
  let table = [];
  let i = 0;
  while (i < e?.sales_details?.data?.length) {
    let res = 0;
    for (
      ;
      e.sales_details?.data?.at(i)?.attributes?.index === null &&
      i < e?.sales_details?.data?.length;
      i++
    );
    let ensembleObj = {
      title: e.sales_details.data?.at(i)?.attributes?.title,
      cost: "",
    };
    let obj = {
      nb: e.sales_details.data?.at(i)?.attributes.index,
      inter_date: e.sales_details.data?.at(i)?.attributes?.date,
      title: e.sales_details.data?.at(i)?.attributes?.title,
      description: e.sales_details.data?.at(i)?.attributes?.description,
      inter_nb: e.sales_details.data?.at(i)?.attributes?.id_devis,
      list: [],
      u: [],
      q: [],
      e: [],
      pu: [],
      total: "",
    };
    i += 1;
    for (
      ;
      e.sales_details?.data?.at(i)?.attributes?.index === null &&
      e?.sales_details.data?.at(i)?.attributes.isSubTotal !== true &&
      i < e?.sales_details?.data?.length;
      i++
    ) {
      obj?.list?.push({
        mylist: e?.sales_details.data?.at(i)?.attributes.description,
      });
      obj?.u?.push({
        v: e?.sales_details.data?.at(i)?.attributes?.unite,
      });
      obj.q?.push({
        z: e.sales_details.data?.at(i)?.attributes?.quantity,
      });
      obj.pu?.push({
        p: e.sales_details.data?.at(i)?.attributes?.amountHt?.toFixed(2),
      });

      obj?.e?.push({
        eu: (
          e.sales_details.data?.at(i)?.attributes?.quantity *
          (e.sales_details.data?.at(i)?.attributes?.completion / 100) *
          e.sales_details.data?.at(i)?.attributes?.amountHt
        )?.toFixed(2),
      });
      res +=
        e.sales_details.data?.at(i)?.attributes?.quantity *
        (e.sales_details.data?.at(i)?.attributes?.completion / 100) *
        e.sales_details.data?.at(i)?.attributes?.amountHt;
    }
    ensembleObj.cost = res?.toFixed(2);
    ensemble.push(ensembleObj);
    obj.total = res.toFixed(2);
    table.push(obj);
    i++;
  }
  doc.setData({
    social_reason: "Raison sociale",
    gender: "Monsieur",
    name: e?.idClient?.data?.attributes?.beneficiary,
    last_name: e?.idClient?.data?.attributes?.bank,
    adress: e?.idClient?.data?.attributes?.address,
    city_code: e?.idClient?.data?.attributes?.postCode.toString(),
    date: "06/66/1991",
    devis_nb: e.documentNumber,
    chantier_name: e?.idChantier?.data?.attributes?.site,
    inter_date: e?.idChantier?.data?.attributes?.dateDebut,
    client: e?.idClient?.data?.attributes?.companyName,
    test: table,
    total: e?.montantHt?.toFixed(2),
    ht: e?.montantHt?.toFixed(2),
    tva: ((e?.montantHt * e?.vat) / 100)?.toFixed(2),
    ttc: e?.montantTtc?.toFixed(2),
    ensemble: ensemble,
    TVA: e?.vat,
  });
}

export const mapFillDocx = {
  DevisVente: Devis,
  FactureVente: Devis,
  DevisIntervention: DevisIntervention,
};

import React from 'react';

const SalesBar = (props) => {
  return (
    <div className="d-flex flex-1 w-100">
        {props.data && props.data.map((val, index) => (
            <a key={index} className="m-1 lift p-2 flex-grow-1" style={styles[val.color]} onClick={val.action}>
                <p style={styles.title}>{val.value?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€ HT</p>
                <p className="mt-1" style={styles.description}>{val.description}</p>
            </a>
        ))}
    </div>
  );
}

const styles = {
    blue: {
        backgroundColor: 'deepskyblue',
        color: 'white',
        cursor: 'pointer'
    },
    orange: {
        backgroundColor: 'orange',
        color: 'white',
        cursor: 'pointer'
    },
    green: {
        backgroundColor: 'var(--success)',
        color: 'white',
        cursor: 'pointer'
    },
    red: {
        backgroundColor: 'var(--danger)',
        color: 'white',
        cursor: 'pointer'
    },
    title: {
        margin: 0,
        fontSize: '1.5rem',
        lineHeight: '1.5rem'
    },
    description: {
        margin: 0,
        fontSize: '1.0rem',
        lineHeight: '1.0rem'
    }
}

export default SalesBar;
export const SET_ACHATSITEMS = "SET_ACHATSITEMS";
export const SET_CURRENT_ACHATSITEM = "SET_CURRENT_ACHATSITEM";

export const setAchatsItems = achatsItems => ({
  type: SET_ACHATSITEMS,
  payload: {
    achatsItems
  }
});

export const setCurrentAchatsItem = achatsItemId => ({
    type: SET_CURRENT_ACHATSITEM,
    payload: {
        achatsItemId
    }
})
import React from 'react';
import Field from '@components/Field';

const FilterBox = ({
    typeFilter = '',
    setTypeFilter = () => {}
}) => {

  return (
    <div className='d-flex ml-5'>
        <div className="d-flex flex-column align-items-start w-25" style={{ height: 'fit-content'}}>
            <Field
            styleLabel={{ fontWeight: 'bolder', fontSize: 15}}
            label=": VENTES"
            type="checkbox"
            inline={12}
            value={typeFilter.includes('DevisVente') && typeFilter.includes('CommandesVente') && typeFilter.includes('BonsdelivraisonVente') && typeFilter.includes('FactureVente')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' DevisVente CommandesVente BonsdelivraisonVente FactureVente SituationChantier' : prev.replace(/DevisVente|CommandesVente|BonsdelivraisonVente|FactureVente|SituationChantier/gi, '').trim()))}
            />
            <Field
            label=": Devis"
            type="checkbox"
            inline={12}
            htmlFor='DevisVente'
            value={typeFilter.includes('DevisVente')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' DevisVente' : prev.replace(' DevisVente', '')))}
            />
            {/* <Field
            className=""
            label=": Commandes"
            type="checkbox"
            inline={12}
            htmlFor='CommandesVente'
            value={typeFilter.includes('CommandesVente')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' CommandesVente' : prev.replace(' CommandesVente', '')))}
            /> */}
            {/* <Field
            className=""
            label=": Bons de livraison"
            type="checkbox"
            inline={12}
            htmlFor='BonsLivraisonVente'
            value={typeFilter.includes('BonsdelivraisonVente')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' BonsdelivraisonVente' : prev.replace(' BonsdelivraisonVente', '')))}
            /> */}
            <Field
            className=""
            label=": Factures"
            type="checkbox"
            inline={12}
            htmlFor='FactureVente'
            value={typeFilter.includes('FactureVente')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' FactureVente' : prev.replace(' FactureVente', '')))}
            />
            {/* <Field
            label=": Situation de Chantier"
            type="checkbox"
            inline={12}
            htmlFor='SituationChantier'
            value={typeFilter.includes('SituationChantier')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' SituationChantier' : prev.replace(' SituationChantier', '')))}
            /> */}
        </div>
        {/* <div className="d-flex flex-column align-items-start w-25" style={{ height: 'fit-content'}}>
            <Field
            styleLabel={{ fontWeight: 'bolder', fontSize: 15}}
            label=": FOURNISSEURS"
            type="checkbox"
            inline={12}
            value={typeFilter.includes('CommandesFournisseur') && typeFilter.includes('BonsréceptionFournisseur') && typeFilter.includes('BonsderetourFournisseur') && typeFilter.includes('FacturesFournisseur')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' CommandesFournisseur BonsréceptionFournisseur BonsderetourFournisseur FacturesFournisseur' : prev.replace(/CommandesFournisseur|BonsréceptionFournisseur|BonsderetourFournisseur|FacturesFournisseur/gi, '').trim()))}
            />
            <Field
            label=": Commandes"
            type="checkbox"
            inline={12}
            htmlFor='CommandesFournisseur'
            value={typeFilter.includes('CommandesFournisseur')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' CommandesFournisseur' : prev.replace(' CommandesFournisseur', '')))}
            /> */}
            {/* <Field
            className=""
            label=": Bons réception"
            type="checkbox"
            inline={12}
            htmlFor='BonsReceptionFournisseur'
            value={typeFilter.includes('BonsréceptionFournisseur')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' BonsréceptionFournisseur' : prev.replace(' BonsréceptionFournisseur', '')))}
            /> */}
            {/* <Field
            className=""
            label=": Bons de retour"
            type="checkbox"
            inline={12}
            htmlFor='BonsRetourFournisseur'
            value={typeFilter.includes('BonsderetourFournisseur')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' BonsderetourFournisseur' : prev.replace(' BonsderetourFournisseur', '')))}
            /> */}
            {/* <Field
            className=""
            label=": Factures"
            type="checkbox"
            inline={12}
            htmlFor='FacturesFournisseur'
            value={typeFilter.includes('FacturesFournisseur')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' FacturesFournisseur' : prev.replace(' FacturesFournisseur', '')))}
            />
        </div> */}
        {/* <div className="d-flex flex-column align-items-start w-25" style={{ height: 'fit-content'}}>
            <Field
            styleLabel={{ fontWeight: 'bolder', fontSize: 15}}
            label=": SOUS TRAITANTS"
            type="checkbox"
            inline={12}
            value={typeFilter.includes('CommandesSousTraitant') && typeFilter.includes('FacturesSousTraitant')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' CommandesSousTraitant FacturesSousTraitant' : prev.replace(/CommandesSousTraitant|FacturesSousTraitant/gi, '').trim()))}
            />
            <Field
            label=": Commandes"
            type="checkbox"
            inline={12}
            htmlFor='CommandesSousTraitant'
            value={typeFilter.includes('CommandesSousTraitant')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' CommandesSousTraitant' : prev.replace(' CommandesSousTraitant', '')))}
            />
            <Field
            className=""
            label=": Factures"
            type="checkbox"
            inline={12}
            htmlFor='FacturesSousTraitan'
            value={typeFilter.includes('FacturesSousTraitant')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' FacturesSousTraitant' : prev.replace(' FacturesSousTraitant', '')))}
            />
        </div> */}
        <div className="d-flex flex-column align-items-start w-25" style={{ height: 'fit-content'}}>
            <Field
            styleLabel={{ fontWeight: 'bolder', fontSize: 15}}
            label=": FINANCIER"
            type="checkbox"
            inline={12}
            value={typeFilter.includes('Echéances') && typeFilter.includes('Règlements')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' Echéances Règlements' : prev.replace(/Echéances|Règlements/gi, '').trim()))}
            />
            {/* <Field
            label=": Echéances"
            type="checkbox"
            inline={12}
            htmlFor='Echeances'
            value={typeFilter.includes('Echéances')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' Echéances' : prev.replace(' Echéances', '')))}
            /> */}
            <Field
            className=""
            label=": Règlements"
            type="checkbox"
            inline={12}
            htmlFor='Reglements'
            value={typeFilter.includes('Règlements')}
            setValue={(e) => setTypeFilter((prev) => (e.target.checked ? prev.trim() + ' Règlements' : prev.replace(' Règlements', '')))}
            />
        </div>
    </div>
  );
}

const styles = {
}

export default FilterBox;
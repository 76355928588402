import Header from '@components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SalesBar from "@components/SalesBar";
import * as actions from '@redux/actions';
import Table from '@components/Table';
import Router from '@components/Router';

function Achats() {

  const dispatch = useDispatch();
  const history = useHistory();

  const suppliers = useSelector((state) => state.suppliers.suppliers.filter((o) => !o.deletedAt));
  
  const achatsItems = useSelector((state) => state.achatsItems.achatsItems.filter((o) => !o.deletedAt));

  const [search, setSearch] = useState('');
  const [currentDownloadingAchatsItem, setCurrentDownloadingAchatsItem] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);

  const [paidAmount, setPaidAmount] = useState(0);
  const [unpaidAmount, setUnpaidAmount] = useState(0);
  
  const openAchatsItem = (id) => {
    dispatch(actions.achatsItems.setCurrentAchatsItem(id));
    history.push('/nouvel-achat');
  }
  
  const downloadAchatsItemPDF = async (item) => {
    let filename = item.documentNumber;
      // use HTML5 a[download] attribute to specify filename
          let a = document.createElement("a");
              a.href = item.fileBase64;
              a.download = filename;
              a.click();
  }

 
  let paidArray = suppliers && achatsItems && achatsItems.filter((o) => ( o.status == 'paid') ).map((element,index) => element.id )
  let unpaidArray = suppliers && achatsItems && achatsItems.filter((o) => (  o.status == 'unpaid' )).map((element,index) => element.id )
 
  useEffect(() => {
    const getSummation = () => {
      let paidCounter = 0;
      let unpaidCounter = 0;
      
      paidArray.map((e) => {paidCounter = paidCounter + (parseFloat(achatsItems.filter((o) => o.id == e ).reduce((a,b) => a + b.amountHT , 0)) ) } )
      unpaidArray.map((e) => {unpaidCounter = unpaidCounter + (parseFloat(achatsItems.filter((o) => o.id == e ).reduce((a,b) => a + b.amountHT , 0)) )  ;  } ) 

      setPaidAmount(paidCounter)
      setUnpaidAmount(unpaidCounter)
    }
    getSummation()
}, [paidArray , unpaidArray])


    useEffect(async () => {
      if (currentDownloadingAchatsItem)
        downloadAchatsItemPDF(currentDownloadingAchatsItem)
      
      setCurrentDownloadingAchatsItem(null);
    }, [currentDownloadingAchatsItem]);

  return (
    <Router
      active='Achats'
    >
        <Header />
        
              <div className="mt-4 ml-3" style={styles.tab}>
                <div className="row pl-2 mr-3">
                <SalesBar
                    data={[
                      { color: 'blue', value: paidAmount, description: 'Payé', action: (() =>setTypeFilter("paid") )  },
                      { color: 'red', value: unpaidAmount, description: 'Non Payé', action: (() => setTypeFilter("unpaid") )  },
                    ]}
                  />
                </div>

                <div className="row mt-3  mr-3">
                    <div className="col input-group mb-3" style={styles.inputSearch}>
                      <div className="input-group-prepend">
                        <span className="input-group-text"><i className="fas fa-search"></i></span>
                      </div>
                       <input type="text" className="form-control" placeholder="Rechercher un achat ..." aria-label="Client_Search" aria-describedby="basic-addon1" onChange={(e) => setSearch(e.target.value)} />
                    </div>
      
                  <div className="justify-content-end">
                    <button className="btn btn-dark " onClick={() => {history.push('/nouvel-achat') ;  dispatch(actions.achatsItems.setCurrentAchatsItem(null)); }} >Nouvel Achat</button>
                  </div>
                </div>

                <div className="form-row mr-3 ml-1 justify-content-end">
                 <Table 
                      headers = {['Entreprise','N° de Document','Type de Document','Montant HT','VAT','Montant TTC','Statut','Action']}
                      data = {( suppliers.length>0  && achatsItems.length>0 && achatsItems )}
                      filterBoolean = { (o) =>  (typeFilter ?  ( o.status == typeFilter  ) :  o) && ( o.documentNumber.toLowerCase().includes(search.toLowerCase())  || o.type.toLowerCase().includes(search.toLowerCase()) || o.companyName.toLowerCase().includes(search.toLowerCase()) ||  o.status.toLowerCase().includes(search.toLowerCase())) && true  }
                      actions= {[
                        { name: 'navigate', action: (element) => openAchatsItem(element.id) },
                        { name: 'download', action:  (element) => setCurrentDownloadingAchatsItem(element) }
                      ]}
                      tds = {[
                        {action: (element) => (element.companyName)},
                        {action: (element) => (element.documentNumber)},
                        {action: (element) => (element.type)},
                        {action: (element) =>  element.amountHT  },
                        {action: (element) =>  element.amountHT *(element.vat/100)  },
                        {action: (element) => (parseFloat(element.amountHT) + parseFloat(element.amountHT *(element.vat/100)))?.toFixed(2) },
                        {action: (element) => (element.status)},
                       ]}
                    />
                </div>
              </div>
    </Router>
  )
}

const styles = {
  clientList: {
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 150px)',
    padding: 0 
  },
  tab: {
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 50px)'
  },
  inputSearch: {
    width: '70%'
  },
  detailContainer: {
    height: 'calc(100vh - 58px - 2*20px)',
    marginTop: 20,
    marginBottom: 20,
    boxShadow: "-0.35rem 0 0.25rem -0.25rem rgba(67, 67, 67, 0.15)" 
  }
}

export default Achats;

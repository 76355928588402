import { SET_SUPPLIERS , SET_CURRENT_SUPPLIER } from "../actions/suppliers";

const initialState = {
  currentSupplierId: null,
  suppliers: []
}

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_SUPPLIERS: {
      return {
        ...state,
        suppliers : action.payload.suppliers
      }
    }


    case SET_CURRENT_SUPPLIER: {
      return {
        ...state,
        currentSupplierId: action.payload.supplierId
      }
    }

    default:
      return state;
  }
}

export default resolver;
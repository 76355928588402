import { useState } from 'react';
import Header from '@components/Header';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { fetchChantiers } from '@queries/Chantiers';
import Table from '@components/Table';
import Router from '@components/Router';
import { useQuery,  useQueryClient } from 'react-query';

function AllChantiers() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [search, setSearch] = useState(''); 

  // Fetch Data 
  const chantiersAll = useQuery('chantiers', fetchChantiers);
  const chantiers = (chantiersAll?.isSuccess) ? chantiersAll?.data?.data?.map(o => (
    { 
      id: o?.id, 
      ...(_?.omit(o?.attributes, ["idClient", "idContact", "sales_items"] )), 
      salesItems: o?.attributes?.sales_items?.data?.map(v => ({ id: v?.id, ...v.attributes })),
      client: { id: o?.attributes?.idClient?.data?.id, ...o.attributes?.idClient?.data?.attributes },
      contact: { id: o?.attributes?.idContact?.data?.id, ...o.attributes?.idContact?.data?.attributes }
    }
  )) : [];


  const getDisplayContactName = (contact) => (contact && contact?.firstName + ' ' + contact?.lastName) || '';
  const getDisplayClient = (client) => client && client?.companyName;
  
  const navigateToChantierDetails = (id) => history?.push({
    pathname: '/chantier-details',
    state: id
  });

  const newChantier = () => history?.push('/chantier-details');

  return(
      <Router
        active='Chantiers'
      >
        <Header />
        
        <div className='mr-3 ml-3'>

          <div className='d-flex justify-content-between align-items-center mt-3'>
            <button className="btn btn-dark align-self-start" onClick={() => newChantier() } >Nouveau chantier</button>
            
            <div className="input-group mb-3" style={styles?.inputSearch}>

              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-search"></i></span>
              </div>
              <input type="text" className="form-control" placeholder="Rechercher un chantier ..." aria-label="Client_Search" aria-describedby="basic-addon1" onChange={(e) => setSearch(e?.target?.value)} />
            </div>
          </div>
          

          <div className="form-row justify-content-end mr-1">
            <Table 
              headers = {['Client','Libellé','Code','Désignation travaux','Contact','Action']}
              data = {( chantiers )}
              filterBoolean = {(o) => (
                o?.site?.toLowerCase()?.includes(search?.toLowerCase()) || 
                o?.code?.toLowerCase()?.includes(search?.toLowerCase()) ||
                o?.designationTravaux?.toLowerCase()?.includes(search?.toLowerCase()) ||
                getDisplayContactName(o?.contact)?.toLowerCase()?.includes(search?.toLowerCase()) ||
                getDisplayClient(o?.client)?.toLowerCase()?.includes(search?.toLowerCase())
              )}
              actions= {[
                { name: 'navigate', action: (element) => navigateToChantierDetails(element?.id) },
              ]}
              tds = {[
                {highlight: search , action: (element) => (search) ? getDisplayClient(element?.client)?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : getDisplayClient(element?.client)},
                {highlight: search , action: (element) => (search) ? element?.site?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.site},
                {highlight: search , action: (element) => (search) ? element?.code?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.code},
                {highlight: search , action: (element) => (search) ? element?.designationTravaux?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.designationTravaux},
                {highlight: search , action: (element) => (search) ? getDisplayContactName(element?.contact)?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : getDisplayContactName(element?.contact)},
              ]}
            />

          </div>
        </div>
      </Router>
    )
}


const styles = {
    clientList: {
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 150px)',
      padding: 0 
    },
    tab: {
        marginLeft :10,
      overflowY: 'scroll',
      maxHeight: 'calc(100% - 50px)'
    },
    inputSearch: {
      width: '80%'
    },
    detailContainer: {
      height: 'calc(100vh - 58px - 2*20px)',
      marginTop: 20,
      marginBottom: 20,
      boxShadow: "-0.35rem 0 0.25rem -0.25rem rgba(67, 67, 67, 0.15)" 
    }
}


export default AllChantiers;
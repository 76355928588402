import React, {useState} from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import logo from '@assets/logo.png';
import axios from 'axios';
import baseUrl from '@root/config'
import { fetchMe } from "@queries/Users";

const Auth = ({
    children = null
}) => {
    const queryClient = useQueryClient();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Fetching data
    const me = useQuery('me', fetchMe, { retry: false });    
    const authenticate = useMutation(data => axios.post(baseUrl + '/api/auth/local', data), {
        onSuccess: (res) => {
            localStorage.setItem('token', res.data.jwt);
            window.location.replace('/');
        }
    })

    return (me.isLoading || authenticate.isLoading) ? (
        <div className="container">
        <div className="d-flex justify-content-center align-items-center"  style ={{ height:'100vh' }}  >
            <div className="spinner-border " role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="mt-3 ml-2">
                <p> Chargement en cours ...</p>
            </div>
        </div>
        </div>
    ) : (me.isSuccess) ?  (
        children
    ) : (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                        <div className="card my-5">
                            <div className="card-body p-5 text-center">
                                <img className="mb-3" src={logo} />
                                <div className="h3 font-weight-light mb-0 mt-2">Login</div>
                            </div>
                            <hr className="my-0" />
                            <div className="card-body p-5">
                                <form>
                                    { (authenticate.isError ) &&

                                        <div className="alert alert-danger" role="alert">
                                            <strong>Erreur !</strong> login et mot de passe ne semblent pas correspondrent
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label className="text-gray-600 small" htmlFor="email">Email</label>
                                        <input className="form-control form-control-solid py-4" type="text" placeholder="" aria-label="Email" aria-describedby="email" name="email" required="required" onChange={e => setEmail(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label className="text-gray-600 small" htmlFor="password">Mot de Passe</label>
                                        <input className="form-control form-control-solid py-4" type="password" placeholder="" aria-label="Password" aria-describedby="password" name="password" required="required" onChange={e => setPassword(e.target.value)}/>
                                    </div>
                                    <div className="form-group d-flex align-items-center justify-content-between mb-0">
                                        <div className="custom-control custom-control-solid custom-checkbox"></div>
                                        <button className="btn btn-outline-primary mt-4" onClick={e => { e.preventDefault(); authenticate.mutate({ identifier: email, password})}}>Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

const styles = {
    by: {
      color: 'rgba(0,0,0,0.5)',
      fontWeight: 500
    },
    brand: {
      fontSize: 15,
      fontWeight: 400
    }
  }

export default Auth;
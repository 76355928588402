export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const SET_CURRENT_SUPPLIER = "SET_CURRENT_SUPPLIER";

export const setSuppliers = suppliers => ({
  type: SET_SUPPLIERS,
  payload: {
    suppliers
  }
});


export const setCurrentSupplier = supplierId => ({
  type: SET_CURRENT_SUPPLIER,
  payload: {
      supplierId
  }
})
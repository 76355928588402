import moment from 'moment';

moment.updateLocale('en', {
  months : [
      "Janvier", "Févier", "Mars", "Avril", "Mai", "Juin", "Juillet",
      "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ]
});

function init_obj(e, nb, i) {
  if (nb === 0) {
    return {
      nb: e.sales_details.data?.at(i)?.attributes.index,
      recap: e.sales_details.data?.at(i)?.attributes?.title,
      price: "",
    };
  } else {
    return {
      nb: e.sales_details.data?.at(i)?.attributes.index,
      inter_date: e.sales_details.data?.at(i)?.attributes?.date,
      title: e.sales_details.data?.at(i)?.attributes?.title || '',
      description: e.sales_details.data?.at(i)?.attributes?.description || '',
      inter_nb: e.sales_details.data?.at(i)?.attributes?.id_devis || '',
      taskList: [],
      u: [],
      q: [],
      pt: [],
      pu: [],
      total: "",
    };
  }
}

function fill_data(doc, e, table, ensemble) {
  doc.setData({
    social_reason: e?.idClient?.data?.attributes?.companyName || '',
    name: e?.idChantier?.data?.attributes?.idContact?.data?.attributes?.firstName || '',
    last_name: e?.idChantier?.data?.attributes?.idContact?.data?.attributes?.lastName || '',
    adress: e?.idClient?.data?.attributes?.address || '',
    city_code: e?.idClient?.data?.attributes?.postCode.toString() || '',
    date: moment(e?.dateFacturation, 'YYYY-MM-DD').format("DD MMMM YYYY") || '',
    devis_nb: e.documentNumber || '',
    relative_nb : e?.sales_item?.data?.attributes?.documentNumber || '',
    chantier_name: e?.idChantier?.data?.attributes?.site || '',
    inter_date: e?.idChantier?.data?.attributes?.dateDebut || '',
    client: e?.idClient?.data?.attributes?.companyName || '',
    test: table,
    total: e?.montantHt?.toFixed(2),
    ht: e?.montantHt?.toFixed(2),
    tva: ((e?.montantHt * e?.vat) / 100)?.toFixed(2),
    ttc: e?.montantTtc?.toFixed(2),
    recap: ensemble,
    TVA: e?.vat,
  });
}

export default function Devis(doc, e) {
  let ensemble = [];
  let table = [];
  let i = 0;
  while (i < e?.sales_details?.data?.length) {
    let res = 0;
    for (
      ;
      e.sales_details?.data?.at(i)?.attributes?.index === null &&
      i < e?.sales_details?.data?.length;
      i++
    );
    let ensembleObj = init_obj(e, 0, i);
    let obj = init_obj(e, 1, i);
    i += 1;
    for (
      ;
      e.sales_details?.data?.at(i)?.attributes?.index === null &&
      e?.sales_details.data?.at(i)?.attributes.isSubTotal !== true &&
      i < e?.sales_details?.data?.length;
      i++
    ) {
      obj?.taskList?.push({
        title_task: e?.sales_details.data?.at(i)?.attributes.title || '',
        description_task: e?.sales_details.data?.at(i)?.attributes.description || '',
        v: e?.sales_details.data?.at(i)?.attributes?.unite || '',
        z: e.sales_details.data?.at(i)?.attributes?.quantity || '',
        p: e.sales_details.data?.at(i)?.attributes?.amountHt?.toFixed(2),
        price: (
          e.sales_details.data?.at(i)?.attributes?.quantity *
          (e.sales_details.data?.at(i)?.attributes?.completion / 100) *
          e.sales_details.data?.at(i)?.attributes?.amountHt
        )?.toFixed(2),
      });
      
      res +=
        e.sales_details.data?.at(i)?.attributes?.quantity *
        (e.sales_details.data?.at(i)?.attributes?.completion / 100) *
        e.sales_details.data?.at(i)?.attributes?.amountHt;
    }
    ensembleObj.price = res?.toFixed(2);
    ensemble.push(ensembleObj);
    obj.total = res?.toFixed(2);
    table.push(obj);
    i++;
  }
  fill_data(doc, e, table, ensemble);
}

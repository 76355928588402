/* src/App.js */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment/min/moment-with-locales";
import { filter, sortBy, findIndex } from "lodash";

import { useQueryClient, useQuery, useMutation } from "react-query";
import { useMax, useForm } from "@hooks";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import {
  fetchSchedules,
  createSchedules,
  updateSchedules,
  deleteSchedules,
} from "@queries/Schedules";
import { fetchClients } from "@queries/Clients";
import { fetchChantiers } from "@queries/Chantiers";
import { fetchUsers, fetchMe } from "@queries/Users";

import Header from "@components/Header";
import Router from "@components/Router";
import { downloadInter } from "@queries/Inter";

const formatSchedules = (data, me) =>  data
  ?.filter((o) => me?.data?.type === "admin" || o?.attributes?.idUser?.data?.id === me?.data?.id)
  ?.map((o) => ({
    id: o?.id,
    ...o?.attributes,
    idClient: o?.attributes?.idClient?.data?.id,
    idChantier: o?.attributes?.idChantier?.data?.id,
    idUser: o?.attributes?.idUser?.data?.id,
    client: o?.attributes?.idClient?.data?.attributes,
  }));

const TimeTracker = () => {
  moment.locale("fr");
  const queryClient = useQueryClient();
  const [bonIntervention, setBonIntervention] = useState([]);

  // Fetch Data
  useQuery("inter", downloadInter, {
    onSuccess: (data) => {
      console.log(data.intervention.data);
      setBonIntervention(data.intervention.data);
    },
  });

  const generateDocument = (index, e) => {
    var zip = new PizZip(bonIntervention);
    var doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });
    const current_chantier = chantiers.find(
      (element) => e.idChantier === element.id
    );
    const current_user = users.find((element) => e.idUser === element.id);

    doc.setData({
      ref: current_chantier.id + " - " + (current_chantier?.designationTravaux || ''),
      technicien: (current_user?.firstName || '') + " " + (current_user?.lastName || ''),
      date: moment(e?.date)?.format("DD/MM/YYYY") || '',
      nb: e.id,
      inter: e?.comment || '',
      hour_start: e?.startTime.slice(0, 5) || '',
      hour_end: e?.endTime.slice(0, 5) || '',
      client: current_chantier?.idClient?.data?.attributes?.companyName || '',
    });

    try {
      doc.render();
    } catch (error) {
      function replaceErrors(key, value) {
        if (value instanceof Error) {
          return Object.getOwnPropertyNames(value).reduce(function (
            error,
            key
          ) {
            error[key] = value[key];
            return error;
          },
          {});
        }
        return value;
      }
      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors

          .map(function (error) {
            return error.properties.explanation;
          })

          .join("\n");

        console.log("errorMessages", errorMessages);
      }

      throw error;
    }

    var out = doc.getZip().generate({
      type: "blob",

      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    }); //Output the document using Data-URI

    saveAs(out, "bon_intervention" + ".docx");
  };

  const usersAll = useQuery("users", fetchUsers);
  const chantiersAll = useQuery("chantiers", fetchChantiers);
  const me = useQuery("me", fetchMe);
  const schedulesAll = useQuery("schedules", fetchSchedules, {
    onSuccess: (data) => filterSchedules(week, formatSchedules(data?.data, me)),
  });

  const users = usersAll.isSuccess ? usersAll?.data : [];
  const chantiers = chantiersAll.isSuccess
    ? chantiersAll.data?.data?.map((o) => ({ id: o?.id, ...o?.attributes }))
    : [];
  const schedules = [schedulesAll, me].every((o) => o.isSuccess)
    ? formatSchedules(schedulesAll.data?.data, me)
    : [];

  // Modify data

  const createSchedulesMutation = useMutation((schedules) =>
    createSchedules(schedules)
  );
  const upsertSchedulesMutation = useMutation((schedules) =>
    updateSchedules(schedules)
  );
  const deleteSchedulesMutation = useMutation((ids) => deleteSchedules(ids));

  const [form, setForm] = useForm();
  const [formModal, setFormModal] = useForm();

  // const [schedules, setForm] = useState([]);
  const [schedulesToDelete, setFormToDelete] = useState([]);
  const [isUpdatingSchedules, setIsUpdatingSchedules] = useState(false);

  const [week, setWeek] = useState(moment().week());
  const [search, setSearch] = useState("");
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    filterSchedules(week, sortBy(schedules, ["date"]));
  }, [week]);

  const upsertSchedules = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await deleteSchedulesMutation.mutateAsync(schedulesToDelete);
    await upsertSchedulesMutation.mutateAsync(
      form?.schedules.map((o) => ({
        ...o,
        ...(o?.startTime && {
          startTime: moment(o?.startTime, "hh:mm").format("HH:mm:ss.SSS"),
        }),
        ...(o?.endTime && {
          endTime: moment(o?.endTime, "hh:mm").format("HH:mm:ss.SSS"),
        }),
      }))
    );
    queryClient.invalidateQueries("schedules");
    queryClient.refetchQueries("schedules");

    setIsUpdatingSchedules(false);
  };

  const insertSchedules = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await createSchedulesMutation.mutateAsync([
      {
        idUser: me?.data?.id,
        ...formModal,
        ...(formModal?.startTime && {
          startTime: moment(formModal?.startTime, "hh:mm").format(
            "HH:mm:ss.SSS"
          ),
        }),
        ...(formModal?.endTime && {
          endTime: moment(formModal?.endTime, "hh:mm").format("HH:mm:ss.SSS"),
        }),
      },
    ]);
    queryClient.invalidateQueries("schedules");
    queryClient.refetchQueries("schedules");

    setIsUpdatingSchedules(false);
    setDisplayModal(false);
  };

  const newNewSchedule = () => {
    setFormModal("all", {});
    setDisplayModal(true);
  };

  const editSchedule = (id, key, value) => {
    const tempSchedules = JSON.parse(JSON.stringify(form.schedules));
    const index = findIndex(tempSchedules, (o) => o.id == id);
    tempSchedules[index][key] = value;
    setForm("schedules", tempSchedules);
  };

  const deleteSchedule = (index, id) => {
    setFormToDelete((prev) => [...prev, id]);

    setForm("schedules", [
      ...form.schedules.slice(0, index),
      ...form.schedules.slice(index + 1),
    ]);
  };

  const filterSchedules = (weekNumber, updatedSchedules) => {
    let filteredArray = [];
    let begin = moment().week(weekNumber).startOf("week").unix();
    let end = moment().week(weekNumber).endOf("week").unix();

    filteredArray = updatedSchedules?.filter(
      (o) =>
        begin <= moment(o?.date, "YYYY-MM-DD").unix() &&
        moment(o?.date, "YYYY-MM-DD").unix() <= end
    );
    setForm("schedules", filteredArray);
  };

  const _generateWeekNumbers = () => {
    const weekLabel = [];
    for (let i = 0; i < 52; i++)
      weekLabel.push(
        `Semaine ${i + 1} - ${moment()
          .week(i + 1)
          .startOf("week")
          .format("DD/MM/yyyy")} - ${moment()
          .week(i + 1)
          .endOf("week")
          .format("DD/MM/yyyy")}`
      );
    return weekLabel;
  };

  return (
    <Router active="Présence">
      <Header />

      <div>
        <div className="container-fluid">
          {/* 
          <div className="input-group mb-3 mt-3" style={styles.inputSearch}>
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-search"></i></span>
            </div>
              <input id='searchbar' type="text" className="form-control" placeholder="Recherchez ..." aria-label="TimeTracker_Search" aria-describedby="basic-addon1" onChange={(e) => setSearch(e.target.value)} />
          </div> */}
          <div className="datatable table-responsive ">
            <table
              className="table table-md-hover mb-0"
              id="dataTable"
              width="100%"
            >
              <thead>
                <tr>
                  <th colSpan="7" className="form-group">
                    <label
                      htmlFor="weekSelect"
                      className="col-md-2 col-form-label"
                    >
                      Semaine :{" "}
                    </label>
                    <select
                      id="weekSelect"
                      className="custom-select col-md-10"
                      defaultValue={moment().week()}
                      onChange={(e) => setWeek(e.target.value)}
                    >
                      {_generateWeekNumbers().map((val, index) => (
                        <option key={index + 1} value={index + 1}>
                          {val}
                        </option>
                      ))}
                    </select>
                    </th>
                  <th className="text-end">
                    <button
                      className="btn btn-outline-dark"
                      onClick={() => newNewSchedule()}
                    >
                      Ajouter
                    </button>

                  </th>
                </tr>
              </thead>

              <thead>
                <tr className="w-100 d-none d-md-table-row">
                  {me?.data?.type === "admin" ? <th>Nom</th> : null}
                  <th>Date</th>
                  <th>Temps de travail</th>
                  <th>Chantier</th>
                  <th>Tâches</th>
                  <th>Déplacement</th>
                  <th>Commentaire</th>
                  <th>Action</th>
                </tr>
              </thead>
              {form?.schedules?.map((element, index) => {
                return (
                  <tbody key={index}>
                    <tr className="d-none d-md-table-row">
                      {me?.data?.type == "admin" ? (
                        <td className="col-2" style={styles.td}>
                          <select
                            className="form-control form-control-solid"
                            id="name"
                            onChange={(e) =>
                              editSchedule(
                                element.id,
                                "idUser",
                                parseInt(e.target.value)
                              )
                            }
                            value={element.idUser}
                          >
                            <option value={""}></option>
                            {users
                              ?.filter((o) => !o.deletedAt)
                              ?.map((val, indexOpt) => (
                                <option key={indexOpt} value={val.id}>
                                  {" "}
                                  {val.firstName} {val.lastName}{" "}
                                </option>
                              ))}
                          </select>
                        </td>
                      ) : null}
                      <td className="col-2" style={styles.td}>
                        <div className="  input-group">
                          {/* { (moment(element.date).format('dddd')=='Invalid date') ? null : <label className="m-2"> {moment(element.date).format('dddd')} </label> } */}
                          <input
                            className="form-control form-control-solid"
                            type="date"
                            id="date"
                            placeholder=""
                            onChange={(e) =>
                              editSchedule(element.id, "date", e.target.value)
                            }
                            value={element.date}
                          />
                        </div>
                      </td>
                      <td className="col-2 text-start" style={styles.td}>
                        <div className="input-group">
                          <input
                            id="starttime"
                            type="time"
                            className="form-control"
                            style={{ ...styles.maxWidth, ...styles.td }}
                            onChange={(e) =>
                              editSchedule(
                                element.id,
                                "startTime",
                                e.target.value
                              )
                            }
                            value={element.startTime}
                          />
                          <input
                            id="endtime"
                            type="time"
                            className="form-control"
                            style={{ ...styles.maxWidth, ...styles.td }}
                            onChange={(e) =>
                              editSchedule(
                                element.id,
                                "endTime",
                                e.target.value
                              )
                            }
                            value={element.endTime}
                          />
                        </div>
                      </td>
                      <td className="col-2" style={styles.td}>
                        <select
                          className="form-control form-control-solid"
                          id="chantier"
                          onChange={(e) =>
                            editSchedule(
                              element.id,
                              "idChantier",
                              parseInt(e.target.value)
                            )
                          }
                          value={element.idChantier}
                        >
                          <option value={""}></option>
                          {chantiers &&
                            chantiers.map((val, index) => (
                              <option key={index} value={val.id}>
                                {" "}
                                {
                                  val?.idClient?.data?.attributes?.companyName
                                } - {val?.site}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td className="col-2" style={styles.td}>
                        <select
                          className="form-control form-control-solid"
                          id="task"
                          onChange={(e) =>
                            editSchedule(element.id, "task", e.target.value)
                          }
                          value={element.task}
                        >
                          <optgroup label="Tâches">
                            <option value={""}></option>
                            <option value={"devis"}>
                              Devis/Etude/Recherche
                            </option>
                            <option value={"etude"}>
                              Etude plan synoptique
                            </option>
                            <option value={"integration"}>
                              Intégration/Paramétrage
                            </option>
                            <option value={"programmation"}>
                              Programmation
                            </option>
                            <option value={"depannage"}>Dépannage SAV</option>
                            <option value={"divers"}>
                              Divers (manutention/déplacement)
                            </option>
                            <option value={"maintenance"}>Maintenance</option>
                          </optgroup>
                          <optgroup label="Autres">
                            <option value={"recuperation"}>Récupération</option>
                            <option value={"conges"}>Congés CCPB</option>
                            <option value={"maladie"}>Maladie</option>
                            <option value={"ferie"}>Férié</option>
                          </optgroup>
                        </select>
                      </td>
                      <td className="col-1 text-center" style={styles.td}>
                        <input
                          type="checkbox"
                          id="deplacementCheck"
                          onChange={(e) =>
                            editSchedule(
                              element.id,
                              "deplacementCheck",
                              e.target.checked
                            )
                          }
                          checked={element.deplacementCheck}
                        />
                      </td>
                      <td>
                        <textarea
                          className="form-control form-control-solid"
                          type="text"
                          id="comment"
                          placeholder=""
                          value={element.comment}
                          onChange={(e) =>
                            editSchedule(element.id, "comment", e.target.value)
                          }
                        />
                      </td>
                      <td className="col-1" style={styles.td}>
                        <button
                          className="btn btn-datatable btn-icon btn-transparent-dark"
                          onClick={(e) => deleteSchedule(index, element.id)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                        <button
                          className="btn btn-datatable btn-icon btn-transparent-dark"
                          onClick={(e) => generateDocument(index, element)}
                        >
                          <i className="fas fa-download"></i>
                        </button>
                      </td>
                    </tr>

                    <tr className="d-md-none">
                      {me?.data?.type == "admin" ? (
                        <tr>
                          <td className="col-2" style={styles.td}>
                            <label> Nom :</label>
                            <select
                              className="custom-select"
                              id="name"
                              onChange={(e) =>
                                editSchedule(
                                  element.id,
                                  "idUser",
                                  parseInt(e.target.value)
                                )
                              }
                              value={element.idUser}
                            >
                              <option value={""}></option>
                              {users
                                .filter((o) => !o.deletedAt)
                                .map((val, index) => (
                                  <option key={index} value={val.id}>
                                    {" "}
                                    {
                                      users.filter((o) => o.id == val.id)[0]
                                        .firstName
                                    }{" "}
                                    {
                                      users.filter((o) => o.id == val.id)[0]
                                        .lastName
                                    }{" "}
                                  </option>
                                ))}
                            </select>
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td style={{ width: 700 }}>
                          <div className="input-group flex-column">
                            <label> Date :</label>
                            <div className="align-items-center input-group ">
                              <input
                                className="custom-select"
                                type="date"
                                id="date"
                                placeholder=""
                                onChange={(e) =>
                                  editSchedule(
                                    element.id,
                                    "date",
                                    e.target.value
                                  )
                                }
                                value={element.date}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={styles.td}>
                          <div className="input-group flex-column">
                            <label> Temps de travail :</label>
                            <input
                              type="time"
                              className="form-control mb-2"
                              style={{ ...styles.maxWidth, ...styles.td }}
                              onChange={(e) =>
                                editSchedule(
                                  element.id,
                                  "startTime",
                                  e.target.value
                                )
                              }
                              value={element.startTime}
                            />
                            <input
                              type="time"
                              className="form-control"
                              style={{ ...styles.maxWidth, ...styles.td }}
                              onChange={(e) =>
                                editSchedule(
                                  element.id,
                                  "endTime",
                                  e.target.value
                                )
                              }
                              value={element.endTime}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-12 flex-column" style={styles.td}>
                          <label> Chantier :</label>
                          <select
                            className="custom-select"
                            id="chantier"
                            onChange={(e) =>
                              editSchedule(
                                element.id,
                                "idChantier",
                                parseInt(e.target.value)
                              )
                            }
                            value={element.idChantier}
                          >
                            <option value={""}></option>
                            {chantiers &&
                              chantiers.map((val, index) => (
                                <option key={index} value={val.id}>
                                  {" "}
                                  {
                                    val?.idClient?.data?.attributes?.companyName
                                  }{" "}
                                  - {val.site}
                                </option>
                              ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-2" style={styles.td}>
                          <label> Tâches :</label>
                          <select
                            className="custom-select"
                            id="task"
                            onChange={(e) =>
                              editSchedule(element.id, "task", e.target.value)
                            }
                            value={element.task}
                          >
                            <optgroup label="Tâches">
                              <option value={""}></option>
                              <option value={"devis"}>
                                Devis/Etude/Recherche
                              </option>
                              <option value={"etude"}>
                                Etude plan synoptique
                              </option>
                              <option value={"integration"}>
                                Intégration/Paramétrage
                              </option>
                              <option value={"programmation"}>
                                Programmation
                              </option>
                              <option value={"depannage"}>Dépannage SAV</option>
                              <option value={"divers"}>
                                Divers (manutention/déplacement)
                              </option>
                              <option value={"maintenance"}>Maintenance</option>
                            </optgroup>
                            <optgroup label="Autres">
                              <option value={"recuperation"}>
                                Récupération
                              </option>
                              <option value={"conges"}>Congés CCPB</option>
                              <option value={"maladie"}>Maladie</option>
                              <option value={"ferie"}>Férié</option>
                            </optgroup>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="col-12 d-flex flex-column align-items-center"
                          style={styles.td}
                        >
                          <label className="align-self-start">
                            {" "}
                            Déplacement :
                          </label>
                          <input
                            type="checkbox"
                            id="deplacementCheck"
                            onChange={(e) =>
                              editSchedule(
                                element.id,
                                "deplacementCheck",
                                e.target.checked
                              )
                            }
                            checked={element.deplacementCheck}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-2" style={styles.td}>
                          <label> Commentaire :</label>
                          <textarea
                            className="form-control"
                            type="text"
                            id="comment"
                            placeholder=""
                            value={element.comment}
                            onChange={(e) =>
                              editSchedule(
                                element.id,
                                "comment",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
          <div className="justify-content-end d-flex flex-row ">
            <button
              className="btn btn-outline-success mb-2"
              onClick={(e) => {
                setIsUpdatingSchedules(true);
                upsertSchedules(e);
              }}
            >
              {isUpdatingSchedules && (
                <div
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></div>
              )}
              {isUpdatingSchedules ? "Enregistrement..." : "Enregistrer"}
            </button>
          </div>
        </div>
        <Modal show={displayModal} onHide={() => setDisplayModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un créneau</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <tr className="row">
                {me?.data?.type == "admin" ? (
                  <td className="col-12" style={styles.td}>
                    <label> Nom :</label>
                    <select
                      className="custom-select"
                      id="name"
                      onChange={(e) =>
                        setFormModal("idUser", parseInt(e.target.value))
                      }
                      value={formModal?.idUser}
                    >
                      <option value={""}></option>
                      {users
                        .filter((o) => !o.deletedAt)
                        .map((val, index) => (
                          <option key={index} value={val.id}>
                            {" "}
                            {
                              users.filter((o) => o.id == val.id)[0].firstName
                            }{" "}
                            {users.filter((o) => o.id == val.id)[0].lastName}{" "}
                          </option>
                        ))}
                    </select>
                  </td>
                ) : null}
                <td className="col-12 mt-1" style={styles.td}>
                  <div className="input-group flex-column">
                    <label> Date * :</label>
                    <div className="align-items-center input-group ">
                      <input
                        className="custom-select"
                        type="date"
                        id="date"
                        placeholder=""
                        onChange={(e) => setFormModal("date", e.target.value)}
                        value={formModal?.date}
                      />
                    </div>
                  </div>
                </td>
                <td className="col-12 mt-1" style={styles.td}>
                  <div className="form-group flex-column">
                    <label> Temps de travail * :</label>
                    <input
                      type="time"
                      className="form-control mb-2"
                      style={{ ...styles.maxWidth }}
                      onChange={(e) =>
                        setFormModal("startTime", e.target.value)
                      }
                      value={formModal?.startTime}
                    />
                    <input
                      type="time"
                      className="form-control"
                      style={{ ...styles.maxWidth }}
                      onChange={(e) => setFormModal("endTime", e.target.value)}
                      value={formModal?.endTime}
                    />
                  </div>
                </td>
                <td className="col-12 flex-column" style={styles.td}>
                  <label> Chantier * :</label>
                  <select
                    className="custom-select"
                    id="chantier"
                    onChange={(e) =>
                      setFormModal("idChantier", parseInt(e.target.value))
                    }
                    value={formModal?.idChantier}
                  >
                    <option value={""}></option>
                    {chantiers &&
                      chantiers.map((val, index) => (
                        <option key={index} value={val.id}>
                          {" "}
                          {val?.idClient?.data?.attributes?.companyName} -{" "}
                          {val.site}
                        </option>
                      ))}
                  </select>
                </td>
                <td className="col-12 mt-1" style={styles.td}>
                  <label> Tâches :</label>
                  <select
                    className="custom-select"
                    id="task"
                    onChange={(e) => setFormModal("task", e.target.value)}
                    value={formModal?.task}
                  >
                    <optgroup label="Tâches">
                      <option value={""}></option>
                      <option value={"devis"}>Devis/Etude/Recherche</option>
                      <option value={"etude"}>Etude plan synoptique</option>
                      <option value={"integration"}>
                        Intégration/Paramétrage
                      </option>
                      <option value={"programmation"}>Programmation</option>
                      <option value={"depannage"}>Dépannage SAV</option>
                      <option value={"divers"}>
                        Divers (manutention/déplacement)
                      </option>
                      <option value={"maintenance"}>Maintenance</option>
                    </optgroup>
                    <optgroup label="Autres">
                      <option value={"recuperation"}>Récupération</option>
                      <option value={"conges"}>Congés CCPB</option>
                      <option value={"maladie"}>Maladie</option>
                      <option value={"ferie"}>Férié</option>
                    </optgroup>
                  </select>
                </td>
                <td
                  className="col-12 d-flex flex-column align-items-center"
                  style={styles.td}
                >
                  <label className="align-self-start"> Déplacement :</label>
                  <input
                    type="checkbox"
                    id="deplacementCheck"
                    onChange={(e) =>
                      setFormModal("deplacementCheck", e.target.checked)
                    }
                    checked={formModal?.deplacementCheck}
                  />
                </td>
                <td className="col-12" style={styles.td}>
                  <label> Commentaire :</label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="comment"
                    placeholder=""
                    value={formModal?.comment}
                    onChange={(e) => setFormModal("comment", e.target.value)}
                  />
                </td>
              </tr>
            </table>
            <div className="justify-content-end d-flex flex-row mt-3">
              <button
                className="btn btn-outline-success mb-2"
                onClick={(e) => {
                  setIsUpdatingSchedules(true);
                  insertSchedules(e);
                }}
              >
                {isUpdatingSchedules && (
                  <div
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></div>
                )}
                {isUpdatingSchedules ? "Enregistrement..." : "Enregistrer"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Router>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  td: {
    verticalAlign: "middle",
  },
  maxWidth: {
    minHeight: 45,
  },
};

export default TimeTracker;

import { React } from "react";
import Field from "@components/Field";

const getFeildsInfo = (
  i,
  currentChantierId,
  form,
  clients,
  contacts,
  setForm,
  moment
) =>
  i === 0
    ? [
        {
          label: "ID Chantier : ",
          value: currentChantierId,
          disabled: true,
        },
        {
          label: "Dénomination client * : ",
          element: "select",
          value: form?.idClient || "",
          setValue: (e) => setForm("idClient", e.target.value),
          options: clients?.map((val) => ({
            value: val.id,
            label: val.companyName,
          })),
        },
        {
          label: "Libellé * : ",
          value: form?.site || "",
          setValue: (e) => setForm("site", e.target.value),
        },
        {
          label: "Désignation de travaux * : ",
          value: form?.designationTravaux || "",
          setValue: (e) => setForm("designationTravaux", e.target.value),
        },
        {
          label: "Ref client : ",
          value: form?.refClient || "",
          setValue: (e) => setForm("refClient", e.target.value),
        },
      ]
    : [
        {
          label: "Date de début * : ",
          element: "input",
          type: "date",
          value: moment(form?.dateDebut).format("YYYY-MM-DD") || "",
          setValue: (e) => setForm("dateDebut", e.target.value),
        },
        {
          label: "Date de fin * : ",
          element: "input",
          type: "date",
          value: moment(form?.dateFin).format("YYYY-MM-DD") || "",
          setValue: (e) => setForm("dateFin", e.target.value),
        },
        {
          label: "Etat * : ",
          element: "select",
          value: form?.status || "",
          setValue: (e) => setForm("status", e.target.value),
          options: [
            { value: "open", label: "En cours" },
            { value: "close", label: "Terminé" },
          ],
        },
        {
          label: "Adresse du chantier",
          value: form?.addressChantier || "",
          setValue: (e) => setForm("addressChantier", e.target.value),
        },
        {
          label: "Contact * : ",
          element: "select",
          value: form?.idContact || "",
          setValue: (e) => setForm("idContact", e.target.value),
          options: contacts
            ?.filter((o) => o.idClient == form?.idClient)
            .map((val) => ({
              value: val.id,
              label: `${val.firstName} ${val.lastName}`,
            })),
        },
        {
          label: "Code : ",
          value: form?.code || "",
          setValue: (e) => setForm("code", e.target.value),
        },
      ];

const RenderFeild = (props) => {
  const { allFeild = [] } = props;
  return (
    <>
      {allFeild.map((item) => (
        <Field
          label={item.label}
          element={item.element ? item.element : "input"}
          value={item.value}
          setValue={item.setValue ? (e) => item.setValue(e) : null}
          options={item.options ? item.options : null}
          disabled={item.disabled ? true : false}
          type={item?.type}
          inline={12}
        />
      ))}
    </>
  );
};
export { RenderFeild, getFeildsInfo };

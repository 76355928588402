import React from 'react';

const DataBox = (props) => {
  return (
    <a style={styles.cursor} onClick={()=> props.navigateTo() }>
         <div style={{...styles.top , borderColor: props.color }} >
         <i style={{...styles.elementStyle, color: props.color}} className={props.image} ></i>
        </div>
        <div style={{...styles.bottom , backgroundColor: props.bottomColor }}>
            {props.text}
        </div>
    </a>
  );
}

const styles = {
    top: {
        width: '140px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: 'solid'
    },
    bottom: {
        width: '140px',
        height: '55px',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
        
    },
    elementStyle: {
        // color: '#000'
    },
    cursor: {
        cursor: 'pointer',
        margin: 10
    },
}

export default DataBox;
import { useState } from 'react';
import Header from '@components/Header';
import Router from '@components/Router';
import { useHistory } from 'react-router-dom';
import Table from '@components/Table'
import { fetchSuppliers } from '@queries/Suppliers'
import { useQuery } from 'react-query';
import { useMax } from '@hooks';

function Suppliers() {
  const history = useHistory();

  // Fetch Data
  const suppliersAll = useQuery('suppliers', fetchSuppliers);
  const maxClientId = useMax((suppliersAll.isSuccess) ? suppliersAll.data?.data : []);
  
  const suppliers = (suppliersAll.isSuccess) ? suppliersAll.data?.data?.filter((o) => !o.deletedAt) : [];

  const [search, setSearch] = useState(''); 

  const openCompany = (id) => {
    history.push({
      pathname: '/suppliers-details',
      state: id
    });
  }

  const newCompany = () => {
    history.push({
      pathname: '/suppliers-details',
      state: maxClientId + 1
    });
  } 

  return (
    <Router
      active='Fournisseurs'
    >
      <Header />
        <div className="mr-3 ml-3 ">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <button className="btn btn-dark align-self-start" onClick={() => newCompany()} >Nouveau fournisseur</button>
              
              <div className="input-group mb-3" style={styles.inputSearch}>
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-search"></i></span>
                </div>
                <input type="text" className="form-control" placeholder="Rechercher un fournisseur ..." aria-label="Client_Search" aria-describedby="basic-addon1" onChange={(e) => setSearch(e.target.value)} />
              </div>

            </div>
          
            <div>
                <Table
                  headers = {['Code tiers','Dénomination','Téléphone','Fax', 'Email']}
                  data = {suppliers}
                  filterBoolean = {(o) => o.id == search || o.companyName.toLowerCase().includes(search.toLowerCase()) || o.faxNumber.toLowerCase().includes(search.toLowerCase()) || o.phone.toLowerCase().includes(search.toLowerCase()) || o.email.toLowerCase().includes(search.toLowerCase())}
                  sortFunction = { () => 'id' }
                  actions= {[
                    { name: 'navigate', action: (element) => openCompany(element.id) },
                  ]}
                  tds = {[
                    {highlight: search , action: (element) => (search) ? element.id.toString().replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element.id},
                    {highlight: search , action: (element) => (search) ? element.companyName.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element.companyName},
                    {highlight: search , action: (element) => (search) ? element.phone.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element.phone},
                    {highlight: search , action: (element) => (search) ? element.faxNumber.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element.faxNumber},
                    {highlight: search , action: (element) => (search) ? element.email.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element.email},
                  ]}
              />
            </div>
        </div>
    </Router>
  )
}

const styles = {
  clientList: {
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 150px)',
    padding: 0 
  },
  inputSearch: {
    width: '80%'
  }
}

export default Suppliers;

import Header from '@components/Header';
import { useSelector } from 'react-redux';
import { useState} from 'react';
import moment from 'moment/min/moment-with-locales';
import exportFromJSON from 'export-from-json'
import { sumBy, sortBy , groupBy} from 'lodash';
import Router from '@components/Router';

function ExportSchedules() {
  
  const users = useSelector((state) => state.users.users.filter((o) => !o.deletedAt));
  const schedules = useSelector((state) => state.schedules.filter((o) => !o.deletedAt));
  
  const [chosenSchedules, setChosenSchedules] = useState([]);
  const [normalSchedules, setNormalSchedules] = useState([]);
 
  const selectedUsers = () => {
    let selectBox = document.getElementById('select-users')

    let startDate =  moment(document.getElementById('start-date').value + 'T00:00:00.000Z').unix() || ''
    let endDate =  moment(document.getElementById('end-date').value + 'T00:00:00.000Z').unix() || ''
    
    let selectedIds = [...selectBox.selectedOptions].map((e) => parseInt(e.value))
    let filteredSchedules = (sortBy(schedules , ['date','startTime']).filter((o) => startDate <= moment(o.date).unix() && moment(o.date).unix() <= endDate && selectedIds.includes(o.idUser) ).map((e) => ({...e , date : (e.date.split('T')[0]) , timeWorked : getTime(e.startTime , e.endTime) }) ))
   
    let x = groupBy( filteredSchedules , (e) => e.idUser+e.date )

    let newTimeArr = []
    for ( let key in x ){
      newTimeArr.push({timeWorked : sumBy( x[key] , (e) => e.timeWorked ) , date : x[key][0].date , idUser : x[key][0].idUser , idChantier : x[key][0].idChantier })
    }
   
    setNormalSchedules (  filteredSchedules.map((e) => ({ Nom : users.filter((o) => o.id== e.idUser).map((ele) => ele.firstName+' '+ele.lastName )[0]  , Date : e.date , 'Durée (en heures)' : (e.timeWorked/3600)?.toFixed(2)  }) ) ) 
    setChosenSchedules(  newTimeArr.map((e) => ({ Nom : users.filter((o) => o.id== e.idUser).map((ele) => ele.firstName+' '+ele.lastName )[0]  , Date : e.date , 'Durée (en heures)' : (e.timeWorked/3600)?.toFixed(2)  }) ) ) 
  }

  const exportToExcel = (key) => {
    const data = (key=='normal') ? normalSchedules  : chosenSchedules
    const fileName = 'download'
    const exportType = 'xls'
    
    if(data.length >0) {  
       exportFromJSON({ data , fileName, exportType })
    }
  }

  const getTime = (startTime , endTime) => {
      let startArr = startTime.split(':');
      let endArr = endTime.split(':');

      let startTimeInSeconds = (startArr[0] * 60 *60) + (startArr[1] * 60) ;
      let endTimeInSeconds = (endArr[0] * 60 *60) + (endArr[1] * 60) ;
 
      let result = ((endTimeInSeconds - startTimeInSeconds) < 0 ) ? ((endTimeInSeconds + ( (24*3600) - startTimeInSeconds) )) : (endTimeInSeconds - startTimeInSeconds);
  
    return (result)
  }

  return (
    <Router
      active='Exportation'
    >

        <Header />
        <div className="form-group mx-5 mt-5">
            <label className="text-gray-600 small" htmlFor="select-users">Choisir des contacts</label>
            <select id="select-users" className="custom-select form-control-solid mb-5"  onChange = {(e)=> selectedUsers() } multiple>
                <option value={0}></option>
                {users.map((val, index) => <option key={index} value={val.id}> {val.firstName} {val.lastName} </option>)}
            </select>

            <div className="row">
                <div className="col-md-6 form-group mb-5">
                    <label  htmlFor="start-date">Date de début</label>
                    <input className="form-control form-control-solid" type="date" id="start-date" placeholder="" onChange={(e)=> selectedUsers() }/>
                </div>

                <div className="col-md-6 form-group mb-5">
                    <label  htmlFor="end-date">Date de fin</label>
                    <input className="form-control form-control-solid" type="date" id="end-date" placeholder="" onChange={(e)=> selectedUsers() }/>
                </div>
            </div>

            <div className="form-row mr-3 ml-1 justify-content-between" >
              <button className="btn btn-dark " onClick={() => exportToExcel('normal') } >Exportation</button>
              <button className="btn btn-success" onClick={() => exportToExcel() } >Exportation</button>
            </div>

        </div>
    </Router>
  )
}

export default ExportSchedules;
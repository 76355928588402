import { useState, React } from 'react';
import { filter, sortBy } from 'lodash';

const Table = (props) => {
  const [rowIsEditing, setRowIsEditing] = useState(null);

  const {
        headers = [],
        data = [],
        filterBoolean = () => true,
        sortFunction = () => true,
        actions = [],
        tds=[]
  } = props

  return (
    
        <div className='datatable table-responsive'>
          <table className='table table-hover mb-0' width='100%' cellSpacing='0'>
              <thead>
                  <tr>
                      { headers.length > 0 && headers.map((o,index) => <th key={index}>{o}</th>)}
                  </tr>
              </thead>
              <tbody>
                  {
                  data.length > 0  && sortBy( data.filter((o) => filterBoolean(o)), [sortFunction()] ).map((element, indexRow) => (
                      <tr
                          key={indexRow}
                          style={styles.cursor}
                          onClick={() => { 
                            const action = actions.filter((actionItem, actionIndex) => actionItem.name == 'navigate')
                            if(action.length > 0)
                              action[0].action(element)
                          }}
                      >

                            { tds.length > 0 && tds.map((val, indexValue) => (
                                (val.highlight == null) ?
                                <td key={indexValue} >
                                  {
                                      (rowIsEditing == indexRow)  ? 
                                      (<input className="form-control form-control-solid" type="text" value={val.action(element)} 
                                        onChange={(e) => val.editAction(indexRow, val.text , e.target.value)} 
                                      />)
                                    :
                                      val.action(element)
                                  }
                                </td>
                                :
                                <td key={indexValue} dangerouslySetInnerHTML={{__html: val.action(element)}}/>
                            ))}
                            { 
                                <td>
                                  { 
                                      actions.length > 0 && actions?.filter((actionItem) => (actionItem?.display === undefined) ? true : actionItem?.display(element)).map((actionItem,actionIndex) => (
                                          <button key={actionIndex} className="btn btn-datatable btn-icon btn-transparent-dark" 
                                            onClick={
                                              (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                
                                                (actionItem.name == 'edit') ?
                                                  ((rowIsEditing == indexRow) ? setRowIsEditing(null) : setRowIsEditing(indexRow)) 
                                                  :  actionItem.action(element, indexRow)
                                              }
                                              }>
                                              <i className={"fas " + ({
                                                  'edit' : "fa-pencil-alt",
                                                  'delete': 'fa-trash-alt',
                                                  'navigate': "fa-pencil-alt",
                                                  'copy': 'fa-copy',
                                                  'download': 'fa-download'
                                              })[actionItem.name]}></i>
                                          </button> 
                                      ))
                                  }
                                </td>
                            }
                      </tr>
                    ))}

          
              </tbody>
          </table>
        </div>
      

  );
}

const styles = {
    cursor: {
      cursor: 'pointer'
    }
}

export default Table;
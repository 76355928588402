import React from 'react';
import Sidebar from '@components/Sidebar';

const Router = ({
    active = '',
    children = null
}) => {
  return (
    <Sidebar>
        <Sidebar.Content header={true}>
            <Sidebar.Item image="fas fa-home" active={(active == 'Accueil')} url='/'>Accueil</Sidebar.Item>
            <Sidebar.Item image="fas fa-warehouse" active={(active == 'Chantiers')} url='/chantiers'>Chantiers</Sidebar.Item>
            <Sidebar.Item image="fas fa-user-check" active={(active == 'Clients')} url='/clients'>Clients</Sidebar.Item>
            {/* <Sidebar.Item image="fas fa-truck" active={(active == 'Fournisseurs')} url='/suppliers'>Fournisseurs</Sidebar.Item> */}
            <Sidebar.Item image="fas fa-file" active={(active == 'Documents')} url='/sales-items'>Documents</Sidebar.Item>
            {/* <Sidebar.Item image="fas fa-box-open" active={(active == 'Achats')} url='/achats'>Achats</Sidebar.Item> */}
            <Sidebar.Item image="fas fa-stopwatch" active={(active == 'Présence')} url='/time-tracker'>Présence</Sidebar.Item>
            <Sidebar.Item image="fas fa-users" active={(active == 'Employees')} url='/employees'>Employés</Sidebar.Item>
            {/* <Sidebar.Item image="fas fa-file-excel" active={(active == 'Exportation')} url='/export-schedules'>Exportation</Sidebar.Item> */}
        </Sidebar.Content>
        <Sidebar.Main>
            {children}
        </Sidebar.Main>
    </Sidebar>
  );
}

export default Router;
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";

export const setClients = clients => ({
  type: SET_CLIENTS,
  payload: {
    clients
  }
});


export const setCurrentClient = clientId => ({
  type: SET_CURRENT_CLIENT,
  payload: {
      clientId
  }
})
import { useState } from 'react';
import { findIndex } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import _ from 'lodash';

import { useForm } from '@hooks';
import { typeLabel, statutText } from '@root/config';

import {fetchClients , createClients, updateClients , deleteClients} from '@queries/Clients';
import { deleteSchedules} from '@queries/Schedules';
import { deleteChantiers} from '@queries/Chantiers';
import { createContacts, updateContacts , deleteContacts} from '@queries/Contacts';
import { fetchSalesItems, deleteSalesItems} from '@queries/SalesItems';
import { deleteSalesDetails} from '@queries/SalesDetails';

import Header from '@components/Header';
import Router from "@components/Router";
import Table from '@components/Table';
import Field from '@components/Field/Field';
import FilterBox from '@components/FilterBox';

function ClientsDetails() {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [form, setForm] = useForm({ contacts: [], chantiers: [], salesItems: [] });
  const currentClientId = location.state;

  // Automatic populate / reupdate client structure based on the currentClientId
  const clientsAll = useQuery('clients', fetchClients, { 
    onSuccess: (data) => setForm('all', data?.data?.filter(o => o.id === currentClientId)?.map(o => (
      { 
        id: o.id, 
        ...o.attributes, 
        ...(o?.attributes?.chantiers?.data?.length >= 0 && ({chantiers : o.attributes.chantiers.data.map(v => ({ id: v.id, ...v.attributes }))})), 
        ...(o?.attributes?.contacts?.data?.length >= 0 && ({ contacts : o?.attributes?.contacts?.data?.map(v => ({ id: v.id, ...v.attributes })) })),
        ...(o?.attributes?.sales_items?.data?.length >= 0 && ({ sales_items : o.attributes.sales_items.data.map(v => ({ id: v.id, ...v.attributes })) }))
      }
    ))?.at(0) || { contacts: [], chantiers: [], salesItems: [] } )});

  const salesItemsAll = useQuery('salesItems', fetchSalesItems);

  const salesItems = salesItemsAll.isSuccess
    ? salesItemsAll.data?.data.map((o) => ({ id: o.id, ...o.attributes }))
    : [];
    
  // Modify Data
  const createClientMutation = useMutation((clients) => createClients(clients));
  const createContactMutation = useMutation((contacts) => createContacts(contacts))

  const updateClientsMutation = useMutation((clients) => updateClients(clients));
  const updateContactsMutation = useMutation((contacts) => updateContacts(contacts));

  const deleteClientMutation = useMutation((ids) => deleteClients(ids));
  const deleteChantiersMutation = useMutation((ids) => deleteChantiers(ids));
  const deleteContactsMutation = useMutation((ids) => deleteContacts(ids));
  const deleteSalesDetailsMutation = useMutation((ids) => deleteSalesDetails(ids));
  const deleteSalesItemsMutation = useMutation((ids) => deleteSalesItems(ids));
  const deleteSchedulesMutation = useMutation((ids) => deleteSchedules(ids));

  // State
  const [typeFilter, setTypeFilter] = useState('');
  const [currentTab, setCurrentTab] = useState('informations');
  
  const [isUpdatingClients, setIsUpdatingClients] = useState(false);
  const [isDeletingClients, setIsDeletingClients] = useState(false);

  const [contactsToDelete, setContactsToDelete] = useState([]);

  // Helpers

  const upsertClientContact = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { contacts = [], chantiers = [], salesItems = [], ...client} = form;

    const contactsToCreate = contacts.filter(o => !o.hasOwnProperty('id'));
    const contactsToUpdate = contacts.filter(o => o.hasOwnProperty('id'));

    let id = null;
    
    if (client.hasOwnProperty('id'))
      await updateClientsMutation.mutateAsync([client]);
    else {
      const result = await createClientMutation.mutateAsync([client]);
      id = result?.at(0)?.data?.data?.id;
    }
    queryClient.invalidateQueries('clients');

    await deleteContactsMutation.mutateAsync(contactsToDelete);
    await updateContactsMutation.mutateAsync(contactsToUpdate);
    await createContactMutation.mutateAsync(contactsToCreate?.map(k => ({
      idClient: currentClientId || id,
      ...k
    })));
    queryClient.invalidateQueries('contacts');

    history.goBack();
  }

  const deleteClient = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await deleteClientMutation.mutateAsync([currentClientId]);
    queryClient.invalidateQueries('clients');

    history.goBack();
  } 

  // Internal methods 

  const newContact = () => setForm('contacts', [...form.contacts, {
      idClient: currentClientId
  }]);

  const deleteContact = (id) => {
    setContactsToDelete((prev) => [...prev, id]);
    const index = findIndex(form.contacts, (o) => o.id == id);

    setForm('contacts', [
        ...form.contacts.slice(0, index), ...form.contacts.slice(index+1)
    ]);
  }

  const editContact = (row, key, value) => {
    const tempContacts = JSON.parse(JSON.stringify(form.contacts));
    tempContacts[row][key] = value;
    setForm('contacts', tempContacts); 
  }

  const getDisplayContactName = (idContact) => {
    let contact = form.contacts[0];
    return `${contact && contact.firstName + ' ' +contact.lastName || ''}`;
  }


  // duplicate bills button
  const duplicateBill = async (element) => {
  //   const { status: statusSalesItems, data: dataSalesItems, message: messageSalesItems } = await updateSalesItems([
  //       {
  //         id : maxSalesItemId + 1 ,
  //         idClient : element.idClient ,
  //         idChantier : element.idChantier ,
  //         type : element.type,
  //         documentNumber : element.documentNumber ,
  //         vat : element.vat ,
  //         dateFacturation :  moment(element.dateFacturation , 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
  //         dateEcheance : moment(element.dateEcheance , 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
  //         companyName : element.companyName,
  //         address : element.address,
  //         postCode : element.postCode,
  //         town : element.town ,
  //         payment : element.payment,
  //         vatNumber : element.vatNumber,
  //         contactName : element.contactName,
  //         email : element.email,
  //         code : element.code,
  //         remiseHT : element.remiseHT,
  //         status : element.status,
  //       }
  //   ]);

  //   let tempSalesDetails = salesDetails.filter((o) => o.idSalesItems == element.id ) ;
  //   let tempSalesDetailsOmitted = tempSalesDetails.map((element) =>  _.omit(element, ['id', 'createdAt', 'updatedAt', 'deletedAt']) )
  //   let tempSalesDetailsCorrectId = tempSalesDetailsOmitted.map((element) => ({...element, idSalesItems : maxSalesItemId + 1}) )

  //  const { status: statusSalesDetails, data: dataSalesDetails, message: messageSalesDetails } = await updateSalesDetails(tempSalesDetailsCorrectId);
  
  //   if(statusSalesItems == 'success' && statusSalesDetails == 'success'){
  //     setFetchSalesItems(true);
  //   }
  //   else {
  //     console.log(messageSalesItems || messageSalesDetails )
  //   }
  }
  
  const navigateToChantierDetails = (id) => {
    history.push({
      pathname: '/chantier-details',
      state: id
    });
  }

  const openSalesItem = (id) => {
    history.push({
      pathname: '/ventes',
      state: {
        id,
        copy: false
      }
    });
  }

  const copySalesItem = (id) => {
    history.push({
      pathname: '/ventes',
      state: {
        id,
        copy: true
      }
    });
  }


    return(
        <Router
          active='Clients'
        >
          <Header />
          
              <div style={styles.detailContainer}>
                <Field
                  label="Code entreprise :"
                  value={currentClientId}
                  inline={12}
                  disabled={true}
                />
                <Field
                  label="Nom de l'entreprise * :"
                  value={form?.companyName || ''}
                  setValue={(e) => setForm('companyName', e.target.value)}
                  inline={12}
                />
              
                <div className="slider mr-3 ml-3 mt-4 mb-4">
                  <div className={(currentTab == 'informations') ? 'active' : ''} onClick={() => setCurrentTab('informations')}>Informations générales</div>
                  <div className={(currentTab == 'contacts') ? 'active' : ''} onClick={() => setCurrentTab('contacts')}>Contacts</div>
                  <div className={(currentTab == 'financier') ? 'active' : ''} onClick={() => setCurrentTab('financier')}>Informations financières</div>
                  <div className={(currentTab == 'documents') ? 'active' : ''} onClick={() => setCurrentTab('documents')}>Documents Internes</div>
                  <div className={(currentTab == 'chantiers') ? 'active' : ''} onClick={() => setCurrentTab('chantiers')}>Chantiers</div>
                </div>


                {currentTab && currentTab === 'documents' && (
                <div className="mt-3" style={styles.tab}>
                  
                  <div className='px-3'>
                    <FilterBox
                      typeFilter={typeFilter}
                      setTypeFilter={setTypeFilter}
                    />
                    <Table
                      headers = {['N° de Document','Type de Document','Date','Tiers', 'Chantier', 'Montant HT','Montant TTC','Montant Réglé', 'Statut','Action']}
                      data = {form?.sales_items}
                      filterBoolean = { (o) => (typeFilter ? typeFilter.includes(o.type) : o) }
                      actions= {[
                        { name: 'navigate', action: (element) => openSalesItem(element.id) },
                        { name: 'copy', action: (element) => copySalesItem(element.id) }
                      ]}
                      tds = {[
                        { action: (element) => element.documentNumber},
                        { action: (element) => typeLabel[element.type]},
                        { action: (element) => moment(element?.dateFacturation).format('DD/MM/YYYY')},
                        { action: () => form?.companyName },
                        { action: (element) => element?.idChantier?.data?.attributes?.site },
                        { action: (element) => element?.montantHt?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") },
                        { action: (element) => element?.montantTtc?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") },
                        { action: (element) => (element?.type === 'FactureVente') ? _.sumBy(salesItems.filter(o => o?.type === 'Règlements' && o?.sales_item?.data?.id === element?.id), 'montantTtc')?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '',},
                        { action: (element) => statutText?.[element?.type]?.[element?.status] },
                        ]}
                    />
                    </div>
                  </div>
                )}

                {currentTab && currentTab == 'chantiers' && (
                <div className="mt-3" style={styles.tab}>
                  <div className="form-row mr-4 ml-4 ">
                      <Table 
                        headers = {['Libellé','Code','Désignation travaux','Contact','Action']}
                        data = {(currentClientId && form.chantiers)}
                        actions= {[
                          { name: 'navigate', action: (element) => navigateToChantierDetails(element.id) },
                        ]}
                        tds = {[
                          { action: (element) => element.site },
                          { action: (element) => element.code },
                          { action: (element) => element.designationTravaux },
                          { action: (element) => getDisplayContactName(element.idContact) }
                        ]}
                      />

                  </div>
                </div>
                )}

                { currentTab && currentTab == 'informations' && (
                <div className='mt-3' style={styles.tab}>
                  {/* <div className="text-center small text-muted mb-4 mt-4">Adresse de facturation</div> */}
                  <Field 
                    label="Statut * :"
                    value={form?.status || ''}
                    setValue={(e) => setForm('status', e.target.value)}
                    element='select'
                    inline={12}
                    options={[
                      { value: 'company', label: 'Entreprise'},
                      { value: 'individual', label: 'Particulier'}
                    ]}
                  />
                  <Field
                    label="Adresse principale * :"
                    value={form?.address || ''}
                    setValue={(e) => setForm('address', e.target.value)}
                    inline={12}
                  />
                  <Field
                    label="Adresse secondaire * :"
                    value={form?.addressFacturation || ''}
                    setValue={(e) => setForm('addressFacturation', e.target.value)}
                    inline={12}
                  />
                  <div className="d-flex">
                    <Field
                      label='Code Postal * :'
                      type='number'
                      value={form?.postCode || ''}
                      setValue={(e) => setForm('postCode', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='Ville * :'
                      value={form?.town || ''}
                      setValue={(e) => setForm('town', e.target.value)}
                      inline={6}
                    />
                  </div>
                  <Field
                    label='Pays * :'
                    value={form?.pays || ''}
                    setValue={(e) => setForm('pays', e.target.value)}
                    inline={12}
                  />
                  <div className="d-flex">
                    <Field
                      label='Telephone * :'
                      type='tel'
                      value={form?.phone || ''}
                      setValue={(e) => setForm('phone', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='Fax * :'
                      type='tel'
                      value={form?.faxNumber || ''}
                      setValue={(e) => setForm('faxNumber', e.target.value)}
                      inline={6}
                    />
                  </div>

                  <Field
                    label='Email * :'
                    type='email'
                    value={form?.email || ''}
                    setValue={(e) => setForm('email', e.target.value)}
                    inline={12}
                  />

                  <div className="d-flex">
                    <Field
                      label='RCI / SIRET * :'
                      value={form?.rciNumber || ''}
                      setValue={(e) => setForm('rciNumber', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='N° TVA * :'
                      value={form?.vatNumber || ''}
                      setValue={(e) => setForm('vatNumber', e.target.value)}
                      inline={6}
                    />
                  </div>        
                  
                  
                </div>
                )}

                { currentTab && currentTab == 'financier' && (
                <div className="mt-3">
                  <Field
                    className='mt-3'
                    label="Mode de règlement * :"
                    value={form?.modeReglement || ''}
                    element='select'
                    setValue={(e) => setForm('modeReglement', e.target.value)}
                    options={[
                      { value : 'cheques', label : 'Cheques' },
                      { value : 'virement', label : 'Virement' },
                      { value : 'traites', label : 'Traites' }
                    ]}
                    inline={12}
                  />
                  <Field
                    label="Condition de règlement * :"
                    value={form?.payment || ''}
                    setValue={(e) => setForm('payment', e.target.value)}
                    inline={12}
                  />

                  <div className="d-flex">

                    <Field
                      label="IBAN * :"
                      value={form?.iban || ''}
                      setValue={(e) => setForm('iban',e.target.value)}
                      inline={6}
                    />
                    <Field
                      label="Code BIC / SWIFT : "
                      value={form?.bic || ''}
                      setValue={(e) => setForm('bic', e.target.value)}
                      inline={6}
                    />
                  </div>
                  <Field
                    label="Nom, prénom ou raison sociale du bénéficiaire :"
                    value={form?.beneficiary || ''}
                    setValue={(e) => setForm('beneficiary', e.target.value)}
                    inline={12}
                  />

                  <Field
                    label="Nom banque : "
                    value={form?.bank || ''}
                    setValue={(e) => setForm('bank', e.target.value)}
                    inline={12}
                  />

                  <div className="d-flex">
                    <Field
                      label='Ville :'
                      value={form?.townFacturation || ''}
                      setValue={(e) => setForm('townFacturation', e.target.value)}
                      inline={6}
                    />
                    <Field
                      label='Pays :'
                      value={form?.paysFacturation || ''}
                      setValue={(e) => setForm('paysFacturation', e.target.value)}
                      inline={6}
                    />
                  </div>


                </div>
                  )}


                    { currentTab && currentTab == 'contacts' && (
                      <div>
                        {/* <div className="text-center small text-muted mb-4 mt-4">Contacts</div> */}
                        <div className="form-row mr-3 ml-3 ">
                          <button className="btn btn-dark mb-3 mt-3" onClick={() => newContact()} >Nouveau contact</button>
                            <Table 
                              headers = {['Nom *','Prénom *','Fonction','Portable','Téléphone Fixe','Email','Commentaire','Action']}
                              data = {(form?.contacts || [])}
                              actions= {[
                                { name: 'edit' },
                                { name: 'delete', action: (element) => deleteContact(element.id) },
                              ]}
                              tds = {[
                                { action: (element) => (element.lastName || '') , editAction: (index, text, e) => editContact(index , text , e) , text : 'lastName' },
                                { action: (element) => (element.firstName || '') , editAction: (index, text, e) => editContact(index , text , e) , text : 'firstName'},
                                { action: (element) => (element.fonction || '')  , editAction: (index, text, e) => editContact(index , text , e) , text : 'fonction' },
                                { action: (element) => (element.phone || '')  , editAction: (index, text, e) => editContact(index , text , e) , text : 'phone' },
                                { action: (element) => (element.phoneFix || '' ) , editAction: (index, text, e) => editContact(index , text , e) , text : 'phoneFix' },
                                { action: (element) =>  (element.email || '' ) , editAction: (index, text, e) => editContact(index , text , e) , text : 'email' },
                                { action: (element) => (element.comment || '')  , editAction: (index, text, e) => editContact(index , text , e) , text : 'comment' },
                              ]}
        
                            />
                        </div>
                      </div>
                    )}

                  <div className="form-row mr-3 ml-3 justify-content-between">
                    <button className="btn btn-outline-danger mb-2" onClick={(e) => { 
                      setIsDeletingClients(true); 
                      deleteClient(e);  
                    }}>
                      { isDeletingClients &&
                          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden='true'>
                          </div>
                      } 
                      { (isDeletingClients) ? 'Supprimer...' : 'Supprimer' }</button>
                    <button className="btn btn-outline-success mb-2" onClick={(e) => { 
                      setIsUpdatingClients(true); 
                      upsertClientContact(e) 
                    }}>
                      { isUpdatingClients &&
                          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden='true'>
                          </div>
                      } 
                      { (isUpdatingClients) ? 'Enregistrement...' : 'Enregistrer' }</button>
                  </div>
              </div>
      </Router>
    )
}


const styles = {
    clientList: {
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 150px)',
      padding: 0 
    },
    tab: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxHeight: 'calc(100% - 50px)'
    },
    inputSearch: {
      width: '70%'
    },
    detailContainer: {
      height: 'calc(100vh - 58px - 2*20px)',
      marginTop: 20,
      marginBottom: 20,
      boxShadow: "-0.35rem 0 0.25rem -0.25rem rgba(67, 67, 67, 0.15)" 
    }
}


export default ClientsDetails;
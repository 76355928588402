import * as queries from '@queries/utils';
import axios from 'axios';
import baseUrl from '@root/config';

const config = { 
    headers: 
    { 
        Authorization: `Bearer ${localStorage.getItem('token')}` 
    } 
};

export const fetchId = () => queries.getQuery('/api/ids-document');


export const updateIds = (arr, wrapped=true) => Promise.all(arr.map(element => axios.put(baseUrl + `/api/ids-document`, (wrapped) ? {
     data: {
            map: element
        }

    } : element, config)));

import { useState, useEffect ,useRef } from 'react';
import Header from '@components/Header';
import Sidebar from '@components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as actions from '@redux/actions';
import { fetchAchatsItems , updateAchatsItems , deleteAchatsItems } from '@queries/AchatsItems';
import { useHistory } from 'react-router-dom';
import Field from '@components/Field';

// Number.prototype.round = function() {
//    return  (Math.round((this + Number.EPSILON) * 100) / 100).toString()
// }

function NewAchat() {
    const dispatch = useDispatch();
    const history = useHistory();
    
    let inputRef = useRef(null);

    let maxValue = 0;
    const allAchatsItemsWithDeleted = useSelector((state) => state.achatsItems.achatsItems);
    if(allAchatsItemsWithDeleted.length>0){ maxValue = Math.max(...allAchatsItemsWithDeleted.map( o => o.id ));}

    const currentAchatsItem = useSelector((state) => state.achatsItems.currentAchatsItem);
    const achatsItems = useSelector((state) => state.achatsItems.achatsItems.filter((o) => !o.deletedAt));
    const suppliers = useSelector((state) => state.suppliers.suppliers.filter((o) => !o.deletedAt));
    
    const [amountHT, setAmountHT] = useState('');

    const [currentSupplier, setCurrentSupplier] = useState('');
    
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const [dateFacturation, setDateFacturation] = useState('');
    const [dateEcheance, setDateEcheance] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [vat, setVat] = useState(20);

    const [companyName,setCompanyName] = useState('');
    const [vatNumber, setVatNumber] = useState('');  
    const [payment, setPayment] = useState('');
    const [postCode, setPostCode] = useState('');
    const [town, setTown] = useState('');
    const [address, setAddress] = useState('');

    
    const [fetchAchatsItemsState, setFetchAchatsItems] = useState(false);
    const [isUpdatingAchatsItems, setIsUpdatingAchatsItems] = useState(false);
    
    const [isDeleting, setIsDeleting] = useState(false);
    const [hasFinishedLoading, setHasFinishedLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState();

    useEffect(() => {
        if(hasFinishedLoading) {
            fillSupplier(currentSupplier);
        }
    }, [currentSupplier] );


    useEffect(() => {
        fillAchatsItem();
    },[]);

 

    const fillSupplier = (id) => {
        const supplier = suppliers.filter((o) => o.id == id)[0];
        
        setCompanyName((supplier && supplier.companyName) || '');
        setPayment((supplier && supplier.payment) || '');
        setAddress((supplier && supplier.address) || '');
        setVatNumber((supplier && supplier.vatNumber) || '');
        setPostCode((supplier && supplier.postCode) || '');
        setTown((supplier && supplier.town) || '');
    }

    const fillAchatsItem = () => {
        if(currentAchatsItem) {
            const achatsItem = achatsItems && achatsItems.filter((o) => o.id == currentAchatsItem)[0];

            setCurrentSupplier((achatsItem && achatsItem.idSupplier) || '');
        
            setType((achatsItem && achatsItem.type) || '');
            setStatus((achatsItem && achatsItem.status) || '');
            setDateFacturation((achatsItem && moment(achatsItem.dateFacturation, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')) || '');
            setDateEcheance((achatsItem && moment(achatsItem.dateEcheance,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')) || '');
            setDocumentNumber((achatsItem && achatsItem.documentNumber) || '');
            setVat((achatsItem && achatsItem.vat) || '');

            setCompanyName((achatsItem && achatsItem.companyName) || '');
            setPayment((achatsItem && achatsItem.payment) || '');
            setAddress((achatsItem && achatsItem.address) || '');
            setVatNumber((achatsItem && achatsItem.vatNumber) || '');
            setPostCode((achatsItem && achatsItem.postCode) || '');
            setTown((achatsItem && achatsItem.town) || '');
       
            setAmountHT( achatsItem && achatsItem.amountHT || '' )
        }
    }

  // Fetch AchatsItems 
  useEffect(async () => {
    if(fetchAchatsItemsState) {
        const { status: statusAchatsItems, data: dataAchatsItems, message: messageAchatsItems } = await fetchAchatsItems();
     
        if(statusAchatsItems == 'success') {
          dispatch(actions.achatsItems.setAchatsItems(dataAchatsItems));
       }
        else
          console.log(messageAchatsItems );
    }

    setFetchAchatsItems(false);
    
  }, [fetchAchatsItemsState]);


  // Update achatsitems
  useEffect(async () => {
    let payload = (dateFacturation && dateFacturation != -1 && dateEcheance && dateEcheance != -1) 
    ?
    { 
        dateFacturation : moment(dateFacturation, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss"),
        dateEcheance : moment(dateEcheance, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss"),
    } 
    : 
    {};

    if(isUpdatingAchatsItems) {
        
        const { status: statusAchatsItems, data: dataAchatsItems, message: messageAchatsItems } = await updateAchatsItems([
            {
                id : currentAchatsItem ? currentAchatsItem : maxValue+1,
                idSupplier : currentSupplier,
                type,
                documentNumber,
                vat,
                ...payload,
                companyName,
                address,
                postCode,
                town,
                payment,
                vatNumber,
                amountHT,
                status,
                fileBase64: selectedFile
            }
        ]);
  
       
        if(statusAchatsItems == 'success' ){
            setFetchAchatsItems(true);
        }
        else {
          console.log(messageAchatsItems)
        }
        history.push('/achats');
    }

    setIsUpdatingAchatsItems(false);
  }, [isUpdatingAchatsItems]);



    // Delete achatsItems 
    useEffect(async() => {
        if(isDeleting) {
             const { status: statusDeleteAchatsItems, data: dataDeleteAchatsItems, message: messageDeleteAchatsItems } = await deleteAchatsItems([currentAchatsItem]);
        
            if(statusDeleteAchatsItems == 'success'){
                setFetchAchatsItems(true);
            }
            else {
              console.log( messageDeleteAchatsItems )
            }
            
            history.push('/achats');
        }

        setIsDeleting(false);
    }, [isDeleting]);

    const changeHandler = (e) => {
        var reader = new FileReader();
        reader.addEventListener('load', function(){
          var dataURL = reader.result;
          setSelectedFile(dataURL)
        });
        reader.readAsDataURL( e.target.files[0]);
	};

    const fakeBtnClick = () => inputRef.current.click()

  return (
    <Sidebar>
      <Sidebar.Content header={true}>
        <Sidebar.Item image="fas fa-home" active={false} url='/'>Accueil</Sidebar.Item>
        <Sidebar.Item image="fas fa-user-check" active={false} url='/clients'>Clients</Sidebar.Item>
        <Sidebar.Item image="fas fa-truck" active={false} url='/suppliers'>Fournisseurs</Sidebar.Item>
        <Sidebar.Item image="fas fa-warehouse" active={false} url='/chantiers'>Chantiers</Sidebar.Item>
        <Sidebar.Item image="fas fa-file" active={false} url='/sales-items'>Documents</Sidebar.Item>
        {/* <Sidebar.Item image="fas fa-box-open" active={false} url='/achats'>Achats</Sidebar.Item> */}
        <Sidebar.Item image="fas fa-stopwatch" active={false} url='/time-tracker'>Présence</Sidebar.Item>
        {/* <Sidebar.Item image="fas fa-users" active={false} url='/employees'>Employés</Sidebar.Item> */}
        <Sidebar.Item image="fas fa-file-excel" active={false} url='/export-schedules'>Exportation</Sidebar.Item>
      </Sidebar.Content>
      <Sidebar.Main>
        <Header />

            <div className="mt-4" style={styles.tab}>
                <div className="text-center small text-muted mb-4">Informations générales</div>
                <div className="row mt-4 pl-3">
                    <div className="col-4">
                        <Field
                            label="Type de document"
                            element='select'
                            setValue={(e) => setType(e.target.value)}
                            value={type}
                            options={[
                                { value: 'Facture', label: 'Facture'}
                            ]}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="Date de facturation"
                            type='date'
                            setValue={(e) => setDateFacturation(e.target.value)}
                            value={dateFacturation}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="Echéance"
                            type='date'
                            setValue={(e) => setDateEcheance(e.target.value)}
                            value={dateEcheance}
                        />
                    </div>
                </div>
                <div className="row mt-4 pl-3">
                    <div className="col-4">
                        <Field
                            label="N° de Document"
                            setValue={(e) => setDocumentNumber(e.target.value)}
                            value={documentNumber}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="Statut"
                            element='select'
                            setValue={(e) => setStatus(e.target.value)}
                            value={status}
                            options={[
                                { value: 'unpaid', label: 'Non Payé'},
                                { value: 'paid', label: 'Payé'}
                            ]}
                        />
                    </div>
                </div>
                <div className="text-center small text-muted mb-4 mt-4">Informations achat</div>
                <div className="row pl-3">   
                    <div className="col-4">
                        <Field
                            label="Entreprise"
                            element='select'
                            setValue={(e) => {setHasFinishedLoading(true);  setCurrentSupplier(e.target.value)}}
                            value={currentSupplier}
                            options={ suppliers.map((val, index) => ({value: val.id , label : val.companyName}))}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="N° TVA"
                            setValue={(e) => setVatNumber(e.target.value)}
                            value={vatNumber}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="Condition de paiement"
                            setValue={(e) =>setPayment(e.target.value)}
                            value={payment}
                        />
                    </div>
                </div>
                <div className="row pl-3">
                    <div className="col-4">
                        <Field
                            label="Code Postal"
                            setValue={(e) =>setPostCode(e.target.value)}
                            value={postCode}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="Ville"
                            setValue={(e) =>setTown(e.target.value)}
                            value={town}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="Adresse"
                            element="textarea"
                            setValue={(e) => setAddress(e.target.value)}
                            value={address}
                        />
                    </div>  
                </div>

                <div className="row pl-3">
                    <div className="col-4">
                        <Field
                            label="Montant HT (€)"
                            setValue={(e) => setAmountHT(e.target.value)}
                            value={amountHT}
                        />
                    </div>
                    <div className="col-4">
                        <Field
                            label="Taux TVA:"
                            element="select"
                            setValue={(e) => setVat(e.target.value)}
                            value={parseFloat(vat)}
                            options = {[
                                {value :'5.5' , label :'5.5%'},
                                {value :'10' , label :'10%'},
                                {value :'20' , label :'20%'},
                            ]}
                        />
                    </div>
                    <div className="col-4">
                    <Field
                            disabled= {true}
                            label="Total TTC (€)"
                            value={ isNaN(( parseFloat(amountHT) + parseFloat(amountHT*(vat/100)) )) ? 0 : ( parseFloat(amountHT) + parseFloat(amountHT*(vat/100)))?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }
                        />
                    </div>
                </div>
                            

               
                <div className="form-row mr-3 ml-3 justify-content-between">
                    <button className="btn btn-outline-danger mb-2" onClick={() => setIsDeleting(true)}>
                    { isDeleting &&
                        <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden='true'>
                        </div>
                    } 
                    { (isDeleting) ? 'Supprimer...' : 'Supprimer' }</button>

                    <input hidden ref={inputRef} id="input" type="file" name="file" onChange={changeHandler} ></input>
                    <button id="button" type="button" className="btn btn-secondary mb-2" onClick={() => fakeBtnClick() } >Telecharger</button>

                    <button className="btn btn-outline-success mb-2" onClick={() => setIsUpdatingAchatsItems(true)}>
                    { isUpdatingAchatsItems &&
                        <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden='true'>
                        </div>
                    } 
                    { (isUpdatingAchatsItems) ? 'Enregistrement...' : 'Enregistrer' }</button>
                </div>

            </div>
      </Sidebar.Main>
    </Sidebar>
  )
}

const styles = {
    tab: {
      overflowY: 'scroll',
      maxHeight: 'calc(100% - 50px)'
    },
    cursor: {
        cursor: 'pointer'
    },
    total: {
        width: 400
    },
    inputTotal: {
        width: 100
    }
  }

export default NewAchat;

import React, {useState} from 'react';
import Header from '@components/Header';
import { Modal } from 'react-bootstrap';
import { fetchUsers , createUsers, updateUsers , deleteUsers } from '@queries/Users';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from '@hooks';
import _ from 'lodash';

import Router from '@components/Router';

function UserManagement() {
    const queryClient = useQueryClient();

    // Fetch data
    const usersAll = useQuery('users', fetchUsers); 

    // Modify data
    const callback = {
        onSuccess: () => {
            queryClient.invalidateQueries('users');
            setUserIsModified(false);
            setDisplayModal(false);
        },
        onError: (e) => {
            setIsError(true);
        }
    }

    const deleteUserMutation = useMutation((ids) => deleteUsers(ids), callback);
    const createUsersMutation = useMutation((users) => createUsers(users), callback);
    const modifyUserMutation = useMutation((ids) => updateUsers(ids), callback);

    // Working data 
    const users = (usersAll.isSuccess) ? usersAll.data?.filter((o) => !o.deletedAt) : [];

    const [form, setForm] = useForm();

    const [displayModal, setDisplayModal] = useState(false)
    const [userIsModified, setUserIsModified] = useState(false);
    const [isError, setIsError] = useState(false);

    // Helpers
    const newUser = () => createUsersMutation.mutate([{
        ...form,
        username: form.email,
        blocked: false,
        confirmed: true
    }]);

    const updateUser = () => modifyUserMutation.mutate([{
        ...form,
        username: form.email
    }])

    const deleteUser = async (id) => {
        try {
            await deleteUserMutation.mutateAsync([id]);
            queryClient.invalidateQueries('users');
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Router>
            <Header />
            <div className='container'>
                <div className="mt-5 mb-4" style={styles.nouveauDocument}>
                <button className="btn btn-dark" onClick={() => { setForm('all', {}); setDisplayModal(true);}} >Nouvel utilisateur</button>
                </div>
                <div className='datatable table-responsive'>
                    <table className='table table-hover mb-0' width='100%' cellSpacing='0'>
                        <thead>
                            <tr>
                                <th>Prénom</th> 
                                <th>Nom</th> 
                                <th>Email</th> 
                                <th>Statut</th> 
                                <th style={styles.alignEnd}>Action</th> 
                            </tr>
                        </thead>
                        <tbody>
                            { users.filter((o) => !o.deletedAt).map((element, index) => (
                                <tr
                                    key={index}
                                    style={styles.cursor}
                                    onClick={() => { setForm('all', _.omit(element, ['password', 'createdAt', 'updatedAt', 'deletedAt'])); setDisplayModal(true); }}
                                >
                                    <td>{element.firstName}</td>
                                    <td>{element.lastName}</td>
                                    <td>{element.email}</td>
                                    <td>{ (element.type == 'admin') ? 'Administrateur' : 'Employé' }</td>
                                    <td style={styles.alignEnd}>
                                        <button className="btn btn-datatable btn-icon btn-transparent-dark" onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            await deleteUser(element.id);
                                        }}>
                                        <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
                <Modal show={displayModal} onHide={() => setDisplayModal(false)}>
                    <Modal.Body>
                        {isError && (
                            <div class="alert alert-danger" role="alert">
                                Le mot de passe doit contenir au moins 6 caractères.
                            </div>
                        )}
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="firstname">Prénom :</label>
                                <input type="text" className="form-control" id="firstname"  value={form.firstName} onChange={(e) => setForm('firstName', e.target.value)}/>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="lastname">Nom :</label>
                                <input type="text" className="form-control" id="lastname"  value={form.lastName} onChange={(e) => setForm('lastName', e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="password">Mot de passe {(form.id) ? <em>(facultatif)</em> : null} :</label>
                                <input type="password" className="form-control" id="password"  value={form.password} onChange={(e) => setForm('password', e.target.value)}/>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="email">Statut :</label>
                                <select className="custom-select" value={form.type} defaultValue={'user'} onChange={(e) => setForm('type',e.target.value)}>
                                    <option value={'admin'}>Administrateur</option>
                                    <option value={'user'}>Employé</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email :</label>
                            <input type="text" className="form-control" id="email"  value={form.email} onChange={(e) => setForm('email', e.target.value)}/>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button disabled={((form.id && form.firstName && form.lastName && form.email) || (form.firstName && form.lastName && form.email && form.password) )? undefined : 'disabled'} className="btn btn-primary" onClick={() => {  
                                if(form.id > 0)
                                    updateUser(); 
                                else
                                    newUser();

                                setUserIsModified(true) 
                                setIsError(false);
                            }}>
                                { userIsModified &&
                                    <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden='true'>
                                    </div>
                                } 
                                { (userIsModified) ? 'Enregistrement...' : 'Enregistrer' }
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </Router>
    )
}

const styles = {
    nouveauDocument : {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    alignEnd: {
      textAlign: 'end'
    },
    cursor: {
      cursor: 'pointer'
    }
  }
  

export default UserManagement;

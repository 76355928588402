export const SET_USERS = "SET_USERS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const setUsers = users => ({
  type: SET_USERS,
  payload: {
    users
  }
});

export const setCurrentUser = userId => ({
  type: SET_CURRENT_USER,
  payload: {
      userId
  }
})
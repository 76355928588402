export const SET_CONTACTS = "SET_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

export const setContacts = contacts => ({
  type: SET_CONTACTS,
  payload: {
    contacts
  }
});

export const addContact = contact => ({
  type: ADD_CONTACT,
  payload: {
    contact
  }
});

export const updateContact = (id, contact) => ({
  type: UPDATE_CONTACT,
  payload: {
    id,
    contact
  }
});

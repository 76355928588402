import { ADD_SALESDETAIL, SET_SALESDETAILS ,UPDATE_SALESDETAIL} from "../actions/salesDetails";
import { findIndex } from 'lodash';

const initialState = []

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_SALESDETAILS: {
      return [
        ...action.payload.salesDetails
      ]
    }
    case ADD_SALESDETAIL: {
      return [
        ...state, 
        action.payload.salesDetail
      ]
    }
    case UPDATE_SALESDETAIL: {
      const tempSalesDetails = JSON.parse(JSON.stringify(state));
      const index = findIndex(tempSalesDetails, (o) => o.id == action.payload.id);
      tempSalesDetails[index] = action.payload.salesDetail;
      return tempSalesDetails;
   }
    default:
      return state;
  }
}

export default resolver;
import { SET_CONTACTS, ADD_CONTACT, UPDATE_CONTACT } from "../actions/contacts";
import { findIndex } from 'lodash';

const initialState = []

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_CONTACTS: {
      return [
        ...action.payload.contacts
      ]
    }
    case ADD_CONTACT: {
        return [
          ...state,
          action.payload.contact
        ]
      }
    case UPDATE_CONTACT: {
        const tempContacts = JSON.parse(JSON.stringify(state));
        const index = findIndex(tempContacts, (o) => o.id == action.payload.id);
        tempContacts[index] = action.payload.contact;
        return tempContacts;
    }
    default:
      return state;
  }
}

export default resolver;
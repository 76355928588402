import React from 'react';

const Avatar = (props) => {
  return (
    <div style={styles.avatar}>
        {props.firstName && props.firstName[0].toUpperCase()}{ props.lastName && props.lastName[0].toUpperCase()}
    </div>
  );
}

const styles = {
    avatar: {
        color: 'white',
        backgroundColor: 'var(--gray)',
        borderRadius: '50%',
        width: '35px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

export default Avatar;
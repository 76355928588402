export const SET_SALESITEMS = "SET_SALESITEMS";
export const SET_CURRENT_SALESITEM = "SET_CURRENT_SALESITEM";

export const setSalesItems = salesItems => ({
  type: SET_SALESITEMS,
  payload: {
    salesItems
  }
});

export const setCurrentSalesItem = salesItemId => ({
    type: SET_CURRENT_SALESITEM,
    payload: {
        salesItemId
    }
})
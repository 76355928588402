import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useHistory } from "react-router-dom";

function MySearchBar(props) {
  const { allSearchItems = [] } = props;
  const history = useHistory();
  const navigateSearch = (id, type) => {
    const path = {
      employees: "/employees-details",
      chantier: "/chantier-details",
      salesItems: "/ventes",
      clients: "/clients-details",
    };
    history.push({
      pathname: path[type],
      state: id,
    });
  };
  const formatResult = (item) => {
    return (
      <>
        {
          {
            clients: (
              <div className="p-2">
                Client : Nº{item.realId} - {item.companyName} / {item.status}
              </div>
            ),
            employees: (
              <div className="p-2">
                Employees : Nº{item.realId} - {item.firstName} {item.lastName}
              </div>
            ),
            chantier: (
              <div className="p-2">
                Chantiers : Nº{item.realId} - {item.designationTravaux}
                {" - "}
                {item?.idClient?.data?.attributes?.companyName}
              </div>
            ),
            salesItems: (
              <div className="p-2">
                Documents : Nº{item.documentNumber} - {item.realType}
              </div>
            ),
          }[item.type]
        }
      </>
    );
  };
  return (
    <>
      <ReactSearchAutocomplete
        items={allSearchItems}
        styling={{
          zIndex: 1,
          borderRadius: "0.35rem",
          inputSearch: {
            width: "70%",
          },
          dropdown: {
            width: "70%",
            height: "170pt",
            position: "absolute",
          },
        }}
        maxResults={15}
        onSelect={(item) => navigateSearch(item?.realId, item?.type)}
        formatResult={formatResult}
        resultStringKeyName="id"
        autoFocus
        fuseOptions={{
          keys: [
            "------Employees",
            "username",
            "email",
            "address",
            "ccssNumber",
            "permitNumber",
            "dateEntry",
            "ccpbNumber",
            "category",
            "coefficient",
            "firstName",
            "lastName",
            "---Clients",
            "companyName",
            "status",
            "phone",
            "pays",
            "postCode",
            "town",
            "payment",
            "vatNumber",
            "modeReglement",
            "rciNumber",
            "faxNumber",
            "beneficiary",
            "iban",
            "bic",
            "bank",
            "addressFacturation",
            "paysFacturation",
            "townFacturation",
            "------Chantiers---------",
            "refClient",
            "dateDebut",
            "dateFin",
            "status",
            "site",
            "code",
            "designationTravaux",
            "addressChantier",
            "------------Items",
            "type",
            "documentNumber",
            "denomination",
            "libelle",
            "refTiers",
            "vat",
            "dateFacturation",
            "dateEcheance",
            "remiseHt",
            "montantHt",
            "montantTtc",
            "status",
          ],
        }} // Search in the description text as well
      />
    </>
  );
}
export default MySearchBar;

import React from 'react';

const Field = (props) => {
  const formatId = (value) => value.toLowerCase().replace(/ /g, '_');

  const {
    className='',
    styleElement={},
    styleLabel={},
    label = '',
    type = 'text',
    setValue = () => {},
    value = '',
    element = 'input',
    options = [],
    disabled = false,
    inline = null,
    htmlFor = ''
  } = props

  return (
    <div className={" form-group " + (type != 'checkbox' ? 'ml-3 ' : '') + className + (inline ? ` row justify-content-between col-${inline}` : '')}>
        <label className="text-gray-600 small d-flex align-items-center" style={{ ...styleLabel, ...styles.labelWidth}} htmlFor={htmlFor ? htmlFor : formatId(label)}>{label}</label>

        { element == 'input' && type == 'checkbox' && <input disabled={disabled} style={styleElement} className={"form-check-input"} type={type} id={htmlFor ? htmlFor : formatId(label)} onChange={(e)=> setValue(e)} checked={value}/> }
        { element == 'input' && type != 'checkbox' && <input disabled={disabled} className={"form-control form-control-solid " + (inline ? `mr-3` : '')} style={{'flex': 1, ...styleElement}} type={type} id={htmlFor ? htmlFor : formatId(label)} placeholder="" onChange={(e)=> setValue(e)} value={value} /> }
        { element == 'textarea' && <textarea  disabled={disabled} className={"form-control form-control-solid " + (inline ? `mr-3` : '')} style={{'flex': 1, ...styleElement}} id={htmlFor ? htmlFor : formatId(label)} placeholder=""  onChange={(e) => setValue(e)} value={value}/>}
        { element == 'select' && (
          <select  disabled={disabled} className={"form-control form-control-solid " + (inline ? `mr-3` : '')} style={{'flex': 1, ...styleElement}} id={htmlFor ? htmlFor : formatId(label)} onChange={(e)=> setValue(e)} value={value || ''}>
                  <option value={null}></option>
                  { options.filter((o) => (o.display === undefined) ? true : o.display).map((o) =>  <option key={o.value} value={o.value}>{o.label}</option>) }
          </select>
        )}
    </div>
  );
}

const styles = {
  labelWidth: {
    width: 200
  }
}

export default Field;

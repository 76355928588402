export const SET_SCHEDULES = "SET_SCHEDULES";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";

export const setSchedules = schedules => ({
  type: SET_SCHEDULES,
  payload: {
    schedules
  }
});

export const addSchedule = schedule => ({
  type: ADD_SCHEDULE,
  payload: {
    schedule
  }
});

export const updateSchedule = (id, schedule) => ({
  type: UPDATE_SCHEDULE,
  payload: {
    id,
    schedule
  }
});

import { SET_CLIENTS ,SET_CURRENT_CLIENT} from "../actions/clients";

const initialState = {
  currentClientId: null,
  clients: []
}

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_CLIENTS: {
      return {
        ...state,
        clients : action.payload.clients
      }
    }

    case SET_CURRENT_CLIENT: {
      return {
        ...state,
        currentClientId: action.payload.clientId
      }
    }

    default:
      return state;
  }
}

export default resolver;
import { useState } from 'react';
import Header from '@components/Header';
import Router from '@components/Router';
import { useHistory } from 'react-router-dom';
import Table from '@components/Table'
import { fetchClients } from '@queries/Clients'
import { useQuery } from 'react-query';

function Clients() {
  const history = useHistory();

  // Fetch Data
  const clientsAll = useQuery('clients', fetchClients);
  
  const clients = (clientsAll?.isSuccess) ? clientsAll?.data?.data?.map(o => ({ id: o?.id, ...o.attributes })) : [];

  const [search, setSearch] = useState(''); 

  const openCompany = (id) => {
    history?.push({
      pathname: '/clients-details',
      state: id
    });
  }

  const newCompany = () => history?.push( '/clients-details' );

  return (
    <Router
      active='Clients'
    >
      <Header />
        <div className="mr-3 ml-3 ">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <button className="btn btn-dark align-self-start" onClick={() => newCompany()} >Nouveau client</button>
              
              <div className="input-group mb-3" style={styles?.inputSearch}>
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-search"></i></span>
                </div>
                <input type="text" className="form-control" placeholder="Rechercher un client ..." aria-label="Client_Search" aria-describedby="basic-addon1" onChange={(e) => setSearch(e?.target?.value)} />
              </div>

            </div>
          
            <div>
                <Table
                  headers = {['Code client','Dénomination','Téléphone','Fax', 'Email']}
                  data = {clients}
                  filterBoolean = {(o) => o?.id == search || o?.companyName?.toLowerCase()?.includes(search?.toLowerCase()) || o?.faxNumber?.toLowerCase()?.includes(search?.toLowerCase()) || o?.phone?.toLowerCase()?.includes(search?.toLowerCase()) || o?.email?.toLowerCase()?.includes(search?.toLowerCase())}
                  sortFunction = { () => 'id' }
                  actions= {[
                    { name: 'navigate', action: (element) => openCompany(element?.id) },
                  ]}
                  tds = {[
                    {highlight: search , action: (element) => (search) ? element?.id?.toString().replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.id},
                    {highlight: search , action: (element) => (search) ? element?.companyName?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.companyName},
                    {highlight: search , action: (element) => (search) ? element?.phone?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.phone},
                    {highlight: search , action: (element) => (search) ? element?.faxNumber?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.faxNumber},
                    {highlight: search , action: (element) => (search) ? element?.email?.replace(new RegExp('(' + search + ')', 'ig'), "<span class='highlight'>$1</span>") : element?.email},
                  ]}
              />
            </div>
        </div>
    </Router>
  )
}

const styles = {
  clientList: {
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 150px)',
    padding: 0 
  },
  inputSearch: {
    width: '80%'
  }
}

export default Clients;

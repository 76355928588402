import React, { useState } from "react";
import moment from "moment/min/moment-with-locales";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryClient, useQuery, useMutation } from "react-query";
import _ from "lodash";

import { fetchUsers, deleteUsers, updateUsers } from "@queries/Users";
import { createUpload, deleteUpload } from "@queries/Upload";
import baseUrl from "@root/config";


import { useForm } from "@hooks";

import Header from "@components/Header";
import Table from "@components/Table";
import Field from "@components/Field";
import Router from "@components/Router";
import Upload from "@components/Upload";

function EmployeesDetails() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const location = useLocation();

  moment.locale("fr");

  const [form, setForm] = useForm({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentTab, setCurrentTab] = useState("informations");
  const [filesToDelete, setFilesToDelete] = useState([]);

  const currentUserId = location.state;

  // Fetch data
  const usersAll = useQuery("users", fetchUsers, {
    onSuccess: () => {
      setForm("all", usersAll?.data?.filter((o) => o.id === currentUserId)[0]);
    },
  });

  // Modify data
  const deleteUserMutation = useMutation((ids) => deleteUsers(ids));
  const modifyUserMutation = useMutation((users) => updateUsers(users));
  const createUploadMutation = useMutation((uploads) => createUpload(uploads));
  const deleteUploadMutation = useMutation((ids) => deleteUpload(ids));

  // Working data
  const users = usersAll.isSuccess
    ? usersAll?.data
        ?.filter((o) => o.id == currentUserId)
        ?.map((o) => ({
          ...o,
          ...(o?.files?.data && {
            files: o?.files?.data?.map((k) => ({
              id: k?.id,
              ...k?.attributes,
            })),
          }),
        }))[0]
    : [];

  const updateUser = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files, ...user } = form;
    if (files?.filter((o) => !o?.id)?.length > 0) {
      const filesToUpload = new FormData();

      for (let file of files?.filter((o) => !o?.id)) {
        filesToUpload.append("files", file.files);
      }

      filesToUpload.append("ref", "plugin::users-permissions.user");
      filesToUpload.append("field", "files");
      filesToUpload.append("refId", currentUserId);

      await createUploadMutation.mutateAsync([filesToUpload]);
    }

    await deleteUploadMutation.mutateAsync(filesToDelete);

    await modifyUserMutation.mutateAsync([
      {
        ...user,
        username: user.email,
      },
    ]);

    queryClient.invalidateQueries("users");
    history.goBack();
  };

  const deleteFile = (index) => {
    if (form?.files[index].hasOwnProperty("id")) {
      let tempFilesToDelete = JSON.parse(JSON.stringify(filesToDelete));
      tempFilesToDelete.push(form?.files[index].id);
      setFilesToDelete(tempFilesToDelete);
    }

    let tempFiles = JSON.parse(JSON.stringify(form?.files));
    setForm("files", [
      ...tempFiles.slice(0, index),
      ...tempFiles.slice(index + 1),
    ]);
  };

  return (
    <Router active="Employees">
      <Header />
      <div className="mt-4">
        <Field
          label="ID Employé : "
          value={currentUserId}
          inline={12}
          disabled={true}
        />
        <Field
          label="Prénom * : "
          value={form?.firstName || ""}
          setValue={(e) => setForm("firstName", e.target.value)}
          inline={12}
        />
        <Field
          label="Nom * : "
          value={form?.lastName || ""}
          setValue={(e) => setForm("lastName", e.target.value)}
          inline={12}
        />
        <Field
          label="Email * : "
          value={form?.email || ""}
          setValue={(e) => setForm("email", e.target.value)}
          inline={12}
        />

        <div className="slider mr-3 ml-3 mt-4 mb-4">
          <div
            className={currentTab == "informations" ? "active" : ""}
            onClick={() => setCurrentTab("informations")}
          >
            Informations générales
          </div>
          <div
            className={currentTab == "documents" ? "active" : ""}
            onClick={() => setCurrentTab("documents")}
          >
            Documents Internes
          </div>
        </div>

        {currentTab == "informations" && (
          <div className="pt-1" style={styles.tab}>
            <Field
              label="Adresse : "
              value={form?.address || ""}
              setValue={(e) => setForm("address", e.target.value)}
              inline={12}
            />
            <Field
              label="N° Téléphone : "
              value={form?.phone || ""}
              setValue={(e) => setForm("phone", e.target.value)}
              inline={12}
            />
            <Field
              label="Date d'entrée : "
              element="input"
              type="date"
              value={moment(form?.dateEntry).format("YYYY-MM-DD") || ""}
              setValue={(e) => setForm("dateEntry", e.target.value)}
              inline={12}
            />
            <Field
              label="N° CCSS : "
              value={form?.ccssNumber || ""}
              setValue={(e) => setForm("ccssNumber", e.target.value)}
              inline={12}
            />
            <Field
              label="N° Permis de travail : "
              value={form?.permitNumber || ""}
              setValue={(e) => setForm("permitNumber", e.target.value)}
              inline={12}
            />
            <Field
              label="N° CCPB : "
              value={form?.ccpbNumber || ""}
              setValue={(e) => setForm("ccpbNumber", e.target.value)}
              inline={12}
            />
            <Field
              label="Catégorie : "
              value={form?.category || ""}
              setValue={(e) => setForm("category", e.target.value)}
              inline={12}
            />
            <Field
              label="Coefficient : "
              value={form?.coefficient || ""}
              setValue={(e) => setForm("coefficient", e.target.value)}
              inline={12}
            />
          </div>
        )}
        {currentTab && currentTab == "documents" && (
          <div className="mt-4" style={styles.tab}>
            <div className="px-3">
              <Table
                headers={["N° de Document", "Type de Document", "Action"]}
                data={form?.files?.length > 0 ? form.files : []}
                actions={[
                  { name: "download", action: (element) => window.open(`${baseUrl}${element?.url}`, "_blank")},
                  {
                    name: "delete",
                    action: (element, index) => deleteFile(index),
                  },
                ]}
                tds={[
                  { action: (element) => element?.name },
                  { action: (element) => element?.mime },
                ]}
              />

              <Upload
                files={form && form?.files}
                setFiles={(data) => {
                  setForm("files", data);
                }}
              />
            </div>
          </div>
        )}

        <div className="form-row mr-3 ml-3 justify-content-end">
          <button
            className="btn btn-outline-success mb-2"
            onClick={(e) => {
              setIsUpdating(true);
              updateUser(e);
            }}
          >
            {isUpdating && (
              <div
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></div>
            )}
            {isUpdating ? "Enregistrement..." : "Enregistrer"}
          </button>
        </div>
      </div>
    </Router>
  );
}

const styles = {
  cursor: {
    cursor: "pointer",
  },
  total: {
    width: 400,
  },
  inputTotal: {
    width: 100,
  },
  tab: {
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "calc(100% - 50px)",
  },
};

export default EmployeesDetails;

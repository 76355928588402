import { SET_SCHEDULES, ADD_SCHEDULE, UPDATE_SCHEDULE } from "../actions/schedules";
import { findIndex } from 'lodash';

const initialState = []

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_SCHEDULES: {
      return [
        ...action.payload.schedules
      ]
    }
    case ADD_SCHEDULE: {
        return [
          ...state,
          action.payload.schedule
        ]
      }
    case UPDATE_SCHEDULE: {
        const tempSchedules = JSON.parse(JSON.stringify(state));
        const index = findIndex(tempSchedules, (o) => o.id == action.payload.id);
        tempSchedules[index] = action.payload.schedule;
        return tempSchedules;
    }
    default:
      return state;
  }
}

export default resolver;
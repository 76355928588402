import React, { useRef } from "react";

const Upload = (props) => {
  const { files = [], setFiles = () => {} } = props;

  const input = useRef(null);

  const clickRef = () => {
    input.current.click();
  };

  const cancelEffect = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const uploadFile = (e, type = "click") => {
    e.preventDefault();
    e.stopPropagation();

    let file = null;
    const temp = JSON.parse(JSON.stringify(files === null ? [] : files));

    if (type === "click") file = e.target.files[0];
    else file = e.dataTransfer.files[0];

    temp.push({
      name: file.name,
      mime: file.type,
      files: new File([file], file?.name, { type: file?.type }),
    });

    setFiles(temp);
    input.current.value = "";
  };

  return (
    <div className="h-100 px-3">
      <div className="row flex-column" id="step1" style={styles.mh}>
        <div
          className="dragndrop"
          onDrag={(e) => cancelEffect(e)}
          onDragEnter={(e) => cancelEffect(e)}
          onDragLeave={(e) => cancelEffect(e)}
          onDragEnd={(e) => cancelEffect(e)}
          onDragOver={(e) => cancelEffect(e)}
          onDragStart={(e) => cancelEffect(e)}
          onDrop={(e) => uploadFile(e, "DND")}
        >
          <div className="text-center">
            <p>
              Déposer votre document PDF
              <br />
              <small>ou</small>
              <br />
              <button
                onClick={() => clickRef()}
                className="btn btn-outline-dark ml-1 mt-2"
                id="fileButton"
              >
                Charger un fichier
              </button>
              <input
                ref={input}
                className="invisible"
                type="file"
                style={{ width: 1 }}
                accept=".pdf"
                id="fileInput"
                onChange={(e) => uploadFile(e)}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mh: {
    minHeight: "100%",
  },
};

export default Upload;

import { useState } from "react";
import moment from "moment/min/moment-with-locales";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryClient, useQuery, useMutation } from "react-query";
import _, { at, map } from "lodash";

import { fetchClients } from "@queries/Clients";
import { fetchContacts } from "@queries/Contacts";
import { fetchSalesItems, deleteSalesItems } from "@queries/SalesItems";
import { deleteSchedules } from "@queries/Schedules";
import {
  fetchChantiers,
  createChantiers,
  updateChantiers,
  deleteChantiers,
} from "@queries/Chantiers";

import { useForm } from "@hooks";
import { typeLabel, statutText, taskText } from "@root/config";
import { getFeildsInfo, RenderFeild } from "./Render/ChantierField";
import Header from "@components/Header";
import Table from "@components/Table";
import Router from "@components/Router";
import FilterBox from "@components/FilterBox";

function ChantiersDetails() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const location = useLocation();

  moment.locale("fr");

  const [form, setForm] = useForm({});
  const currentChantierId = location.state;

  // Fetch data
  // Automatic populate / reupdate chantier structure based on the currentChantierId
  const clientsAll = useQuery("clients", fetchClients);
  const contactsAll = useQuery("contacts", fetchContacts);
  const salesItemsAll = useQuery('salesItems', fetchSalesItems);

  const chantiersAll = useQuery("chantiers", fetchChantiers, {
    onSuccess: () => {
      setForm(
        "all",
        chantiersAll?.data?.data
          ?.filter((o) => o.id == currentChantierId)
          .map((o) => ({
            id: o.id,
            ..._.omit(o.attributes, ["sales_items"]),
            idClient: o.attributes.idClient?.data?.id,
            idContact: o.attributes.idContact?.data?.id,
            salesItems: o.attributes.sales_items?.data?.map((v) => ({
              id: v.id,
              ...v.attributes,
            })),
            client: {
              id: o.attributes.idClient?.data?.id,
              ...o.attributes.idClient?.data?.attributes,
            },
            contact: {
              id: o.attributes.idContact?.data?.id,
              ...o.attributes.idContact?.data?.attributes,
            },
            schedules: o?.attributes?.schedules?.data?.map((k) => ({
              id: k?.id,
              ...k.attributes,
            })),
          }))[0]
      );
    },
  });

  const salesItems = salesItemsAll.isSuccess
    ? salesItemsAll.data?.data.map((o) => ({ id: o.id, ...o.attributes }))
    : [];
  const clients = clientsAll.isSuccess
    ? clientsAll.data?.data.map((o) => ({ id: o.id, ...o.attributes }))
    : [];
  const contacts = contactsAll.isSuccess
    ? contactsAll.data?.data.map((o) => ({
        id: o.id,
        ...o.attributes,
        idClient: o.attributes?.idClient?.data?.id,
      }))
    : [];

  // Modify Data

  const updateChantierMutation = useMutation((chantiers) =>
    updateChantiers(chantiers)
  );
  const createChantierMutation = useMutation((chantiers) =>
    createChantiers(chantiers)
  );

  const deleteChantiersMutation = useMutation((ids) => deleteChantiers(ids));
  const deleteSalesItemsMutation = useMutation((ids) => deleteSalesItems(ids));
  const deleteSchedulesMutation = useMutation((ids) => deleteSchedules(ids));

  const [currentTab, setCurrentTab] = useState("informations");
  const [typeFilter, setTypeFilter] = useState("");

  const [isUpdatingChantier, setIsUpdatingChantier] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Helpers

  const openSalesItem = (id) => {
    history.push({
      pathname: "/ventes",
      state: {
        id,
        copy: false,
      },
    });
  };

  const copySalesItem = (id) => {
    history.push({
      pathname: "/ventes",
      state: {
        id,
        copy: true,
      },
    });
  };

  const deleteChantier = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await deleteSchedulesMutation.mutateAsync(
      form?.schedules?.map((o) => o.id) || []
    );
    queryClient.invalidateQueries("schedules");
    await deleteSalesItemsMutation.mutateAsync(
      form?.salesItems?.map((o) => o.id) || []
    );
    queryClient.invalidateQueries("salesItems");
    await deleteChantiersMutation.mutateAsync([currentChantierId]);
    queryClient.invalidateQueries("chantiers");

    history.goBack();
  };

  const upsertChantier = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (form.hasOwnProperty("id"))
      await updateChantierMutation.mutateAsync([
        {
          ...form,
          dateDebut: moment(form?.dateDebut).format("YYYY-MM-DD"),
          dateFin: moment(form?.dateFin).format("YYYY-MM-DD"),
        },
      ]);
    else
      await createChantierMutation.mutateAsync([
        {
          ...form,
          dateDebut: moment(form?.dateDebut).format("YYYY-MM-DD"),
          dateFin: moment(form?.dateFin).format("YYYY-MM-DD"),
        },
      ]);

    queryClient.invalidateQueries("chantiers");
    history.goBack();
  };
  const tabulationInformation = [
    {
      label: "Informations générales",
      value: "informations",
    },
    {
      label: "Documents Internes",
      value: "documents",
    },
    {
      label: "Main d'oeuvre",
      value: "salaries",
    },
  ];
  return (
    <Router active="Chantiers">
      <Header />
      <div className="mt-4">
        <RenderFeild
          allFeild={getFeildsInfo(
            0,
            currentChantierId,
            form,
            clients,
            contacts,
            setForm,
            moment
          )}
        />
        <div className="slider mr-3 ml-3 mt-4 mb-4">
          {tabulationInformation.map((item) => (
            <div
              className={currentTab == item.value ? "active" : ""}
              onClick={() => setCurrentTab(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>

        {currentTab == "informations" && (
          <div className="pt-1" style={styles.tab}>
            <RenderFeild
              allFeild={getFeildsInfo(
                1,
                currentChantierId,
                form,
                clients,
                contacts,
                setForm,
                moment
              )}
            />
          </div>
        )}
        {currentTab && currentTab == "documents" && (
          <div className="mt-4" style={styles.tab}>
            <FilterBox typeFilter={typeFilter} setTypeFilter={setTypeFilter} />
            <div className="px-3">
              <Table
                headers={[
                  "N° de Document",
                  "Type de Document",
                  "Date",
                  "Tiers",
                  "Chantier",
                  "Montant HT",
                  "Montant TTC",
                  "Montant Réglé",
                  "Statut",
                  "Action",
                ]}
                data={form?.salesItems}
                filterBoolean={(o) =>
                  typeFilter ? typeFilter.includes(o.type) : o
                }
                actions={[
                  {
                    name: "navigate",
                    action: (element) => openSalesItem(element.id),
                  },
                  {
                    name: "copy",
                    action: (element) => copySalesItem(element.id),
                  },
                ]}
                tds={[
                  { action: (element) => element.documentNumber },
                  { action: (element) => typeLabel[element.type] },
                  {
                    action: (element) =>
                      moment(element?.dateFacturation).format("DD/MM/YYYY"),
                  },
                  { action: (element) => form?.client?.companyName },
                  { action: (element) => form.site },
                  {
                    action: (element) =>
                      element?.montantHt
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                  },
                  {
                    action: (element) =>
                      element?.montantTtc
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                  },
                  {
                    action: (element) => (element?.type === 'FactureVente') ? _.sumBy(salesItems.filter(o => o?.type === 'Règlements' && o?.sales_item?.data?.id === element?.id), 'montantTtc')?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") : '',
                  },
                  {
                    action: (element) =>
                      statutText?.[element.type]?.[element.status],
                  },
                ]}
              />
            </div>
          </div>
        )}
        {currentTab === "salaries" && (
          <div style={styles.tab}>
            <div className="px-3">
              <Table
                headers={["Tâche", "Nombre d'heures"]}
                data={Object.entries(
                  _.groupBy(form?.schedules, (o) => [o?.task])
                )}
                actions={[]}
                tds={[
                  // {action: (element) => (`${element?.at(1)?.at(0)?.idUser?.data?.attributes?.firstName} ${element?.at(1)?.at(0)?.idUser?.data?.attributes?.lastName}`)},
                  // {action: (element) => taskText[element[0].split(',').slice(1).join(',')]},
                  { action: (element) => taskText[element[0]] },
                  {
                    action: (element) =>
                      parseInt(
                        _.sum(
                          element[1].map((e) =>
                            moment(e.endTime, "HH:mm").diff(
                              moment(e.startTime, "HH:mm"),
                              "minutes"
                            )
                          )
                        ) / 60
                      )
                        .toString()
                        .padStart(2, "0") +
                      ":" +
                      (
                        _.sum(
                          element[1].map((e) =>
                            moment(e.endTime, "HH:mm").diff(
                              moment(e.startTime, "HH:mm"),
                              "minutes"
                            )
                          )
                        ) % 60
                      )
                        .toString()
                        .padStart(2, "0"),
                  },
                ]}
              />
            </div>
          </div>
        )}

        <div className="form-row mr-3 ml-3 justify-content-between">
          <button
            className="btn btn-outline-danger mb-2"
            onClick={(e) => {
              setIsDeleting(true);
              deleteChantier(e);
            }}
          >
            {isDeleting && (
              <div
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></div>
            )}
            {isDeleting ? "Supprimer..." : "Supprimer"}
          </button>
          <button
            className="btn btn-outline-success mb-2"
            onClick={(e) => {
              setIsUpdatingChantier(true);
              upsertChantier(e);
            }}
          >
            {isUpdatingChantier && (
              <div
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></div>
            )}
            {isUpdatingChantier ? "Enregistrement..." : "Enregistrer"}
          </button>
        </div>
      </div>
    </Router>
  );
}

const styles = {
  cursor: {
    cursor: "pointer",
  },
  total: {
    width: 400,
  },
  inputTotal: {
    width: 100,
  },
  tab: {
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "calc(100% - 50px)",
  },
};

export default ChantiersDetails;

import { SET_USER } from "../actions/auth";

const initialState = {
  user: '',
};

const resolver = function(state = initialState, action) {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: {
          ...action.payload.user,
        }
          
      };
    }
    default:
      return state;
  }
}

export default resolver;